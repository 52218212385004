import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { ISolicitacao } from '../models/solicitacao.model';
import { MeurhSolicitacoes } from '../services/solicitacoes.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';
import { TrabalhadoresService } from '../../../Trabalhadores/trabalhadores.service';
import { ListagemService } from '../../../../shared/services/listagem.service';

export class MeurhSolicitacoesListController implements ng.IController {
	static $inject = ['$rootScope', '$scope', '$state', '$http', '$stateParams', '$location', '$transitions', 'nsjRouting', 'NewToaster', 'MeurhSolicitacoes', 'RelatoriosService', 'PreviousState', 'PermissaoService', 'TrabalhadoresService', 'BuscaAvancadaService', 'ListagemService'];

	public entities: Array<ISolicitacao>;
	public service: MeurhSolicitacoes;
	public busy: boolean = false;
	public filter: any = this.$stateParams['tipo'] ? this.$stateParams['tipo'] : '';
	public filters: any = this.entityService.filters = {};
	public constructors: any = this.entityService.constructors = [];
	public estabelecimentos: Array<Estabelecimento>;
	public empresas: Array<Empresa>;
	public listagemPermissao: Array<string> = ['visualizar_admissao', 'visualizar_rescisao', 'visualizar_adiantamento_avulso', 'visualizar_vtadicional', 'visualizar_alteracaovt', 'visualizar_alteracaodadoscadastrais', 'visualizar_alteracaotiporecebimento', 'visualizar_afastamentosretorno'];
	public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
	public carregandoRelatorio: boolean;
	public lookupTrabalhadores: boolean = false;
	public primeiroLookupTrabalhadoresCarregado: boolean = false;
	public entityScroll: any;
	public dataAtual: moment.Moment | string;
	public relatoriosServ: any;
	public maxoccurs = {};
	public fields = [
		{
			value: 'tiposolicitacao',
			label: 'Tipo',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnScope: {
				getSolTipo: (entity: any): string => {
					return this.solicitacoestipos[entity.tiposolicitacao];
				},
			},
			customCollumnHtml: () => {
				let someHtmlVar = `
				<span ng-bind="customCollumnScope.getSolTipo(tableRowEntity)"></span>
              `;
				return someHtmlVar;
			},
		},
		{
			value: 'matricula_admissao',
			label: 'Matrícula',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnHtml: () => {
				let someHtmlVar = `
				<span ng-if="(tableRowEntity.tiposolicitacao == 0 || tableRowEntity.tiposolicitacao == 12 || tableRowEntity.tiposolicitacao == 15)" class="td-v-middle">{{tableRowEntity.matricula_admissao}}</span>
				<span ng-if="(tableRowEntity.tiposolicitacao != 0 && tableRowEntity.tiposolicitacao != 12 && tableRowEntity.tiposolicitacao != 15)" class="td-v-middle">{{tableRowEntity.trabalhador.codigo}}</span>
              `;
				return someHtmlVar;
			},
		},
		{
			value: 'solicitacao',
			label: 'Colaborador',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnHtml: () => {
				let someHtmlVar = `
				<span ng-if="(tableRowEntity.tiposolicitacao == 0 || tableRowEntity.tiposolicitacao == 12 || tableRowEntity.tiposolicitacao == 15)" class="td-v-middle">{{tableRowEntity.nome_admissao}}</span>
				<span ng-if="(tableRowEntity.tiposolicitacao != 0 && tableRowEntity.tiposolicitacao != 12 && tableRowEntity.tiposolicitacao != 15)" class="td-v-middle">{{tableRowEntity.trabalhador.nome}}</span>
              `;
				return someHtmlVar;
			},
		},
		{
			value: 'created_at',
			label: 'Criado em',
			type: 'date',
			style: 'title',
			copy: '',
		},
		{
			value: 'created_by.nome',
			label: 'Criado por',
			type: 'integer',
			style: 'title',
			copy: '',
		},
		{
			value: 'codigo',
			label: 'Código',
			type: 'integer',
			style: 'title',
			copy: '',
		},
		{
			value: 'situacao',
			label: 'Situação',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnHtml: () => {
				let someHtmlVar = `
				<label class="label label-uppercase {{tableRowEntity.situacao === 0 ? 'label-warning' :
				(tableRowEntity.situacao === -1 && tableRowEntity.tiposolicitacao !== 12 ? 'label-primary' :
				(tableRowEntity.situacao === -1 ? 'label-aberta' :
				(tableRowEntity.situacao === 1 ? 'label-success' :
				(tableRowEntity.situacao === 2 || tableRowEntity.situacao === 3 || tableRowEntity.situacao === 4 ? 'label-danger' : ''))))}}"

                ng-bind-template="{{
				tableRowEntity.situacao === 0 ? 'Enviada' :
				(tableRowEntity.situacao === -1 && tableRowEntity.tiposolicitacao !== 12 ? 'Rascunho' :
				(tableRowEntity.situacao === -1 ? 'Aberta' :
				(tableRowEntity.situacao === 1 ? 'Efetuada' :
				(tableRowEntity.situacao === 2 ? 'Cancelada' :
				(tableRowEntity.situacao === 3 ? 'Excluída' :
				(tableRowEntity.situacao === 4 ? 'Recusada' :
                (tableRowEntity.wkf_estado ? tableRowEntity.wkf_estado :
                ' - ')))))))}}">

				</label><span ng-if="tableRowEntity.situacao === '-'" >{{tableRowEntity.situacao}}</span><label
				class="label label-encaminhado" ng-bind-template="{{tableRowEntity.statusprocesso === 1 ? 'enviada' :
				(tableRowEntity.statusprocesso === 2 ? 'em acompanhamento' : '')}}"></label>
              `;
				return someHtmlVar;
			},
		},
	];
	public selects: any = {
		'situacao':
		{
			'-1': 'Rascunho',
			'0': 'Aberta',
			'1': 'Efetuada',
			'2': 'Cancelada',
			'4': 'Recusada'
		}
	};
	public options: any = {
		'estabelecimento': 'estabelecimento',
		'situacao': 'situacao',
		'trabalhador': 'trabalhador'
	};
	public solicitacoestipos: any = {
		'': 'Todos',
		'0': 'Admissão Preliminar',
		'1': 'Rescisão',
		'2': 'Adiantamento Avulso',
		'3': 'VT Adicional',
		'4': 'Alteração de VT',
		'5': 'Alteração de Endereço',
		'6': 'Falta',
		'7': 'Férias',
		// '8': 'Salário Sob Demanda',
		'8': 'Afastamento',
		'9': 'Promoção',
		'10': 'Créditos e Descontos',
		'12': 'Admissão completa',
		'13': 'Transferência de colaborador',
		'14': 'Mudança de Horário',
		'15': 'Admissão de estagiário',
		'16': 'Inclusão de Dependente',
		'20': 'Alteração de dados salariais',
		'24': 'Ajuste no retorno do afastamento',
	};
	public situacoes = {
		'-1': 'Rascunho',
		'0': 'Aberta',
		'1': 'Efetuada',
		'2': 'Cancelada',
		'4': 'Recusada'
	};
	public filterActive: boolean = false;
	public configDesativaAdmPreliminar: boolean;

	// inicio - busca avançada - variáveis
	public trabalhadorinput: string = '';
	public entitiesTrabalhadores: Array<ITrabalhador>;
	public constructorSearchList: any = {};
	public tipo: string = '';
	// fim - busca avançada - variáveis

	constructor(
		public $rootScope: angular.IScope & {
			nsjGlobals: any,
			session: any,
			configuracoes: any,
			modoGestorPermissao: (arg: string) => boolean,
			temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
		},
		public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
		public $http: angular.IHttpService,
		public $stateParams: angular.ui.IStateParamsService,
		public $location: angular.ILocationService,
		public $transitions: any,
		public nsjRouting: any,
		public NewToaster: any,
		public entityService: MeurhSolicitacoes,
		public RelatoriosService: any,
		public PreviousState: any,
		public PermissaoService: any,
		public trabalhadoresService: TrabalhadoresService,
		public BuscaAvancadaService: any,
		public ListagemService: ListagemService
	) {
		this.tipo = $stateParams.tipo ? $stateParams.tipo : '-1';
		if (!this.PermissaoService.temPermissao(this.listagemPermissao)) {
			$state.go('dp', $stateParams);
		}

		entityService.filter = $stateParams.q ? $stateParams.q : '';
		entityService.filters = {};
		entityService.constructors = {};

		for (let i in $stateParams) {
			if (['estabelecimento', 'situacao', 'trabalhador'].indexOf(i) > -1 && $stateParams[i] !== undefined) {
				entityService.filters[i] = $stateParams[i];
			} else if (typeof $stateParams[i] !== 'undefined' && typeof $stateParams[i] !== 'function' && i !== 'q' && i !== 'solicitacao') {
				entityService.constructors[i] = $stateParams[i];
			}
		}

		this.service = entityService;
		this.relatoriosServ = RelatoriosService;
		this.filters = entityService.filters = {};
		this.filters = {
			trabalhador: null,
			tiposolicitacao: '',
			situacao: '',
			datainicial: null,
			datafinal: null,
			empresa: this.$rootScope.session.entities.empresa.empresa,
			estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
		};
		this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
		this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);

		this.constructors = entityService.constructors = [];
		this.options = { 'estabelecimento': 'estabelecimento', 'situacao': 'situacao', 'trabalhador': 'trabalhador', };
		this.maxoccurs = {};

		$scope.$on('meurh_solicitacoes_deleted', (event: angular.IAngularEvent): void => {
			this.reload();
		});

		$scope.$on('$destroy', (): void => {
			if (this.entityService.loading_deferred) {
				this.entityService.loading_deferred.resolve();
			}
		});

		$rootScope.$on('meurh_solicitacoes_submitted', (event: angular.IAngularEvent, args: any): void => {
			if (!args.autosave) {
				this.reload();
			}
		});

		$rootScope.$on('Trabalhadores_loading', (event: angular.IAngularEvent, args: any): void => {
			if (!this.primeiroLookupTrabalhadoresCarregado) {
				this.lookupTrabalhadores = true;
				this.primeiroLookupTrabalhadoresCarregado = true;
			}
		});

		$rootScope.$on('Trabalhadores_loaded', (event: angular.IAngularEvent, args: any): void => {
			this.lookupTrabalhadores = false;
		});

		// busca avançada \/
		this.entitiesTrabalhadores = this.trabalhadoresService.reload();
		$rootScope.$on('persona_trabalhadores_list_finished', (event: angular.IAngularEvent, args: any) => {
			this.entitiesTrabalhadores = [...args];
		});
		// busca avançada /\

		this.dataAtual = moment();
		this.findEntity = this.findEntity.bind(this);

		$rootScope.$on('meurh_solicitacoes_list_hasdata', (event: angular.IAngularEvent, args: any) => {
			this.filtraSolicitacoesNaoImplementadas(args);
		});

        this.configDesativaAdmPreliminar = this.$rootScope.configuracoes.DESABILITAR_ADMISSAO_PRELIMINAR;
	}

	$onInit(): void {
		this.$scope.$watch('mrh_slctcs_lst_cntrllr.entities', (newValue: any, oldValue: any) => {
			if (newValue !== oldValue) {
				this.buscarAnteriomenteSelecionado(newValue);
			}
		}, true);

		this.reload();
	}

	reload(): void {
		this.entityService.constructors = this.montaConstrutor();
		this.entities = this.entityService.reload();
	}

	finished(): boolean {
		return this.entityService.loadParams.finished;
	}

	mudaEstabelecimentos(): void {
		this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
		this.filters.estabelecimento = '';
		this.filtraEmpresaEstabelecimento();
	}

	filtraEmpresaEstabelecimento(): void {
		delete this.filters.trabalhador;
		this.$rootScope.$broadcast('departamento_refresh', this.montaConstructorDepartamentos());
		this.eventoTipo();
		this.reload();
		this.setColunaEmpresa();
		this.setColunaEstabelecimento();
	}

	setColunaEmpresa(): void {
		let temColunaEmpresa = this.fields.some((field: any) => field.label === 'Empresa');
		if ((this.filters.empresa === '') && !temColunaEmpresa) {
			this.fields.splice(3, 0, {
				value: 'empresa.razaosocial',
				label: 'Empresa',
				type: 'string',
				style: 'title',
				copy: '',
			});
		} else if ((this.filters.empresa !== '') && temColunaEmpresa) {
			this.fields = this.fields.filter((field: any) => field.label !== 'Empresa');
		}
	}

	setColunaEstabelecimento(): void {
		let temColunaEstabelecimento = this.fields.some((field: any) => field.label === 'Estabelecimento');
		let posicaoColuna = this.fields.some((field: any) => field.label === 'Empresa') ? 4 : 3;
		if ((this.filters.estabelecimento === '') && !temColunaEstabelecimento) {
			this.fields.splice(posicaoColuna, 0, {
				value: 'estabelecimento.nomefantasia',
				label: 'Estabelecimento',
				type: 'string',
				style: 'title',
				copy: '',
			});
		} else if ((this.filters.estabelecimento !== '') && temColunaEstabelecimento) {
			this.fields = this.fields.filter((field: any) => field.label !== 'Estabelecimento');
		}
	}

	montaConstructorDepartamentos() {
		return { 'empresa': this.filters.empresa, 'estabelecimento': this.filters.estabelecimento };
	}

	montaConstrutorColaboradores(): object {
		let construtor: any = {};
		construtor.forcagestor = false;

		return construtor;
	}

	montaConstrutor(): object {
		let construtor = angular.copy(this.filters);

		if (construtor.trabalhador && typeof construtor.trabalhador === 'object') {
			construtor.trabalhador = this.filters.trabalhador.trabalhador;
		} else {
			delete construtor.trabalhador;
		}

		if (construtor.tiposolicitacao === '') {
			delete construtor.tiposolicitacao;
		}

		if (construtor.situacao === '') {
			delete construtor.situacao;
		}

		if (!this.valorExiste(construtor.departamento)) {
			delete construtor.departamento;
		} else {
			construtor.departamento = construtor.departamento.departamento;
		}

		if (construtor.trabalhador === '') {
			delete construtor.trabalhador;
		}

		let created_at: Array<any> = [];

		if (this.filters.datainicial === undefined || (this.filters.datainicial !== null && this.filters.datainicial.length === 0)) {
			this.filters.datainicial = null;
			construtor.datainicial = null;
		}

		if (this.filters.datainicial !== null) {
			created_at.push({
				condition: 'gte',
				value: construtor.datainicial + ' 00:00:00'
			});

			delete construtor.datainicial;
		} else {
			delete construtor.datainicial;
		}

		if (this.filters.datafinal === undefined || (this.filters.datafinal !== null && this.filters.datafinal.length === 0)) {
			this.filters.datafinal = null;
			construtor.datafinal = null;
		}

		if (this.filters.datafinal !== null) {
			created_at.push({
				condition: 'lte',
				value: construtor.datafinal + ' 23:59:00'
			});

			delete construtor.datafinal;
		} else {
			delete construtor.datafinal;
		}

		if (created_at.length > 0) {
			construtor.created_at = created_at;
		}

		return construtor;
	}

	generateRoute(route: string, params: any): string {
		return this.nsjRouting.generate(route, params, true);
	}

	isBusy(): boolean {
		return this.entityService.loadParams.busy;
	}

	abrirRelatorio(): void {
		let construtor = this.montaConstrutor();
		this.relatoriosServ.abrirRelatorio('meurh_solicitacoes_relatorio', construtor);
	}

	buscarAnteriomenteSelecionado(entities: Array<ISolicitacao>): void {
		let entity: any = null;

		if (this.PreviousState.Name !== 'meurh_solicitacoes_show') {
			return;
		}

		if (angular.isArray(entities) && entities.length && !this.busy) {
			entity = entities.find(this.findEntity);
		}

		if (entity) {
			this.entityScroll = entity;
			return;
		}

		this.loadMore();
	}

	filtraSolicitacoesNaoImplementadas(data: Array<ISolicitacao>): void {
		if (data.length > 0) {
			let entitiesFiltered = data.filter(solicitacao => solicitacao.tiposolicitacao in this.solicitacoestipos);
			this.entities = angular.copy(entitiesFiltered);
		}
	}

	temSolicitacao(codigo: number | string): boolean {
		return this.entityScroll ? (codigo === this.entityScroll.codigo) : false;
	}

	findEntity(entity: ISolicitacao): boolean {
		return entity.solicitacao === this.PreviousState.Params.solicitacao;
	}

	carregandoLookups(): boolean {
		return this.lookupTrabalhadores;
	}

	// search(): Array<ISolicitacao> {
	// 	if (this.entityService.filter !== '') {
	// 		this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
	// 	} else {
	// 		this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
	// 	}

	// 	var filter = { search: this.entityService.filter, filters: angular.copy(this.entityService.filters) };

	// 	return this.entityService.search(filter);
	// }

	search(filter: any): Array<ISolicitacao> {
		let entities = this.entityService.search(filter);
		let filterURL = angular.copy(this.entityService.filters);

		if (filter && filter.search !== '') {
			this.$location.path(this.$location.path()).search(angular.extend({}, filterURL, { 'q': this.entityService.filter }));
		} else {
			this.$location.path(this.$location.path()).search(angular.extend({}, filterURL));
		}

		return entities;
	}

	loadMore(): void {
		this.entityService.loadMore();
	}

	filtroSituacao(entity: ISolicitacao): string {
		if ((entity.tiposolicitacao === 7 || entity.tiposolicitacao === 12 || entity.tiposolicitacao === 13) && entity.situacao === 0) {
			return 'Enviada';
		} else if (entity.tiposolicitacao === 9 && entity.situacao === 1) {
			return 'Aprovada';
		}

		return this.situacoes[entity.situacao];
	}

	goToShow(entity: any): void {
		let rota = '';
		let path = 1; // 1 significa vindo da página de gerenciamento de solicitações
		switch (entity.tiposolicitacao) {
			case TipoSolicitacaoEnum.ADIANTAMENTO_AVULSO:
				rota = 'meurh_solicitacoesadiantamentosavulsos_show';
				break;
			case TipoSolicitacaoEnum.ADMISSAO_PRELIMINAR:
				rota = 'meurh_solicitacoesadmissoes_show';
				break;
			case TipoSolicitacaoEnum.ADMISSOESCOMPLETAS:
				rota = 'meurh_admissaocompleta_show';
				break;
			case TipoSolicitacaoEnum.AFASTAMENTO:
				rota = 'meurh_afastamentos_show';
				break;
			case TipoSolicitacaoEnum.ALTERACAO_DADOS_CADASTRAIS:
				rota = 'meurh_solicitacoesalteracoesenderecos_show';
				break;
			case TipoSolicitacaoEnum.ALTERACAO_VT:
				rota = 'meurh_solicitacoesalteracoesvts_show';
				break;
			case TipoSolicitacaoEnum.CREDITOS_E_DESCONTOS:
				rota = 'creditosdescontos_show';
				break;
			case TipoSolicitacaoEnum.FALTA:
				rota = 'meurh_solicitacoesfaltas_show';
				break;
			case TipoSolicitacaoEnum.FERIAS:
				rota = 'ferias_marcacoes_show';
				break;
			case TipoSolicitacaoEnum.PROMOCAO:
				rota = 'promocoes_show';
				break;
			case TipoSolicitacaoEnum.RECISAO:
				rota = 'meurh_solicitacoesrescisoes_show';
				break;
			case TipoSolicitacaoEnum.TRANSFERENCIA_DE_COLABORADOR:
				rota = 'meurh_solicitacoestransferenciasvagas_show';
				break;
			case TipoSolicitacaoEnum.VT_ADICIONAL:
				rota = 'meurh_solicitacoesvtsadicionais_show';
				break;
			case TipoSolicitacaoEnum.MUDANCA_HORARIO:
				rota = 'meurh_mudancahorario_show';
				break;
			case TipoSolicitacaoEnum.INCLUSAODEPENDENTE:
				rota = 'meurh_inclusaodependente_show';
				break;
			case TipoSolicitacaoEnum.ALTERACAODADOSSALARIAIS:
				rota = 'meurh_solicitacoesalteracoestiporecebimento_show';
				break;
			case TipoSolicitacaoEnum.AJUSTERETORNOAFASTAMENTO:
				rota = 'meurh_solicitacoesafastamentosretorno_show';
				break;
		}

		if (this.valorExiste(rota)) {
			this.busy = true;
			this.$state.go(rota,
				angular.extend({}, {
					'solicitacao': entity.solicitacao,
					'path': path,
				})
			);
		} else {
			this.NewToaster.pop({
				type: 'error',
				title: 'Não foi possível visualizar esta solicitação.'
			});
		}
	}

	valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}

	// iNÍCIO - BUSCA AVANÇADA
	buscaAvancada() {
		this.trabalhadoresService.constructors.tipo = this.tipo;
		delete this.trabalhadoresService.constructors.trabalhador;

		let columns = ['Nome', 'Tipo'];
		let values = ['nome', 'tipo'];
		let enumValues: any = [];
		enumValues['nome'] = '';
		enumValues['tipo'] = 'lookupTipoTrabalhador';
		this.trabalhadoresService.constructors['status'] = '00';
		let modal = this.BuscaAvancadaService.open(this, this.filters, this.trabalhadoresService, columns, values, 'Colaboradores', enumValues, 'trabalhador', true, false, true, () => this.selecionaTrabalhador());
		modal.result.then(() => {/* */ }).catch(() => {/* */ });
	}

	evento(event: any) {
		this.constructorSearchList = {
			'empresa': this.filters.empresa,
			'estabelecimento': this.filters.estabelecimento,
			'status': '00',
			'forcagestor': this.$rootScope.modoGestorPermissao('registrar_apontamento'),
		};
		let filter: any = {};
		filter.search = event.detail.value;
		this.trabalhadoresService.constructors = this.constructorSearchList;
		this.trabalhadoresService.filter = event.detail;
		this.trabalhadoresService.search(filter);
	}

	eventoTipo() {
		this.constructorSearchList = {
			'empresa': this.filters.empresa,
			'estabelecimento': this.filters.estabelecimento,
			'tipo': this.tipo,
			'status': '00'
		};
		this.trabalhadoresService.constructors = this.constructorSearchList;
		this.entitiesTrabalhadores = this.trabalhadoresService.reload();
	}

	retornaListagemCombo() {
		return this.entitiesTrabalhadores;
	}

	comboBusy() {
		return this.trabalhadoresService.loadParams.busy;
	}

	selecionaTrabalhador(event: any = null) {
		if (event) {
			this.filters.trabalhador = event.detail.value;
		}
		this.reload();
	}

	limpaTrabalhador() {
		delete this.filters.trabalhador;
		this.reload();
	}
	// fIM - BUSCA AVANÇADA
}
