import ng from 'angular';
import angular from 'angular';
import { StatusProcessoEnum } from '../../enums/statusprocesso.enum';
import { TipoRecebimentobolsaEnum } from '../../enums/tiporecebimentobolsa.enum';
import { TipoContaPixEnum } from '../../../../../shared/enums/TipoContaPixEnum';

export class MeurhAdmissoesestagiarioOutrasinformacoesFormController implements ng.IController {

    static $inject = ['$rootScope'];

    public entity: any;
    public form: angular.IFormController;
    public action: string;

    public tipoContaPixEnum = TipoContaPixEnum;

    private statusProcessoEnum = StatusProcessoEnum;
    private tipoRecebimentobolsaEnum = TipoRecebimentobolsaEnum;

    constructor(
        private $rootScope: ng.IRootScopeService & { configuracoes: any },
    ) { }

    public alertaCampoInvalido(tipoAlerta: 'vazio' | 'pattern', campo: any): boolean {
        const condicoes = {
            'vazio': campo && campo.$error.required && (campo.$dirty || this.form.$submitted),
            'pattern': campo && campo.$error.pattern
        };

        return condicoes[tipoAlerta];
    }

    public desabilitaCampo(): boolean {
        return (
            this.action === 'show' ||
            this.entity.statusprocesso === this.statusProcessoEnum.ENVIADA ||
            this.entity.statusprocesso === this.statusProcessoEnum.EM_ACOMPANHAMENTO ||
            this.entity.$$__submitting
        );
    }

    public camposPagamento(tipo: 'banco' | 'pix'): boolean {
        const condicoes = {
            'banco': (
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_CORRENTE ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_POUPANCA ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_SALARIO ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_AGENCIA_CONTA
            ),
            'pix': (
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CPF ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_EMAIL ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CELULAR ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CHAVE_ALEATORIA
            )
        };

        return condicoes[tipo];
    }

    public getChavepixLabel(): string {
        const labels = {
            '128': 'CPF',
            '256': 'E-mail',
            '512': 'Celular',
            '1024': 'Chave aleatória',
        };

        return labels[this.entity.tiporecebimentobolsa];
    }

    // início - controle do formulário
    public aplicarSemDadosBancarios(): void {
        delete this.entity.banco;
        delete this.entity.agencia;
        delete this.entity.agencianumero;
        delete this.entity.numerocontasalario;
        delete this.entity.numerocontasalariodv;
    }

    public preencheCamposRecebimento(): void {
        if (this.entity.tiporecebimentobolsa !== '') {
            this.entity.semdadosbancarios = false;
        }

        if (this.entity.tiporecebimentobolsa !== '64') {
            this.entity.tipocontapix = null;
        }

        if (!this.camposPagamento('banco')) {
            this.aplicarSemDadosBancarios();
        }

        switch (this.entity.tiporecebimentobolsa) {
            case this.tipoRecebimentobolsaEnum.PIX_CPF:
                this.entity.chavepix = this.entity.cpf;
                break;

            case this.tipoRecebimentobolsaEnum.PIX_EMAIL:
                this.entity.chavepix = this.entity.email;
                break;

            case this.tipoRecebimentobolsaEnum.PIX_CELULAR:
                if (this.entity.dddcel !== undefined || this.entity.celular !== undefined) {
                    this.entity.chavepix = this.entity.dddcel + this.entity.celular;
                }
                break;

            default:
                this.entity.chavepix = '';
                break;
        }
    }

    public desabilitarNenhumTipoPagamento(): boolean {
        return !!this.$rootScope.configuracoes.DESABILITAR_NENHUM_TIPO_PAGAMENTO_ADMISSOES;
    }

    public desabilitarNenhumaInstituicaoBancaria(): boolean {
        return !!this.$rootScope.configuracoes.DESABILITAR_NENHUMA_INSTITUICAO_BANCARIA_ADMISSOES;
    }
    // fim - controle do formulário
}
