import angular = require('angular');
import { MeurhAcompanhamentoService } from '../../../Acompanhamento/acompanhamento.service';
import { MeurhSolicitacoes } from '../../../Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../../Workflow/workflow.service';
import moment = require('moment');
import { MeurhFeriasService } from '../../ferias.service';
import { MeurhMarcacoesAnterioresModalService } from '../marcacoesanterioresmodal/marcacoesanteriores.modal.service';
import { MeurhConfirmarEnvioModalService } from '../confirmarenviomodal/confirmarenvio.modal.service';
import { IPeriodoAquisitivo } from '../../models/periodoaquisitivo.model';
import { IFerias } from '../../models/ferias.model';
import { ISolicitacao } from '../../../Solicitacoes/models/solicitacao.model';
import { TipoColaboradorEnum } from '../../../../../shared/enums/TipoColaboradorEnum';

export class MeurhFeriasmarcacoesShowController implements ng.IController {
	static $inject = ['$scope', '$stateParams', '$state', 'MeurhFeriasService', 'entity', 'NewToaster', '$rootScope', '$http', 'nsjRouting', '$q', 'MeurhAcompanhamentoService', 'MeurhMarcacoesAnterioresModalService', 'MeurhConfirmarEnvioModalService', 'WorkflowService', 'ModalConfirmService', 'MeurhSolicitacoes'];

	public col_defs = [
		{
			field: 'created_at',
			displayName: 'Criada em',

		},
		{
			field: 'iniciogozo',
			displayName: 'Início de gozo'
		},
		{
			field: 'diasaconceder',
			displayName: 'Dias a conceder'
		},
		{
			field: 'diasvendidos',
			displayName: 'Comprar dias?'
		},
		{
			field: 'fimgozo',
			displayName: 'Fim de gozo'
		},
		{
			field: 'adto13nasferias',
			displayName: 'Adiantar 13º?'
		},
		{
			field: 'situacao',
			displayName: 'Situação',
			cellTemplate: `
      <div ng-if="!row.branch.situacao && row.branch.situacao !== 0 && row.branch.situacao !== -1">
          <span>-</span>
      </div>
      <div ng-if="row.branch.situacao === -1" class="label label-default">
          <span>Rascunho</span>
      </div>
      <div ng-if="row.branch.situacao === 0" class="label label-warning">
          <span>Enviada</span>
      </div>
      <div ng-if="row.branch.situacao === 1" class="label label-success">
          <span>Efetuada</span>
      </div>
      <div ng-if="row.branch.situacao === 2" class="label label-danger">
          <span>Fechada</span>
      </div>
      `
		}
	];

	public tree_data: any = [];
	public action: string = 'retrieve';
	public actionUpdate: string = 'update';
	public LABELS: any = {
		'-1': 'label-primary',
		'0': 'label-warning',
		'1': 'label-success',
		'2': 'label-danger'
	};
	public SITUACOES: any = {
		'-1': 'Rascunho',
		'0': 'Enviada',
		'1': 'Efetuada',
		'2': 'Fechada'
	};
	public form: angular.IFormController;
	public constructors: any = [];
	public busy: boolean = false;
	public expandOutrasMarcacoes: boolean = false;
	public outrasSolicitacoes: any;
	public marcacoesParaSalvar: number;
	public buscandoMarcacoes: boolean = false;
	public marcacoesAnteriores: Array<any>;
	public rascunhoApenas: boolean = false;
	public rascunhoSendoCriado: boolean = false;
	public path: number = this.$stateParams.path;

	public configDiasAntecedenciaFerias: number = this.$rootScope.nsjGlobals.a.configuracoes.QTD_DIAS_ANTECEDENCIA_FERIAS_MRH;
  	public dataMinimaInicioGozo: string;

	constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService & { reload: (() => void), go: ((route: string, callback: (() => void)) => void) },
		public entityService: MeurhFeriasService,
		public entity: IFerias,
		public NewToaster: any,
		public $rootScope: angular.IScope & { configuracoes: any, nsjGlobals: any },
		public $http: angular.IHttpService,
		public nsjRouting: any,
		public $q: angular.IQService,
		public MeurhAcompanhamentoService: MeurhAcompanhamentoService,
		public MeurhMarcacoesAnterioresModalService: MeurhMarcacoesAnterioresModalService,
		public MeurhConfirmarEnvioModalService: MeurhConfirmarEnvioModalService,
		public WorkflowService: WorkflowService,
		public ModalConfirmService: any,
		public MeurhSolicitacoes: MeurhSolicitacoes,
	) {
		this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
		this.MeurhAcompanhamentoService.loadParams.finished = false;
		this.MeurhAcompanhamentoService.loadParams.to_load = 3;
		this.MeurhAcompanhamentoService.load();
		this.expandOutrasMarcacoes = true;
		this.$rootScope.$on('workflow_reloaded_after', (event: any, args: any) => {
			this.$state.reload();
		});
	}

	$onInit(): void {
		this.montaTree();
		this.buscaPeriodoAquisitivo(this.entity.trabalhadorobj);
		this.onDeleteSuccess();
		this.onDeleteError();
		this.onSubmitError();

		this.afterReloadWorkflow();
		this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesferias_');

		let dataAtual = moment();

		// controle não é necessário para estagiários
		if (this.entity.trabalhadorobj!.tipo !== TipoColaboradorEnum.ESTAGIARIO) {
			// data que será considerada para desativar dias no calendário
			let dataLiberacaoCalendario = (
				this.$rootScope.nsjGlobals.a.configuracoes.MARCAR_FERIAS_ANTES_PERIODO === false
				&& dataAtual < moment(this.entity.datafimperiodoaquisitivo).add(1, 'days')
			)
			? moment(this.entity.datafimperiodoaquisitivo).add(1, 'days')
			: dataAtual;

			let diferencaDatas = dataLiberacaoCalendario.diff(dataAtual, 'days');

			// só deve somar o valor da configuração com a data de liberação do calendário caso a diferença entre os dias seja menor que o valor da configuração
			if (diferencaDatas < this.configDiasAntecedenciaFerias) {
				dataLiberacaoCalendario.add(this.configDiasAntecedenciaFerias, 'days');
			}

			this.dataMinimaInicioGozo = dataLiberacaoCalendario.format('YYYY-MM-DD');
		}

	}

	afterReloadWorkflow(): void {
		this.$scope.$on('workflow_reloaded_after', (event: any, args: any) => {
			this.$state.reload();
		});
	}
	buscaPeriodoAquisitivo(trabalhador: any): void {
		if (trabalhador) {
			this.busy = true;
			this.$http({
				method: 'GET',
				url: this.nsjRouting.generate('meurh_solicitacoesferias_lista_periodos', angular.extend({ id: trabalhador.trabalhador }, [], { 'offset': [], 'filter': {} }, {}), true),
				timeout: this.$q.defer().promise
			}).then((response: any) => {
				this.busy = false;
				this.precisaMarcarPeriodoAnterior(response.data);
				this.$rootScope.$broadcast('periodos_aquisitivos_loaded', {
					response: response.data
				});
			});
		}
	}

	precisaMarcarPeriodoAnterior(solicitacoesFeriasAgrupadas: any): boolean {
		let periodoSelecionado = this.entity.datainicioperiodoaquisitivo;

		let precisaMarcarPeriodoAnterior = solicitacoesFeriasAgrupadas.periodosaquisitivos.some((periodo: any) => {
			if (moment(periodoSelecionado).isAfter(periodo.inicioperiodoaquisitivo) && periodo.saldo > 0) {
				this.rascunhoApenas = true;
				return true;
			} else {
				this.rascunhoApenas = false;
			}
		});

		return precisaMarcarPeriodoAnterior;
	}

	enviarSolicitacao(): void {
		let entity = angular.copy(this.entity);

		if (entity.trabalhadorobj?.tipo !== TipoColaboradorEnum.ESTAGIARIO) {
			let dataInicioGozoFormat = moment(entity.datainiciogozo, 'DD/MM/YYYY').format('YYYY-MM-DD');

			// se a data inicio gozo for menor que dataMinimaGozo, mostrar toaster e não enviar
			if (moment(dataInicioGozoFormat, 'YYYY-MM-DD').isBefore(this.dataMinimaInicioGozo)) {
				this.NewToaster.pop({
					type: 'error',
					title: 'Início de gozo inválido',
					body: 'A data de início de gozo não pode ser inferior a data mínima de início de gozo.',
					timeout: 5000
				});
				return;
			}

		}

		if (this.existemMarcacoesAnteriores() && !this.rascunhoApenas) {
			let modal = this.MeurhMarcacoesAnterioresModalService.open(this.marcacoesAnteriores);
			modal.result.then((confirmEnviar: boolean) => {
				if (this.form.$valid && !this.entity.$$__submitting && confirmEnviar) {
					this.busy = true;
					entity.situacao = 0;
					entity.trabalhador = this.entity.trabalhadorobj?.trabalhador!;
					this.marcacoesAnteriores.push(entity);
					this.marcacoesAnteriores.map((marcacao: any) => {
						marcacao.situacao = 0;
					});
					this.marcacoesParaSalvar = this.marcacoesAnteriores.length;
					this.marcacoesAnteriores.forEach((element: any) => {
						if (element.dataaviso) {
							delete element.dataaviso;
						}

						element.datafimgozo = this.tratarData(element.datafimgozo);
						element.datafimperiodoaquisitivo = this.tratarData(element.datafimperiodoaquisitivo);
						element.datainiciogozo = this.tratarData(element.datainiciogozo);
						element.datainicioperiodoaquisitivo = this.tratarData(element.datainicioperiodoaquisitivo);

						this.entityService.save(element, {
							empresa: this.entity.empresaobj.empresa,
							estabelecimento: this.entity.estabelecimentoobj.estabelecimento
						});
					});
					this.$scope.$on('meurh_solicitacoesferias_submitted', (event: angular.IAngularEvent, args: any) => {
						this.marcacoesParaSalvar = this.marcacoesParaSalvar - 1;
						if (this.marcacoesParaSalvar === 0) {
							this.busy = false;
							this.NewToaster.pop({
								type: 'success',
								title: 'As solicitações de marcações de férias foram criadas com sucesso!'
							});
						}
					});
				} else {
					if (confirmEnviar) {
						this.NewToaster.pop({
							type: 'error',
							title: 'Ocorreu um erro ao criar as marcações de férias!',
						});
					}
				}
			});
		} else if (this.rascunhoApenas) {
			this.NewToaster.pop({
				type: 'error',
				title: 'Atenção!',
				body: 'A marcação de férias do período aquisitivo selecionado só poderá ser enviada após o envio das marcações do período aquisitivo anterior.'
			});
		} else {
			this.MeurhConfirmarEnvioModalService.open().result.then((confirm: boolean) => {
				if (confirm) {
					if (this.form.$valid && !this.entity.$$__submitting) {
						this.busy = true;
						entity.situacao = 0;
						entity.trabalhador = this.entity.trabalhadorobj?.trabalhador!;
						delete entity.dataaviso;
						entity.datafimgozo = this.tratarData(entity.datafimgozo);
						entity.datafimperiodoaquisitivo = this.tratarData(entity.datafimperiodoaquisitivo);
						entity.datainiciogozo = this.tratarData(entity.datainiciogozo);
						entity.datainicioperiodoaquisitivo = this.tratarData(entity.datainicioperiodoaquisitivo);
						this.entityService.save(entity, {
							empresa: this.entity.empresaobj.empresa,
							estabelecimento: this.entity.estabelecimentoobj.estabelecimento
						});
						this.$scope.$on('meurh_solicitacoesferias_submitted', (event: angular.IAngularEvent, args: any) => {
							if (this.tree_data.length > 0) {
								this.tree_data[0].children.map((marcacao: any) => {
									if (moment(marcacao.iniciogozo, 'DD/MM/YYYY').format('YYYY-MM-DD') === this.entity.datainiciogozo && moment(marcacao.fimgozo, 'DD/MM/YYYY').format('YYYY-MM-DD') === this.entity.datafimgozo) {
										marcacao.situacao = 0;
									}
								});
							}
							this.entity.situacao = 0;
							this.busy = false;
							this.NewToaster.pop({
								type: 'success',
								title: 'A solicitação de marcação de férias foi criada com sucesso!'
							});
						});
					} else {
						this.NewToaster.pop({
							type: 'error',
							title: 'Ocorreu um erro ao criar a marcação de férias!',
							body: 'Por favor, verifique os campos destacados em vermelho.'
						});
					}
				}

			});
		}
	}

	salvar(rascunho: boolean = false): void {
		let entity = angular.copy(this.entity);
		if (!rascunho && this.existemMarcacoesAnteriores()) {
			let modal = this.MeurhMarcacoesAnterioresModalService.open(this.marcacoesAnteriores);

			modal.result.then((confirmEnviar: boolean) => {
				if (this.form.$valid && !this.entity.$$__submitting && confirmEnviar) {
					this.busy = true;
					entity.trabalhador = this.entity.trabalhadorobj?.trabalhador!;
					this.marcacoesAnteriores.push(entity);

					this.marcacoesAnteriores.map((marcacao: any) => {
						marcacao.situacao = 0;
					});

					this.marcacoesAnteriores.forEach((element: any) => {
						if (element.dataaviso) {
							delete element.dataaviso;
						}
						element.datafimgozo = this.tratarData(element.datafimgozo);
						element.datafimperiodoaquisitivo = this.tratarData(element.datafimperiodoaquisitivo);
						element.datainiciogozo = this.tratarData(element.datainiciogozo);
						element.datainicioperiodoaquisitivo = this.tratarData(element.datainicioperiodoaquisitivo);
						this.entityService.save(element, {
							empresa: this.entity.empresaobj.empresa,
							estabelecimento: this.entity.estabelecimentoobj.estabelecimento
						});
					});

					this.marcacoesParaSalvar = this.marcacoesAnteriores.length;

					this.$scope.$on('meurh_solicitacoesferias_submitted', (event: any, args: any) => {
						this.marcacoesParaSalvar = this.marcacoesParaSalvar - 1;
						this.busy = false;
						if (this.marcacoesParaSalvar === 0) {
							this.NewToaster.pop({
								type: 'success',
								title: 'As solicitações de marcações de férias foram criadas com sucesso!'
							});

							this.$state.go('ferias_marcacoes', angular.extend({}, {}));
						}
					});
				} else {
					if (confirmEnviar) {
						this.NewToaster.pop({
							type: 'error',
							title: 'Ocorreu um erro ao criar as marcações de férias!',
						});
					}
				}
			});
		} else if (this.form.$valid && !this.entity.$$__submitting) {
			this.busy = true;
			this.rascunhoSendoCriado = rascunho;
			entity.trabalhador = this.entity.trabalhadorobj?.trabalhador!;
			delete entity.dataaviso;
			entity.datafimgozo = this.tratarData(entity.datafimgozo);
			entity.datafimperiodoaquisitivo = this.tratarData(entity.datafimperiodoaquisitivo);
			entity.datainiciogozo = this.tratarData(entity.datainiciogozo);
			entity.datainicioperiodoaquisitivo = this.tratarData(entity.datainicioperiodoaquisitivo);
			entity.situacao = rascunho ? -1 : 0;
			this.entityService.save(entity, {
				empresa: this.entity.empresaobj.empresa,
				estabelecimento: this.entity.estabelecimentoobj.estabelecimento
			});

			this.$scope.$on('meurh_solicitacoesferias_submitted', (event: any, args: any) => {
				this.busy = false;

				if (this.rascunhoSendoCriado) {
					this.rascunhoSendoCriado = false;

					this.NewToaster.pop({
						type: 'success',
						title: 'O rascunho de marcação de férias foi criado com sucesso!'
					});

					this.$state.go('ferias_marcacoes_show', angular.extend({}, {
						'solicitacao': args.response.data.solicitacao
					}));
				} else {
					this.NewToaster.pop({
						type: 'success',
						title: 'A solicitação de marcação de férias foi criada com sucesso!'
					});

					this.$state.go('ferias_marcacoes_show', angular.extend({}, {
						'solicitacao': args.response.data.solicitacao
					}));
				}

			});
		} else {
			this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro ao criar a marcação de férias!',
				body: 'Por favor, verifique os campos destacados em vermelho.'
			});
		}
	}

	delete(): void {
		let customText = {
			title: 'Excluir marcação',
			confirmText: 'Tem certeza que deseja excluir a marcação de férias do colaborador ' + this.entity.trabalhadorobj.nome + '?',
			closeButton: 'Cancelar',
			confirmButton: 'Sim, excluir marcação',
		};
		let confirm = this.ModalConfirmService.open(this.entity, 'Marcação de férias', false, customText);
		confirm.result.then(() => {
			this.entityService.delete(this.$stateParams['solicitacao'], true);
		}).catch(function () {/**/ });
	}

	existemMarcacoesAnteriores(): boolean {
		let periodosaquisitivos = JSON.parse(JSON.stringify(this.outrasSolicitacoes.periodosaquisitivos));
		var solicitacoes;

		if (periodosaquisitivos) {
			for (let periodo in periodosaquisitivos) {
				if (periodosaquisitivos[periodo].hasOwnProperty('solicitacoes')) {
					if (periodosaquisitivos[periodo]['solicitacoes'].length > 0) {
						solicitacoes = periodosaquisitivos[periodo]['solicitacoes'];
						return this.contaMarcacoesAnteriores(solicitacoes);
					} else {
						return false;
					}
				}
			}
		} else {
			return false;
		}
	}

	contaMarcacoesAnteriores(solicitacoes: Array<IFerias>): boolean {
		this.marcacoesAnteriores = [];

		solicitacoes.forEach((solicitacao: any) => {
			if (moment(solicitacao.datainiciogozo).isBefore(this.entity.datainiciogozo) && solicitacao.situacao === -1) {
				solicitacao['trabalhadorobj'] = this.entity.trabalhadorobj;

				this.marcacoesAnteriores.push(solicitacao);
			}
		});

		if (this.marcacoesAnteriores.length > 0) {
			return true;
		}

		return false;
	}

	existemOutrasMarcacoes(): boolean {
		return this.tree_data.length > 0 && this.tree_data[0].children.length > 0;
	}

	cancelar(entity: ISolicitacao): void {
		let customText = {
			title: 'Cancelar',
			subtitle: 'Você está prestes a cancelar a solicitação de férias de',
			confirmText: 'Após o cancelamento, não será possível desfazer. Deseja continuar?',
			confirmTextNum: this.entity.trabalhadorobj?.nome,
			closeButton: 'Voltar',
			confirmButton: 'Cancelar solicitação'
		};
		var confirm = this.ModalConfirmService.openCancelar(entity, 'Solicitação de Férias', customText);

		confirm.result.then((entity: ISolicitacao) => {
			this.busy = true;

			this.MeurhSolicitacoes.cancelar(entity)
				.then((response: any) => {
					this.NewToaster.pop({
						type: 'success',
						title: 'A solicitação de férias foi cancelada com sucesso!'
					});

					this.entityService.reload();
					this.$state.go('ferias_marcacoes', angular.extend(this.entityService.constructors));
				}).catch((response: any) => {
					if (typeof (response.data.message) !== 'undefined' && response.data.message) {
						this.NewToaster.pop({
							type: 'error',
							title: response.data.message
						});
					} else {
						this.NewToaster.pop({
							type: 'error',
							title: 'Ocorreu um erro ao cancelar a solicitação de férias!',
							body: 'Por favor, verifique os campos em vermelho.'
						});
					}
				}).finally((): void => {
					this.busy = false;
				});
		}).catch((error: any) => {
			if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
				this.NewToaster.pop({
					type: 'error',
					title: error
				});
			}
		});
	}

	montaTree(): void {
		this.buscandoMarcacoes = true;

		this.$http({
			method: 'GET',
			url: this.nsjRouting.generate('meurh_solicitacoesferias_get_agrupado', angular.extend({ id: this.entity.trabalhador }, [], { 'offset': [], 'filter': {} }), true),
			timeout: this.$q.defer().promise
		}).then((response: any) => {
			this.buscandoMarcacoes = false;
			this.outrasSolicitacoes = response.data;

			if (Object.keys(response.data.periodosaquisitivos).length > 0) {
				let periodos = response.data.periodosaquisitivos;

				for (let periodo in periodos) {
					if (periodos[periodo].hasOwnProperty('solicitacoes')) {
						let anoPeriodoAtual = moment(response.data.inicioperiodoaquisitivoferias).year();
						let anoPeriodoSolicitacao = moment(periodos[periodo].datainicioperiodoaquisitivo).year();

						if (anoPeriodoSolicitacao >= anoPeriodoAtual || this.possuiFeriasNaoGozadas(periodos[periodo])) {
							periodos[periodo].solicitacoes = periodos[periodo].solicitacoes.filter((solicitacao: any) => {
								return solicitacao.datainiciogozo !== this.entity.datainiciogozo && solicitacao.datafimgozo !== this.entity.datafimgozo && solicitacao.datainicioperiodoaquisitivo && this.entity.datainicioperiodoaquisitivo && moment(solicitacao.datafimgozo).isSameOrAfter(moment());
							});
							let children = periodos[periodo].solicitacoes.map((marcacao: any) => {
								return {
									'Período aquisitivo': '-',
									'created_at': moment(marcacao.created_at).format('DD/MM/YYYY'),
									'iniciogozo': moment(marcacao.datainiciogozo).format('DD/MM/YYYY'),
									'diasaconceder': marcacao.diasferiascoletivas,
									'diasvendidos': marcacao.diasvendidos ? marcacao.diasvendidos : 'Não',
									'fimgozo': moment(marcacao.datafimgozo).format('DD/MM/YYYY'),
									'adto13nasferias': marcacao.adto13nasferias ? 'Sim' : 'Não',
									'situacao': marcacao.situacao
								};
							});

							let datainicioperiodoaquisitivo = periodo.split('|')[0];
							let datafimperiodoaquisitivo = periodo.split('|')[1];

							if (children.length > 0) {
								this.tree_data.push(
									{
										'Período aquisitivo': `${moment(datainicioperiodoaquisitivo).format('DD/MM/YYYY')} a ${moment(datafimperiodoaquisitivo).format('DD/MM/YYYY')}`,
										'created_at': '-',
										'iniciogozo': '-',
										'diasaconceder': '-',
										'diasvendidos': '-',
										'fimgozo': '-',
										'adto13nasferias': '-',
										'situacao': '-',
										children: children,
										icons: {
											iconLeaf: 'none',
											iconCollapse: 'none',
											iconExpand: 'none'
										}
									},
								);
							}
						}
					}
				}
			}
		});
	}

	possuiFeriasNaoGozadas(periodoAquisitivo: IPeriodoAquisitivo): boolean {
		if (periodoAquisitivo.hasOwnProperty('solicitacoes')) {
			return periodoAquisitivo.solicitacoes.some((solicitacao: any) => {
				return moment(solicitacao.datafimgozo).isSameOrAfter(moment());
			});
		}
	}

	onSubmitError(): void {
		this.$scope.$on('meurh_solicitacoesferias_submit_error', (event: any, args: any) => {
			this.busy = false;

			if (args.response.status === 409) {
				if (confirm(args.response.data.message)) {
					this.entity[''] = args.response.data.entity[''];
					delete this.entity.dataaviso;
					this.entity.datafimgozo = this.tratarData(this.entity.datafimgozo);
					this.entity.datafimperiodoaquisitivo = this.tratarData(this.entity.datafimperiodoaquisitivo);
					this.entity.datainiciogozo = this.tratarData(this.entity.datainiciogozo);
					this.entity.datainicioperiodoaquisitivo = this.tratarData(this.entity.datainicioperiodoaquisitivo);
					this.entityService.save(this.entity, {
						empresa: this.entity.empresaobj.empresa,
						estabelecimento: this.entity.estabelecimentoobj.estabelecimento
					});
				}
			} else {
				if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
					if (args.response.data.message === 'Validation Failed') {
						let message: string = '';
						if (args.response.data.errors.errors) {
							message = args.response.data.errors.errors[0];
						} else if (args.response.data.errors.children) {
							let children = args.response.data.errors.children;
							let childrenMsg: string = '';
							for (let child in children) {
								if (children[child].errors !== undefined) {
									for (let error in children[child].errors) {
										if (children[child].errors.hasOwnProperty(error)) {
											childrenMsg += '<li>' + children[child].errors[error] + '</li>';
										}
									}
								}
							}
							message = 'Os seguintes itens precisam ser alterados: <ul>' + childrenMsg + '</ul>';
						}

						this.NewToaster.pop({
							type: 'error',
							title: 'Erro de Validação',
							body: message,
							bodyOutputType: 'trustedHtml'
						});
					} else {
						this.NewToaster.pop({
							type: 'error',
							title: args.response.data.message
						});
					}
				} else {
					this.NewToaster.pop({
						type: 'error',
						title: args.response.config.method === 'PUT' ? 'Ocorreu um erro ao alterar a solicitação.' : 'Ocorreu um erro ao inserir a solicitação.'
					});
				}
			}
		});
	}

	onDeleteSuccess(): void {
		this.$scope.$on('meurh_solicitacoesferias_deleted', (event: any, args: any) => {
			this.busy = false;
			this.NewToaster.pop({
				type: 'success',
				title: 'A solicitação de marcação de férias foi excluída com sucesso!'
			});

			this.$state.go('ferias_marcacoes', angular.extend(this.entityService.constructors));
		});
	}

	onDeleteError(): void {
		this.$scope.$on('meurh_solicitacoesferias_delete_error', (event: any, args: any) => {
			this.busy = false;

			if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
				this.NewToaster.pop({
					type: 'error',
					title: args.response.data.message
				});
			} else {
				this.NewToaster.pop({
					type: 'error',
					title: 'Ocorreu um erro ao excluir a solicitação.'
				});
			}
		});
	}

	goToNew(): void {
		this.$state.go('ferias_marcacoes_new', {
			trabalhador: {
				...this.entity.trabalhadorobj,
				empresaobj: this.entity.empresaobj,
				estabelecimentoobj: this.entity.estabelecimentoobj,
			}
		});
	}

	getLabels(situacao: string): string {
		return this.LABELS[situacao];
	}

	getSituacao(situacao: string): string {
		return this.SITUACOES[situacao];
	}

	tratarData(data: string): string {
		let dataTratada = data;

		let dataFragmentada = data.split('-');

		if (dataFragmentada.length === 3) {
			return data;
		}

		dataFragmentada = data.split('/');
		dataTratada = `${dataFragmentada[2]}-${dataFragmentada[1]}-${dataFragmentada[0]}`;

		return dataTratada;
	}
}
