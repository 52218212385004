import angular = require('angular');
import { MeurhSolicitacoes } from '../../../Meurh/Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';
import { ISolicitacaoAlteracaoVT } from '../models/solicitacaoalteracaovt.model';
import { MeurhSolicitacoesalteracoesvtsService } from '../solicitacoesalteracoesvts.service';

export class MeurhSolicitacoesalteracoesvtsShowController implements ng.IController {
    static $inject = ['ModalConfirmService', '$scope', '$stateParams', '$state', 'MeurhSolicitacoesalteracoesvtsService', 'entity', 'NewToaster', 'WorkflowService', 'MeurhSolicitacoes'];

    public action = 'retrieve';
    public busy: boolean = false;
    public path: number = this.$stateParams.path;

    constructor(
        public ModalConfirmService: any,
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesalteracoesvtsService,
        public entity: ISolicitacaoAlteracaoVT,
        public NewToaster: any,
        public WorkflowService: WorkflowService,
        public MeurhSolicitacoes: MeurhSolicitacoes
    ) {
        this.onDeleteSuccess();
        this.onDeleteError();
        this.afterReloadWorkflow();
    }

    $onInit(): void {
        this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesalteracoesvts_');
    }

    afterReloadWorkflow(): void {
        this.$scope.$on('workflow_reloaded_after', (event: any, args: any) => {
            this.$state.reload();
        });
    }

    delete(force: boolean): void {
        this.entityService.delete(this.$stateParams['solicitacao'], force);
    }

    cancelar(entity: ISolicitacao): void {
        let confirm = this.ModalConfirmService.openCancelar(entity, 'solicitação de alteração de VT');

        confirm.result
            .then((entity: ISolicitacao): void => {
                this.busy = true;

                this.MeurhSolicitacoes.cancelar(entity)
                    .then((): void => {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A solicitação de alteração de VT foi cancelada com sucesso.'
                        });

                        this.entityService.reload();
                        this.$state.go('meurh_solicitacoesalteracoesvts', this.entityService.constructors);
                    })
                    .catch((response: any): void => {
                        if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                            this.NewToaster.pop({
                                type: 'error',
                                title: response.data.message
                            });
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Ocorreu um erro ao cancelar a solicitação.'
                            });
                        }
                    })
                    .finally((): void => {
                        this.busy = false;
                    });
            })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    onDeleteSuccess(): void {
        this.$scope.$on('meurh_solicitacoesalteracoesvts_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'Solicitação excluída com sucesso!'
            });

            this.$state.go('meurh_solicitacoesalteracoesvts', angular.extend(this.entityService.constructors));
        });
    }

    onDeleteError(): void {
        this.$scope.$on('meurh_solicitacoesalteracoesvts_delete_error', (event: angular.IAngularEvent, args: any): void => {
            if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao excluir solicitação.'
                });
            }
        });
    }
}
