import angular from 'angular';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

type Session = {
  entities: {
    empresa: { empresa: string },
    estabelecimento: { estabelecimento: string },
  }
};

export class FormController {
  static $inject = [
    '$scope',
    '$rootScope',
    'ListagemService'
  ];

  public action: string;
  public tipo: string;
  public form: angular.IFormController;
  public busy: boolean = false;
  public entity;

  public filters = {
    empresa: this.$rootScope.session.entities.empresa.empresa,
    estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
  };
  public empresas: Array<Empresa>;
  public estabelecimentos: Array<Estabelecimento>;
  public listagemPermissao: Array<string> = ['criar_adiantamento_avulso'];
  public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

  public constructorLookupColaborador: any;

  constructor(
    public $scope: angular.IScope,
    public $rootScope: angular.IRootScopeService & {
      session: Session,
      nsjGlobals: any,
      modoGestorPermissao: (arg: string) => boolean,
      temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
      liberadoTela: (arg: string) => boolean
    },
    public ListagemService: ListagemService,
  ) {

    this.$scope.$watch('$ctrl.entity', (newValue, oldValue: { valor: number }) => {
      if (newValue !== oldValue) {
        this.form.$setDirty();
        if (newValue.valor <= 0) {
          this.form.valor.$setValidity('valorzero', false);
        } else {
          this.form.valor.$setValidity('valorzero', true);
        }
      }
    }, true);
  }

  $onInit(): void {
    this.montaListagemVisibilidade();

    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.forcaGestor(),
      ...(this.tipo && { 'tipo': this.tipo }),
    };

  }

  forcaGestor() {
    return this.$rootScope.modoGestorPermissao('criar_adiantamento_avulso');
  }

  montaListagemVisibilidade() {
    if (this.action === 'update') {
      this.filters.empresa = this.entity.estabelecimentoobj.empresa;
      this.filters.estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
    }
    this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
    this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
  }

  mudaEstabelecimentos() {
    this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
    this.filters.estabelecimento = '';
    this.filtraEmpresaEstabelecimento();
  }

  filtraEmpresaEstabelecimento() {
    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.forcaGestor(),
      ...(this.tipo && { 'tipo': this.tipo }),
    };
  }

}
