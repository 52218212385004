import angular from 'angular';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { IEntity } from '../models/IEntity.model';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';
import { MeurhSolicitacoesadiantamentosavulsosService } from '../solicitacoesadiantamentosavulsos.service';
import { MeurhSolicitacoes } from './../../Solicitacoes/services/solicitacoes.service';

export class MeurhSolicitacoesadiantamentosavulsosFormShowController {
  static $inject = [
    'ModalConfirmService', '$scope', '$stateParams', '$state', 'MeurhSolicitacoesadiantamentosavulsosService', 'entity', 'NewToaster', 'WorkflowService', 'MeurhSolicitacoes'];

  public action: string = 'retrieve';
  public busy: boolean = false;
  public reload;
  public path: number = this.$stateParams.path;

  constructor(public ModalConfirmService: any, public $scope: angular.IScope, public $stateParams: angular.ui.IStateParamsService, public $state: angular.ui.IStateService, public entityService: MeurhSolicitacoesadiantamentosavulsosService, public entity: IEntity, public NewToaster: { pop: Function }, public WorkflowService: WorkflowService, public MeurhSolicitacoes: MeurhSolicitacoes) {
    this.reload = entityService.reload;
  }

  $onInit() {
    this.onDeleteError();
    this.onDeleteSuccess();
    this.$scope.$on('workflow_reloaded_after', () => {
      this.$state.reload();
    });

    this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesadiantamentosavulsos_');

  }

  delete(force: boolean) {
    this.entityService.delete(this.$stateParams['solicitacao'], force);
  }

  onDeleteSuccess() {
    this.$scope.$on('meurh_solicitacoesadiantamentosavulsos_deleted', () => {
      this.NewToaster.pop({
        type: 'success',
        title: 'A Solicitação de Adiantamento Avulso foi excluída com sucesso.'
      });
      this.$state.go('meurh_solicitacoesadiantamentosavulsos', angular.extend(this.entityService.constructors));
    });

  }
  onDeleteError() {
    this.$scope.$on('meurh_solicitacoesadiantamentosavulsos_delete_error', (event, args) => {
      var msgErro = args.response.data ? args.response.data.message ? true : false : false;
      if (msgErro) {
        this.NewToaster.pop(
          {
            type: 'error',
            title: args.response.data.message
          });
      } else {
        this.NewToaster.pop(
          {
            type: 'error',
            title: 'Ocorreu um erro ao tentar excluir o adiantamento avulso'
          });
      }
    });

  }

  cancelar(entity: ISolicitacao): void {
		let confirm = this.ModalConfirmService.openCancelar(entity, 'solicitação de adiantamento avulso');

		confirm.result.then((entity: ISolicitacao): void => {
			this.busy = true;

			this.MeurhSolicitacoes.cancelar(entity)
				.then((response: any): void => {
					this.NewToaster.pop({
						type: 'success',
						title: 'A solicitação de adiantamento avulso foi cancelada com sucesso!'
					});

					this.entityService.reload();
					this.$state.go('meurh_solicitacoesadiantamentosavulsos', angular.extend(this.entityService.constructors));
				})
				.catch((response: any): void => {
					if (typeof (response.data.message) !== 'undefined' && response.data.message) {
						this.NewToaster.pop({
							type: 'error',
							title: response.data.message
						});
					} else {
						this.NewToaster.pop({
							type: 'error',
							title: 'Ocorreu um erro ao cancelar a solicitação de adiantamento avulso!',
							body: 'Por favor, verifique os campos em vermelho.'
						});
					}
				})
				.finally((): void => {
					this.busy = false;
				});
		})
		.catch((error: any): void => {
			if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
				this.NewToaster.pop({
					type: 'error',
					title: error
				});
			}
		});
	}

}
