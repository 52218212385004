
import angular from 'angular';
import { MeurhSolicitacoesalteracoesdadossalariaisService } from '../solicitacoesalteracoesdadossalariais.service';
import { IModalConfirmacao } from '../../Solicitacoesrescisoes/models/modalconfirm';
import { MeurhAlteracoesDadosSalariaisModalConfirmService } from '../modal/modalalteracoesdadossalariais.confirm.service';

export class MeurhSolicitacoesalteracoesdadossalariaisNewController implements angular.IController {
	static $inject = [
        '$scope',
        '$stateParams',
        '$state',
        'MeurhSolicitacoesalteracoesdadossalariaisService',
        'entity',
        'NewToaster',
        'ModalConfirmService',
        '$q',
        '$transitions',
        'MeurhAlteracoesDadosSalariaisModalConfirmService',
    ];

    public constructors: any = {};
    public action = 'insert';
    public tipo = this.$stateParams['tipo'];
	public form: angular.IFormController;
    public mudouInfosColaborador: boolean = false;
    private objRequisicao: any = {};

    constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesalteracoesdadossalariaisService,
        public entity: any,
        public NewToaster: any,
        public ModalConfirmService: any,
        public $q: any,
        public $transitions: any,
        public MeurhAlteracoesDadosSalariaisModalConfirmService: MeurhAlteracoesDadosSalariaisModalConfirmService,
    ) {
        this.onSubmitSuccess();
        this.onSubmitError();
        this.onChangeInfosColaborador();
    }

    submit(): void {

        angular.forEach(this.form.$$controls, (field: any) => {
            field.$validate();
        });

        if (this.form.$valid && !this.entity.$$__submitting) {

            const customMessage: IModalConfirmacao = this.getMensagensModal('envio');
            const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhAlteracoesDadosSalariaisModalConfirmService.open(this.entity, customMessage);

            confirm.result.then((): void => {
                this.montaObjetoRequisicao();
                this.entityService.save(this.objRequisicao, {
                    empresa: this.entity.trabalhador.empresaobj.empresa,
                    estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
                });
            })
            .catch(function () {/**/ });

        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos não foram preenchidos corretamente.'
            });
        }
    }

    onSubmitSuccess() {
        this.$scope.$on('meurh_solicitacoesalteracoestiporecebimento_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A alteração de dados salariais foi enviada com sucesso!'
            });
            this.constructors.solicitacao = args.entity.solicitacao;
            this.$state.go('meurh_solicitacoesalteracoestiporecebimento_show', this.constructors);
        });
    }

    onSubmitError() {
        this.$scope.$on('meurh_solicitacoesalteracoestiporecebimento_submit_error', (event: angular.IAngularEvent, args: any): void => {
            if (args.response.status === 409) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    if (args.response.data.message === 'Validation Failed') {
                        let message = '';
                        let children = args.response.data.errors.children;
                        for (let child in children) {
                            if (children[child].errors !== undefined) {
                                for (let error in children[child].errors) {
									if (children[child].errors.hasOwnProperty(error)) {
										message += '<li>' + children[child].errors[error] + '</li>';
									}
                                }
                            }
                        }
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Erro de Validação',
                            body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
                            bodyOutputType: 'trustedHtml'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: args.response.data.message
                        });
                    }
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao enviar a alteração de dados salariais!'
                    });
                }
            }
        });
    }

    montaObjetoRequisicao() {

        this.objRequisicao.situacao = 0; // abertura
        this.objRequisicao.trabalhador = this.entity.trabalhador.trabalhador;
        this.objRequisicao.estabelecimento = this.entity.estabelecimento;
        this.objRequisicao.tiporecebimento = this.entity.tiporecebimento ? parseInt(this.entity.tiporecebimento, 10) : null;

        // verificando valor do banco, agência, número da conta e DV
        if (this.objRequisicao.tiporecebimento === 1 || this.objRequisicao.tiporecebimento === 2 ||
            this.objRequisicao.tiporecebimento === 4 || this.objRequisicao.tiporecebimento === 64) {
            this.objRequisicao.tipocontapix = this.objRequisicao.tiporecebimento === 64 ? parseInt(this.entity.tipocontapix, 10) : null;
            this.objRequisicao.banco = this.entity.banco.banco;
            this.objRequisicao.agencia = this.entity.agencianumero;
            this.objRequisicao.numeroconta = this.entity.numeroconta;
            this.objRequisicao.numerocontadv = this.entity.numerocontadv;
        } else {
            this.objRequisicao.banco = null;
            this.objRequisicao.agencia = null;
            this.objRequisicao.numeroconta = null;
            this.objRequisicao.numerocontadv = null;
            this.objRequisicao.tipocontapix = null;
        }

        // verificando valor da chave pix
        if (this.objRequisicao.tiporecebimento === 128 || this.objRequisicao.tiporecebimento === 256 ||
            this.objRequisicao.tiporecebimento === 512 || this.objRequisicao.tiporecebimento === 1024) {
            this.objRequisicao.chavepix = this.entity.chavepix;
        } else {
            this.objRequisicao.chavepix = null;
        }

    }

    buttonDescartar() {
        let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', false);
        confirm.result.then((): void => {
            this.$state.go('meurh_solicitacoesalteracoestiporecebimento', angular.extend(this.entityService.constructors));
        })
        .catch((error: any): void => {/* */ });
    }

    onChangeInfosColaborador() {
        this.$scope.$on('dados_bancarios_colaborador_change', (event: angular.IAngularEvent, args: any): void => {
            this.mudouInfosColaborador = args;
        });
    }

    private getMensagensModal(tipo: 'envio' | 'descartar'): IModalConfirmacao {
        const customText = {
            'envio': {
                titulo: 'Enviar alteração de dados salariais',
                descricao: (
                    'Ao fazer o envio, os dados do colaborador serão atualizados.'
                ),
                textoConfirmacao: (
                    `Tem certeza que deseja enviar a alteração de dados salariais de ${this.entity.trabalhador?.nome}?`
                ),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Enviar alteração'
                }
            },
            'descartar': {
                titulo: 'Descartar',
                descricao: (
                    'Após descartar a alteração de dados salariais, todas as informações já preenchidas serão perdidas.'
                ),
                textoConfirmacao: (
                    `Você tem certeza que deseja descartar a alteração?`
                ),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Sim, descartar'
                }
            },
        };

        return customText[tipo];
    }

}
