import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { CargosService } from '../../../../Cargos/cargos.service';
import { ConcessionariasService } from '../../../../Persona/Concessionarias/concessionarias.service';
import { MeurhVagasService } from '../../../Vagas/vagas.service';
import { EstabelecimentosService } from '../../../../Estabelecimentos/estabelecimentos.service';
import { TrabalhadoresService } from '../../../../Trabalhadores/trabalhadores.service';
import { MeurhAdmissaoestagiarioService } from '../../admissaoestagiario.service';
import { IGestoresTrabalhador } from '../../../Transferenciacolaboradores/models/transferenciacolaboradores.model';
import { HorarioDetalhadoModalService } from '../../../Admissao/modais/modal-horario-detalhado/horario-detalhado.modal.service';
import { HorariosService } from '../../../../Horarios/horarios.service';

export class MeurhAdmissoesestagiarioDadosgeraisFormController implements ng.IController {

    static $inject = [
        'CargosService',
        'MeurhVagasService',
        'EstabelecimentosService',
        'TrabalhadoresService',
        'HorarioDetalhadoModalService',
        'HorariosService',
        '$scope',
        '$rootScope',
        '$window',
        'ConcessionariasService',
        'MeurhAdmissaoestagiarioService',
        'NewToaster'
    ];

    public busy: boolean;
    public entity: any;
    public action: string;
    public form: angular.IFormController;
    public constructor_: any = {};
    public empresa: any;
    public carregaHorarios: any;
    public desativaportrabalhadortemporario: boolean = false;
    public matriculaAutomatica: boolean = false;
    public listaTrabalhadores: any;
    public vagaSelecionada: boolean = false;
    public nivelSelecionado: boolean = false;
    public mostraobrigatoriosencaminhar: boolean;
    public listagemPermissaoGestores: string[] = ['visualizar_admissao'];

    // inicio - tratamento de datas
    public anoMaximo: moment.Moment;
    public anoMinimo: moment.Moment;
    public mesInit: moment.Moment;
    // fim - tratamento de datas

    private toastTimeout: number = 8000;

    constructor(
        public CargosService: CargosService,
        public MeurhVagasService: MeurhVagasService,
        public EstabelecimentosService: EstabelecimentosService,
        public TrabalhadoresService: TrabalhadoresService,
        public HorarioDetalhadoModalService: HorarioDetalhadoModalService,
        public HorariosService: HorariosService,
        private $scope: angular.IScope,
        private $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        private $window: ng.IWindowService,
        private ConcessionariasService: ConcessionariasService,
        private MeurhAdmissaoestagiarioService: MeurhAdmissaoestagiarioService,
        private NewToaster: any,
    ) {
        this.TrabalhadoresService.constructors['tipo'] = '1'; // colaborador contribuinte
        this.listaTrabalhadores = this.TrabalhadoresService.reload();
        this.$scope.$on('meurh_vagas_list_finished', (event: any, args: any) => {
            if (args.length > 0 && this.action === 'insert') {
                this.entity.vagaAtiva = true;
            } else {
                this.entity.vagaAtiva = false;
            }
            this.busy = false;
            this.$rootScope.$broadcast('vaga_ativa_load_finished');
        });
    }

    async $onInit(): Promise<void> {
        this.constructor_ = this.verificacaoCargo();

        // if (this.entity.situacao !== -1) {
        this.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.carregaHorarios = true;
        // }

        if (this.$rootScope.configuracoes.CODIGO_FUNCIONARIO_AUTOMATICO === true) {
            this.matriculaAutomatica = true;
            this.entity.matricula = '';
        }

        this.preencheCampoEstabelecimento();
        this.calculaMes();
        this.bloqueiaQuatorzeanos();
        this.bloqueiaAnoMinimo();
        this.verificaGestores();
        this.verificaVagaAtiva();

        // se estiver visualizando/editando admissão, precisa fazer get de horário para obter o resumo da jornada semanal
        if (this.action !== 'insert' && this.entity?.horario) {

            await this.HorariosService.get(this.entity.horario.horario, true).then((horarioBuscado) => {
                this.entity.horario = horarioBuscado;
            })
            .catch((error) => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não foi possível carregar o horário'
                });
            });

        }

    }

    public validaTelefone(): boolean {
        return !(!!this.entity.telefonecorporativo) || this.entity.telefonecorporativo.length >= 8;
    }

    calculaDatas(): void {
        this.entity.inicioperiodoaquisitivoferias = moment(this.entity.datainiciocontrato);
    }

    verificacaoCargo(): { cargo: string | null } {
        if (this.entity.cargo) {
            if (this.entity.cargo.cargo) {
                return { 'cargo': this.entity.cargo.cargo };
            }
        }
        return { 'cargo': null };
    }

    verificaEstabelecimento(): { estabelecimento: string } {
        if (this.entity.estabelecimento) {
            return { 'estabelecimento': this.entity.estabelecimento };
        } else {
            return { 'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento };
        }
    }

    verificaVagaAtiva(): void {
        if (!this.entity.vaga) {
            this.busy = true;
            this.MeurhVagasService.reload();
        } else {
            this.entity.vagaAtiva = true;
            this.vagaSelecionada = true;
            this.$rootScope.$broadcast('vaga_ativa_load_finished');
        }
    }

    verificaGestores(): void {
        if (!this.entity.gestores) {
            this.entity.gestores = [];
        }

        if (this.action === 'update' || this.action === 'show') {
            this.entity.gestores.map((gestor: IGestoresTrabalhador) => {
                if (gestor.identificacaonasajongestor) {
                    gestor.nome = '';
                    gestor.tipoGestor = 'Externo';
                    gestor.email = gestor.identificacaonasajongestor;
                } else {
                    gestor.nome = gestor.trabalhador!['nome'];
                    gestor.tipoGestor = 'Interno';
                    gestor.email = gestor.trabalhador!['identificacaonasajon'];
                    gestor.gestor = gestor.trabalhador!['trabalhador'];
                }
            });
        }
    }

    alteraNivelPorCargo(): void {
        if (this.entity.cargo) {
            this.$rootScope.$broadcast('nivelcargo_refresh', { cargo: this.entity.cargo.cargo });
            this.entity.nivelcargo = null;
        }
    }

    alteraVaga(): void {
        if (this.entity.vaga) {
            this.busy = true;
            this.MeurhVagasService.get(this.entity.vaga.vaga)
                .then((response: any): void => {
                    this.vagaSelecionada = true;
                    this.preencheCamposVaga(response);
                })
                .catch((e: any): void => {
                    this.busy = false;
                    console.error(e);
                });
        } else if (this.vagaSelecionada) {
            this.limpaCamposVaga();
        }
    }

    preencheCamposVaga(vaga: any): void {
        this.limpaCamposVaga();
        this.entity.vaga = vaga;
        this.entity.departamento = vaga.departamento;
        this.entity.lotacao = vaga.lotacao;
        this.entity.nivelcargo = vaga.nivelcargo;
        this.entity.unidadebolsa = vaga.unidadesalariofixo.toString();
        this.entity.valorbolsa = vaga.salariofixo;
        this.entity.grupodeponto = vaga.grupodeponto;
        this.entity.beneficios = vaga.beneficios.length > 0 ? vaga.beneficios : [];
        this.entity.vts = vaga.vts.length > 0 ? vaga.vts : [];
        this.entity.ambiente = vaga.ambiente;
        this.entity.condicaoambientetrabalho = vaga.condicaoambientetrabalho;

        if (vaga.gestores && vaga.gestores.length > 0) {
            this.entity.gestores = vaga.gestores.map((gestor: any) => {
                if (gestor.gestor.trabalhador) {
                    return gestor.gestor;
                } else {
                    return {
                        nome: gestor.identificacaonasajongestor
                    };
                }
            });
        }
        // em vagas, o nome dos atributos referente a data dos períodos são diferentes
        this.entity.beneficios.forEach((beneficio: any) => {
            if (beneficio.tipoperiodo === 1) { // todo mês (x)
                beneficio.mes = beneficio.tipoperiodomes;
            } else if (beneficio.tipoperiodo === 2) { // entre mês/ano
                beneficio.mesinicial = beneficio.tipoperiodomesinicio;
                beneficio.mesfinal = beneficio.tipoperiodomesfinal;
                beneficio.anoinicial = beneficio.tipoperiodoanoinicio;
                beneficio.anofinal = beneficio.tipoperiodoanofinal;
            }
            beneficio.usobeneficio = 1;
            beneficio.nomepessoa = this.entity.nome;
        });

        // em vagas, não há o campo exclusivo de concessionária (ela fica dentro da tarifa)
        this.entity.vts.forEach((vt: any) => {
            this.ConcessionariasService.get(vt.tarifaconcessionariavt.concessionariavt)
                .then((response: any) => {
                    vt.concessionariavt = response;
                });
        });
        // em vagas, não há o campo exclusivo de cargo (ele fica dentro do nível cargo)
        if (vaga.nivelcargo?.cargo) {
            this.CargosService.get(vaga.nivelcargo.cargo)
                .then((response: any) => {
                    this.entity.cargo = response;
                }).catch((e: any): void => {
                    console.error(e);
                }).finally(() => {
                    this.busy = false;
                });
        } else {
            this.busy = false;
        }

        if (vaga.horario?.horario) {

            this.HorariosService.get(vaga.horario.horario, true).then((horarioBuscado) => {
                this.entity.horario = horarioBuscado;
            })
            .catch((error) => {
                console.log(error);
            });

        }

        // em vagas, o campo estabelecimento é um identificador
        /*
        this.EstabelecimentosService.get(vaga.estabelecimento)
            .then((response: any) => {
                this.entity.estabelecimento = response;
            })
            .catch((e: any): void => {
                this.busy = false;
                console.error(e);
            });
        */
    }

    limpaCamposVaga(): void {
        delete this.entity.vaga;
        delete this.entity.departamento;
        delete this.entity.lotacao;
        delete this.entity.cargo;
        delete this.entity.nivelcargo;
        delete this.entity.grupodeponto;
        delete this.entity.valorbolsa;
        delete this.entity.ambiente;
        delete this.entity.condicaoambientetrabalho;
        delete this.entity.horario;
        this.entity.gestores = [];
        this.entity.beneficios = [];
        this.entity.vts = [];
    }

    preencheCampoEstabelecimento(): void {
        if (!this.entity.estabelecimento) {
            this.entity.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        }
        this.busy = true;
        this.EstabelecimentosService.get(this.entity.estabelecimento)
            .then((response: any) => {
                this.entity.estabelecimento = response;
            }).catch((e: any): void => {
                console.error(e);
            }).finally(() => {
                this.busy = false;
            });
    }

    changeCargo(): void {
        this.alteraNivelPorCargo();
        this.constructor_ = this.verificacaoCargo();
    }

    public alteraLotacao(): void {
        if (this.entity.lotacao) {
            this.$rootScope.$broadcast('ambiente_refresh', { lotacao: this.entity.lotacao.lotacao });
            this.entity.ambiente = null;
            this.entity.condicaoAmbiente = null;
        }
    }

    public alteraAmbiente(): void {
        if (this.entity.ambiente) {
            this.$rootScope.$broadcast('condicoesambientestrabalho_refresh', { ambiente: this.entity.ambiente.ambiente });
            this.entity.condicaoambientetrabalho = null;
        }
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    public preenchercamposNivel(): void {
        if (this.entity.nivelcargo) {
            this.nivelSelecionado = true;
            this.entity.valorbolsa = this.entity.nivelcargo.valorsalario;
        } else if (this.nivelSelecionado) {
            delete this.entity.valorbolsa;
        }
    }

    public getNumerodiasperiodo(event: angular.IAngularEvent & { detail: any }): void {
        if (event.detail.value < 0 || event.detail.value > 31 || !event.detail.value) {
            event.detail.error = true;
        }

        this.entity.numerodiasperiodo = event.detail.value;
        this.entity.erroNumerodiasperiodo = event.detail.error;
    }

    public getNumerohorasmensais(event: angular.IAngularEvent & { detail: any }): void {
        if (event.detail.value < 0 || !event.detail.value) {
            event.detail.error = true;
        }

        this.entity.numerohorasmensais = event.detail.value;
        this.entity.erroNumerohorasmensais = event.detail.error;
    }

    public getQuantidademediahorassemanais(event: angular.IAngularEvent & { detail: any }): void {
        if (event.detail.value < 0 || !event.detail.value) {
            event.detail.error = true;
        }

        this.entity.quantidademediahorassemanais = event.detail.value;
        this.entity.erroQuantidademediahorassemanais = event.detail.error;
    }

    public scrollToField(fieldId: string) {
        var element = this.$window.document.getElementById(fieldId);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    public verificaLotacao(): { lotacao?: string } {
        if (!this.entity.lotacao) {
            return {};
        }
        return { 'lotacao': this.entity.lotacao!.lotacao };
    }

    public verificaAmbiente(): { ambiente?: string } {
        if (!this.entity.ambiente) {
            return {};
        }
        return { 'ambiente': this.entity.ambiente!.ambiente };
    }

    // início - funções para CPF
    public funcoesCPF(): void {
        this.preenchePix();
    }

    public preenchePix(): void {
        if (this.entity.tiporecebimentobolsa === '128') { // cpf
            this.entity.chavepix = this.entity.cpf;
        }
    }
    // fim - funções para CPF

    public abrirModalHorarioDetalhado() {

        let modal = this.HorarioDetalhadoModalService.open(this.entity.horario, {
            empresa: this.empresa,
            estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
            action: this.action
        });

        modal.result.then((horarioSelecionadoModal: any): void => {
            this.entity.horario = horarioSelecionadoModal;
        })
        .catch((error: any): void => {/**/});
    }

    // inicio - tratamento de datas
    private calculaMes(): void {
        this.mesInit = moment().subtract(216, 'month'); // 18 anos
    }

    private bloqueiaQuatorzeanos(): void {
        this.anoMaximo = moment(this.mesInit).add(4, 'years'); // = 14 anos
    }

    private bloqueiaAnoMinimo(): void {
        this.anoMinimo = moment().subtract(120, 'years'); // > 120 anos
    }
    // fim - tratamento de datas
}
