import angular from 'angular';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';
import { TrabalhadoresService } from '../../../Trabalhadores/trabalhadores.service';
import { TipoContaPixEnum } from '../../../../shared/enums/TipoContaPixEnum';

export class MeurhSolicitacoesalteracoesdadossalariaisFormController implements angular.IController {
	static $inject = [
    '$scope',
    '$element',
    '$attrs',
    'NewToaster',
    '$stateParams',
    '$rootScope',
    'ListagemService',
    'TrabalhadoresService',
  ];

  public tipo: string = this.$stateParams.tipo;
  public action: string;
  public busy: boolean;
	public form: angular.IFormController;
  public entity: any;
  public filter: any;

  public filters = {
    empresa: this.$rootScope.session.entities.empresa.empresa,
    estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
  };
  public empresas: Array<Empresa>;
  public estabelecimentos: Array<Estabelecimento>;

  public listagemPermissao: Array<string> = ['criar_alteracaotiporecebimento'];
  public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

  public constructorLookupColaborador;

  public tipoRecebimentoOriginal;
  public chavePixOriginal;
  public bancoOriginal;
  public agenciaNumeroOriginal;
  public numeroContaOriginal;
  public numeroContaDvOriginal;

  public tipoContaPixEnum = TipoContaPixEnum;

  constructor (
    public $scope: angular.IScope,
		public $element: any,
		public $attrs: any,
		public NewToaster: any,
		public $stateParams: angular.ui.IStateParamsService,
		public $rootScope: angular.IRootScopeService & {
      temPermissao: (arg: string) => boolean,
      modoGestorPermissao: (arg: string) => boolean,
      temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
      liberadoTela: (arg: string) => boolean,
      session: any,
      nsjGlobals: any,
      configuracoes: any,
    },
    public ListagemService: ListagemService,
    public TrabalhadoresService: TrabalhadoresService,
  ) {
    $scope.$watch('$ctrl.entity', (newValue: any, oldValue: any) => {
      if (newValue !== oldValue) {
        this.form.$setDirty();
      }
    }, true);

  }

  $onInit(): void {

    this.entity.estabelecimento = this.action !== 'show' ? this.filters.estabelecimento : this.entity.estabelecimentoobj.estabelecimento;
    this.montaListagemVisibilidade();

    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.forcaGestor(),
      ...(this.tipo && { 'tipo': this.tipo }),
    };
  }

  montaListagemVisibilidade(): void {
    if (this.action === 'show') {
      this.filters.empresa = this.entity.estabelecimentoobj.empresa;
      this.filters.estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
    }
    this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
    this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
  }

  mudaEstabelecimentos(): void {
    this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
    this.filters.estabelecimento = '';
    this.filtraEmpresaEstabelecimento();
  }

  filtraEmpresaEstabelecimento(): void {
    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.forcaGestor(),
      ...(this.tipo && { 'tipo': this.tipo }),
    };
  }

  forcaGestor(): boolean {
    return this.$rootScope.modoGestorPermissao('criar_alteracaotiporecebimento');
  }

  public async getInfosColaborador(idColaborador: string) {

    if (idColaborador) {
      this.entity.estabelecimento = this.entity.trabalhador.estabelecimentoobj.estabelecimento;
      await this.TrabalhadoresService.get(idColaborador).then((dadosColaborador) => {
        this.entity.tiporecebimento = dadosColaborador.tiporecebimentosalario ? dadosColaborador.tiporecebimentosalario.toString() : null;

        if (this.entity.tiporecebimento === '128' ||
          this.entity.tiporecebimento === '256' ||
          this.entity.tiporecebimento === '512') {
            this.preencheChavePixColaborador(this.entity.tiporecebimento, dadosColaborador);
        } else {
          this.entity.chavepix = null;
        }

        this.entity.banco = dadosColaborador.banco ? dadosColaborador.banco : null;
        this.entity.agencianumero = dadosColaborador.agencia ? dadosColaborador.agencia.numero : null;
        this.entity.numeroconta = dadosColaborador.numerocontasalario;
        this.entity.numerocontadv = dadosColaborador.numerocontasalariodv;

        // guardando informações bancárias do colaborador carregadas inicialmente. Serão usadas para comparar se houve mudança nesses dados
        this.tipoRecebimentoOriginal = this.entity.tiporecebimento;
        this.chavePixOriginal = this.entity.chavepix;
        this.bancoOriginal = this.entity.banco;
        this.agenciaNumeroOriginal = this.entity.agencianumero;
        this.numeroContaOriginal = this.entity.numeroconta;
        this.numeroContaDvOriginal = this.entity.numerocontadv;

        this.verificaMudancaDadosBancarios();

      });
    } else {
      this.entity.estabelecimento = null;
      this.limpaDadosBancarios();
    }

  }

  public preencheCamposRecebimento(): void {

    switch (this.entity.tiporecebimento) {
      case '128': // cpf
        this.entity.chavepix = this.entity.trabalhador.cpf;
        break;

      default:
        this.entity.chavepix = null;
        break;
    }
  }

  public preencheChavePixColaborador(tiporecebimento: string, dadosColaborador: any) {

    switch (tiporecebimento) {
      case '128': // cpf
        this.entity.chavepix = dadosColaborador.cpf;
        break;

      case '256': // email
        this.entity.chavepix = dadosColaborador.email;
        break;

      case '512': // celular
        this.entity.chavepix = dadosColaborador.dddcel + dadosColaborador.celular;
        break;
    }

  }

  limpaDadosBancarios() {
    this.entity.tiporecebimento = null;
    this.entity.chavepix = null;
    this.entity.banco = null;
    this.entity.agencianumero = null;
    this.entity.numeroconta = null;
    this.entity.numerocontadv = null;
  }

  /**
   * Método que verifica se algum dos dados bancários do colaborador foi alterado
   */
  verificaMudancaDadosBancarios() {
    if (this.tipoRecebimentoOriginal !== this.entity.tiporecebimento ||
      this.chavePixOriginal !== this.entity.chavepix ||
      this.bancoOriginal?.banco !== this.entity.banco?.banco ||
      this.agenciaNumeroOriginal !== this.entity.agencianumero ||
      this.numeroContaOriginal !== this.entity.numeroconta ||
      this.numeroContaDvOriginal !== this.entity.numerocontadv
    ) {
      this.mudouInfoColaborador(true);
    } else {
      this.mudouInfoColaborador(false);
    }
  }

  /**
   * Dispara broadcast informando se alguma informação do colaborador foi alterada
   */
  mudouInfoColaborador(mudouInfo: boolean) {
    this.$rootScope.$broadcast('dados_bancarios_colaborador_change', mudouInfo);
  }

  public desabilitarNenhumTipoPagamento(): boolean {
    return !!this.$rootScope.configuracoes.DESABILITAR_NENHUM_TIPO_PAGAMENTO_ADMISSOES;
  }

  public alertaTipoPagamento(): boolean {
    return !(!!this.entity.tiporecebimento) && (this.form && (this.form.tiporecebimento.$dirty || this.form.$submitted));
  }

  public alertaTipoContaPix(): boolean {
    return this.entity.tiporecebimento === '64' &&  !(!!this.entity.tipocontapix) && (this.form && (this.form.tipocontapix.$dirty || this.form.$submitted));
  }

  public desabilitarNenhumaInstituicaoBancaria(): boolean {
    return !!this.$rootScope.configuracoes.DESABILITAR_NENHUMA_INSTITUICAO_BANCARIA_ADMISSOES;
  }

}
