declare var nsjGlobals: any;
import angular = require('angular');
import moment = require('moment');
import { MeurhCreditosdescontosService } from '../creditosdescontos.service';
import { IAno } from '../models/ano.model';
import { ICreditoDesconto } from '../models/creditoDesconto.model';
import { IDadosInit } from '../models/dadosInit.model';
import { IMes } from '../models/mes.model';
import { ITitles } from '../models/titles.model';

export class MeurhCreditosdescontosFormController {
    static $inject = ['$rootScope', '$scope', '$compile', 'MeurhCreditosdescontosService'];

    public busy: boolean = false;

    public entity: Partial<ICreditoDesconto> = {};
    public isEdit: boolean;
    public titles: ITitles;
    public datas: IDadosInit = {
      dataFolhaAdiantamentoSalarial: '',
      dataFolhaPagamento: ''
    };

    public tempEntitySolicitacao: Partial<ICreditoDesconto> = {};
    public entitySolicitacaoEnviar: Partial<ICreditoDesconto> = {};
    public zerarConteudo: boolean = false;
    public periodos: Array<string> = [];
    public anosSelect: Array<IAno> = [];
    public mesesSelect: Array<IMes> = [];
    public dataAtual = new Date();
    public anoAtual: number = undefined;
    public errosPossiveisMensagens = {
      erroDatacompetenciafinalperiodo: '',
    };
    public errosPossiveis: any = {
      erroDatacompetenciafinalperiodo: false,
    };
    public clienteAna: boolean = nsjGlobals.get('clienteana');
    public disableFolha: boolean = false;
    public competenciaAtual = moment(this.$rootScope.nsjGlobals.a.configuracoes.DATA_FECHAMENTO_FOLHA, 'YYYY-MM-DD').format('MM/YYYY');

    constructor(
        public $rootScope: angular.IRootScopeService & { nsjGlobals: any },
        public $scope: angular.IScope,
        public $compile: angular.ICompileService,
        public service: MeurhCreditosdescontosService
    ) {

      $scope.$on('meurh_solicitacoescreditodesconto_getloaded', () => {
        $scope.$watch('$ctrl.entity', () => {
          let eventounidade = this.entity.evento.unidade === undefined ? this.entity.evento.evento_unidade : this.entity.evento.unidade;
          if (this.entity.conteudo && (eventounidade === 0 || eventounidade === 8 || eventounidade === 10)) {
            this.entity.conteudo = this.service.transformaConteudoEmHora(this.entity.conteudo);
          }

          // visualização
          if (!this.isEdit) {
            let competenciaDaSolicitacao = this.entity.mesreferencia + '/' + this.entity.anoreferencia;

            this.datas.dataFolhaAdiantamentoSalarial = this.datas.dataFolhaPagamento = moment(competenciaDaSolicitacao, 'MM/YYYY').isValid() ?
              moment(competenciaDaSolicitacao, 'MM/YYYY').format('MM/YYYY') : 'Data inválida';
          } else {
            this.datas.dataFolhaAdiantamentoSalarial = this.datas.dataFolhaPagamento = moment(this.competenciaAtual, 'MM/YYYY').format('MM/YYYY');
          }

          if (this.entity.tipocalculo) {
            this.montaPeriodos();
          }
          this.selecionaRubrica();
          this.setCompetenciapagamento();
        });

      });
    }

    $onInit() {
      this.anoAtual = this.dataAtual.getFullYear();
      this.montaSelectsData();

      if (!this.$rootScope.nsjGlobals.a.configuracoes.DATA_PAGAMENTO_ADIANTAMENTO) {
        this.disableFolha = true;
      }

    }

    isBusy() {
      return this.busy;
    }

    montaSelectsData() {
      this.anosSelect = [
        {
          'ano': this.anoAtual
        },
        {
          'ano': (this.anoAtual + 1)
        },
        {
          'ano': (this.anoAtual + 2)
        },
      ];

      this.mesesSelect = [
        {
          'value': 1,
          'mes': 'Janeiro'
        },
        {
          'value': 2,
          'mes': 'Fevereiro'
        },
        {
          'value': 3,
          'mes': 'Março'
        },
        {
          'value': 4,
          'mes': 'Abril'
        },
        {
          'value': 5,
          'mes': 'Maio'
        },
        {
          'value': 6,
          'mes': 'Junho'
        },
        {
          'value': 7,
          'mes': 'Julho'
        },
        {
          'value': 8,
          'mes': 'Agosto'
        },
        {
          'value': 9,
          'mes': 'Setembro'
        },
        {
          'value': 10,
          'mes': 'Outubro'
        },
        {
          'value': 11,
          'mes': 'Novembro'
        },
        {
          'value': 12,
          'mes': 'Dezembro'
        },
      ];
    }

    alteraEntitySolicitacao() {
      switch (this.entity.situacao) {
        case -1:
            this.entity.situacaoDesc = 'Rascunho';
            break;
        case 0:
            this.entity.situacaoDesc = 'Enviada';
            break;
        case 1:
            this.entity.situacaoDesc = 'Aprovada';
            break;
        case 3:
            this.entity.situacaoDesc = 'Excluída';
            break;
      }
        this.entity.enviadoEm = this.entity.situacao === -1 ? 'Ainda não enviado' : moment(this.entity.lastupdate).format('DD/MM/YYYY');

        if (this.entity.tipoperiodo === 2) {
            this.entity.mes = this.entity.mesfinalperiodo.toString();
            this.entity.ano = this.entity.anofinalperiodo.toString();
        }

        this.entity.tipocalculo = this.entity.tipocalculo.toString();
        this.entity.tipoperiodo = this.entity.tipoperiodo.toString();

    }

    montaPeriodos() {
        let periodo = 'Somente uma vez em ' + (this.entity.tipocalculo === '0' ? this.datas.dataFolhaAdiantamentoSalarial : this.datas.dataFolhaPagamento);
        this.periodos.push(periodo);

        periodo = 'De ' + (this.entity.tipocalculo === '0' ? this.datas.dataFolhaAdiantamentoSalarial : this.datas.dataFolhaPagamento) + ' em diante';
        this.periodos.push(periodo);

        periodo = 'De ' + (this.entity.tipocalculo === '0' ? this.datas.dataFolhaAdiantamentoSalarial : this.datas.dataFolhaPagamento) + ' a...';
        this.periodos.push(periodo);
    }

    selecionaRubrica() {
        // para criar o input conteúdo correspondente a rubrica selecionada
        let inputHtml = '';
        let inputConteudo = angular.element(document.getElementById('inputConteudo'));
        if (inputConteudo) {
          inputConteudo.remove();
        }
        if (this.entity.evento) {
          let eventounidade = this.entity.evento.unidade === undefined ? this.entity.evento.evento_unidade : this.entity.evento.unidade;

          switch (eventounidade) {
            case 0:
            case 8:
            case 10:
              // hora
              inputHtml = '<input id="inputConteudo" type="text" class="form-control" id="conteudo" name="conteudo" ng-model="$ctrl.entity.conteudo" ng-disabled="!$ctrl.entity.evento || $ctrl.isEdit===false" ui-time-mask="short" placeholder="00:00" />';
              break;
            case 2:
            case 12:
              // dinheiro
              inputHtml = '<input id="inputConteudo" type="text" class="form-control" id="conteudo" name="conteudo" ng-model="$ctrl.entity.conteudo"  ng-disabled="!$ctrl.entity.evento || $ctrl.isEdit===false" ui-money-mask/>';
              break;
            default:
              // variado
              this.entity.conteudo = Number(this.entity.conteudo);
              inputHtml = '<input id="inputConteudo" type="number" class="form-control" id="conteudo" name="conteudo" ng-model="$ctrl.entity.conteudo" ng-disabled="!$ctrl.entity.evento || $ctrl.isEdit===false" min="0" />';
          }
        } else {
          inputHtml = '<input id="inputConteudo" type="text" class="form-control" ng-disabled="true"/>';
        }

        if (this.zerarConteudo === true) {
          this.entity.conteudo = undefined;
        }

        if (this.entity.evento) {
          let tipovalor = this.entity.evento.tipovalor === undefined ? this.entity.evento.evento_tipovalor : this.entity.evento.tipovalor;
          this.titles.accordion = tipovalor === 0 ? 'Dados do crédito' : 'Dados do desconto';
          this.titles.header = tipovalor === 0 ? 'Crédito' : 'Desconto';
        }

        let compiledHtml = this.$compile(inputHtml)(this.$scope);
        let inputBox = angular.element(document.getElementById('inputBox'));
        inputBox.append(compiledHtml);

        if (this.zerarConteudo === false && this.isEdit === true) {
            this.zerarConteudo = true;
        }
    }

    /* INÍCIO - funcoes form */
    setCompetenciapagamento() {

      if (this.entity.tipoperiodo !== '2') {
        this.errosPossiveis['erroDatacompetenciafinalperiodo'] = false;
        this.entity.mes = null;
        this.entity.ano = null;
        return;
      }

      if ((this.entity.mes !== '' && this.entity.mes !== undefined) && (this.entity.ano !== '' && this.entity.ano !== undefined)) {
        this.entity.competenciafinalperiodo = this.entity.mes + '/' + this.entity.ano;
        this.verificaCompetenciafinalperiodoValida();
      } else {
        this.verificaCompetenciafinalperiodoValida();
      }
    }

    verificaCompetenciafinalperiodoValida() {

      if (!this.entity.ano || !this.entity.mes) {
        this.errosPossiveis['erroDatacompetenciafinalperiodo'] = true;
        this.errosPossiveisMensagens['erroDatacompetenciafinalperiodo'] = 'É necessário preencher o mês e o ano da competência final período.';
        return;
      }

      let competenciapagamentoCompleta = this.entity.tipocalculo === 0 ? this.datas.dataFolhaAdiantamentoSalarial : this.datas.dataFolhaPagamento;
      let mesCompetenciapagamento = moment(competenciapagamentoCompleta, 'MM/YYYY').format('MM');
      let anoCompetenciapagamento = moment(competenciapagamentoCompleta, 'MM/YYYY').format('YYYY');

      let diferenca = moment([this.entity.ano, (Number(this.entity.mes) - 1)]).diff(moment([anoCompetenciapagamento, (Number(mesCompetenciapagamento) - 1)]), 'months', true);

      let competenciafinalperiodoCompleta = moment(competenciapagamentoCompleta, 'MM/YYYY').add(diferenca, 'months').format('MM/YYYY');
      if (!(moment(competenciafinalperiodoCompleta, 'MM/YYYY').isAfter(moment(competenciapagamentoCompleta, 'MM/YYYY')))) {
        this.errosPossiveis['erroDatacompetenciafinalperiodo'] = true;
        this.errosPossiveisMensagens['erroDatacompetenciafinalperiodo'] = 'A data competência final período deve ser posterior a data competência da folha selecionada: ' + this.competenciaAtual;
      } else {
        this.errosPossiveis['erroDatacompetenciafinalperiodo'] = false;
      }

      this.verificaArrayErros();
    }

    verificaArrayErros() {
      let existeErro = false;
      for (var i in this.errosPossiveis) {
        if (this.errosPossiveis[i] === true) {
          existeErro = true;
          break;
        }
      }

      return existeErro;
    }
    /* FIM - funcoes form */

}
