import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { StatusProcessoEnum } from '../../enums/statusprocesso';
import { TipoContaPixEnum } from '../../../../../shared/enums/TipoContaPixEnum';

export class MeurhAdmissoesOutrasinformacoesFormController implements ng.IController {

    static $inject = ['$rootScope'];

    public entity: any;
    public action: string;
    public form: angular.IFormController;

    public agenciaNumero: any = 'numero';

    public statusProcessoEnum = StatusProcessoEnum;

    public anoMinimo: moment.Moment;
    public anoMaximo: moment.Moment;

    public tipoContaPixEnum = TipoContaPixEnum;

    constructor(
        private $rootScope: angular.IRootScopeService & { session: any, configuracoes: any },
    ) { }

    $onInit(): void {
        this.bloqueiaAnoMinimo();
        this.bloqueiaAnoMaximo();

        if (this.action === 'show') {
            this.agenciaNumero = 'agencianumero';
        } else {
            this.agenciaNumero = 'numero';
            if (this.action === 'update' && this.entity.agencia) {
                this.entity.agencia.numero = this.entity.agencia.agencianumero;
            }
        }
    }

    // início - controle do formulário
    public aplicarSemDadosBancarios(): void {
        delete this.entity.banco;
        delete this.entity.agencia;
        delete this.entity.agencianumero;
        delete this.entity.numerocontasalario;
        delete this.entity.numerocontasalariodv;
    }

    public getSaldoferias(event: angular.IAngularEvent & { detail: any }): void {
        this.entity.saldoferias = event.detail.value;
        this.entity.erroSaldoferias = event.detail.error;
    }

    public preencheCamposRecebimento(): void {
        if (this.entity.tiporecebimentosalario !== '') {
            this.entity.semdadosbancarios = false;
        }

        if (this.entity.tiporecebimentosalario !== '64') {
            this.entity.tipocontapix = null;
        }

        switch (this.entity.tiporecebimentosalario) {
            // cpf
            case '128':
                this.entity.chavepix = this.entity.cpf;
                break;

            // e-mail
            case '256':
                this.entity.chavepix = this.entity.email;
                break;

            // celular
            case '512':
                if (this.entity.dddcel !== undefined || this.entity.celular !== undefined) {
                    this.entity.chavepix = this.entity.dddcel + this.entity.celular;
                }
                break;

            default:
                this.entity.chavepix = '';
                break;
        }
    }

    public desabilitarNenhumTipoPagamento(): boolean {
        return !!this.$rootScope.configuracoes.DESABILITAR_NENHUM_TIPO_PAGAMENTO_ADMISSOES;
    }

    public desabilitarNenhumaInstituicaoBancaria(): boolean {
        return !!this.$rootScope.configuracoes.DESABILITAR_NENHUMA_INSTITUICAO_BANCARIA_ADMISSOES;
    }
    // fim - controle do formulário


    // início - alertas e verificações
    public alertaTipoPagamento(): boolean {
        return !(!!this.entity.tiporecebimentosalario) && (this.form && (this.form.tiporecebimentosalario.$dirty || this.form.$submitted));
    }

    public verificaEstabelecimento(): { estabelecimento: string } {
        if (this.entity.estabelecimento) {
            return { 'estabelecimento': this.entity.estabelecimento };
        } else {
            return { 'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento };
        }
    }
    // fim - alertas e verficações


    // inicio - tratamento de dados
    private bloqueiaAnoMinimo(): void {
        this.anoMinimo = moment().subtract(120, 'years'); // > 120 anos
    }

    private bloqueiaAnoMaximo(): void {
        this.anoMaximo = moment().add(100, 'years'); // > 120 anos
    }
    // fim - tratamento de dados
}
