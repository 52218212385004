declare var nsjGlobals: any;
import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
// import { IAdmissaoCompleta } from '../models/admissaocompleta.model';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { SituacaoAdmissaoEnum } from '../enums/situacao';
import { StatusProcessoEnum } from '../enums/statusprocesso';
import { MeurhAdmissaocompletaService } from '../admissaocompleta.service';
import { MeurhSolicitacoesadmissoesService } from '../../Solicitacoesadmissoes/solicitacoesadmissoes.service';
import { MeurhSolicitacoes } from '../../Solicitacoes/services/solicitacoes.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { MeurhAcompanhamentoService } from '../../Acompanhamento/acompanhamento.service';
import { IGestoresTrabalhador } from '../../Transferenciacolaboradores/models/transferenciacolaboradores.model';

export class MeurhAdmissaocompletaEditController implements ng.IController {
    static $inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        '$state',
        'MeurhAdmissaocompletaService',
        'MeurhSolicitacoesadmissoes',
        'MeurhSolicitacoes',
        'entity',
        'NewToaster',
        'TiposDocumentosRequeridosService',
        '$q',
        'ModalConfirmService',
        'MeurhAcompanhamentoService',
        'ConfiguracoesService'
    ];

    public constructors: any = {};
    public action: string = 'update';
    public tipo = this.$stateParams['tipo'];
    public form: angular.IFormController;
    public busy: boolean = false;
    public entitySave: any = {};
    public matriculaAutomatica: boolean = false;
    public desativaportrabalhadortemporario: boolean = false;
    public enviaEncaminhar: boolean = false;
    public cardAtencao: boolean = false;
    public toastTimeout: number = 8000;
    public dataAtual = new Date();
    public configuracoes: any;
    public envioCompleto: boolean = false;
    public mostraobrigatoriosencaminhar: boolean = false;
    public dataEfetuada: string;
    public foiEditado: boolean;
    public dataLimiteRetificacao: string;

    public toastController = {
        podeEncaminhar: false,
        podeEnviar: false,
        podeEnviarPreliminar: false
    };

    public busyController = {
        foto: false,
        admissaoPreliminar: false,
        dadosGerais: false
    };

    // documentos / anexos
    public mostrarAlerta: boolean = false;
    public tiposdocumentosrequeridos: Array<any>;
    public busyTipoDocumentos: boolean = true;
    public busyAnexo: boolean = false;
    public editanexo: boolean = true;
    public adicionarAnexo: boolean = false;
    public alterouanexo: boolean = false;
    public documentosValidados: boolean = false;
    public entityAnexo: IAnexo = {};

    public clienteAna: boolean;
    public configDesativaAdmPreliminar: boolean;

    private situacaoAdmissaoEnum = SituacaoAdmissaoEnum;
    private statusProcessoEnum = StatusProcessoEnum;

    constructor(
        private $scope: angular.IScope,
        private $rootScope: angular.IRootScopeService & { nsjGlobals: any, configuracoes: any },
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private entityService: MeurhAdmissaocompletaService,
        private admissoesPreliminarService: MeurhSolicitacoesadmissoesService,
        private MeurhSolicitacoes: MeurhSolicitacoes,
        private entity: any,
        private NewToaster: any,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        private $q: angular.IQService,
        private ModalConfirmService: any,
        private MeurhAcompanhamentoService: MeurhAcompanhamentoService,
        private Configuracoes: any,
    ) {
        this.entityService.reload();

        this.onSubmitSuccess();
        this.onSubmitError();
        this.onSubmitPreliminarSuccess();
        this.onSubmitPreliminarError();

        this.configuracoes = this.Configuracoes.reload();

        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.reload();

        // inicio - caso a admissao completa tenha uma admissao preliminar
        this.admissoesPreliminarService.constructors = {
            estabelecimento: this.entity.estabelecimento,
            solicitacaoadmissaocompleta: this.entity.solicitacao,
            situacao: '0',
        };
        this.busyController.admissaoPreliminar = true;
        this.admissoesPreliminarService.reload();

        this.$scope.$on('meurh_solicitacoesadmissoespreliminar', (event: angular.IAngularEvent, args: any): void => {
            args.forEach((arg: { solicitacao: string; situacaoadmissaopreliminar: number; }) => {
                if (arg.solicitacao === this.entity.solicitacao) {
                    this.entity.situacaoadmissaopreliminar = arg.situacaoadmissaopreliminar;
                    return;
                }
            });
        });

        this.$scope.$on('meurh_solicitacoesadmissoes_list_finished', (event: angular.IAngularEvent, args: any): void => {
            this.busyController.admissaoPreliminar = false;
            if (args && args.length > 0) {
                this.entity.admissaopreliminar = args[0];
                this.entity.situacaoadmissaopreliminar = args[0].situacao;
                this.entity.solicitacaoadmissaopreliminar = args[0].solicitacao;
            }
        });
        // fim - caso a admissao completa tenha uma admissao preliminar

        // inicio - documentos / anexos
        this.entityAnexo['casodeuso'] = 'ADMISSOESCOMPLETAS';
        this.entityAnexo['solicitacao'] = this.entity.solicitacao;
        this.resetTiposDocumentosRequeridosService();

        this.$scope.$on('meurh_leituradocumentos_finalizada', (event: angular.IAngularEvent, args: any): void => {
            this.preencherDadosLeituraDocumentos(args);
            this.busyAnexo = false;
        });
        // fim - documentos / anexos

        this.$scope.$watchGroup(['mrh_admsscmplt_frm_edit_cntrllr.busyController.dadosGerais',
            'mrh_admsscmplt_frm_edit_cntrllr.busyController.foto',
            'mrh_admsscmplt_frm_edit_cntrllr.busyController.admissaoPreliminar'], (newValues: any, oldValues: any): void => {
                if (newValues !== oldValues && (newValues.every((busy: boolean) => busy === false))) {
                    this.busy = false;
                }
            });

        this.$scope.$watch('mrh_admsscmplt_frm_edit_cntrllr.toastController.podeEnviar', function (newValue: any, oldValue: any) {
            let documentosValidos: boolean = this.busyTipoDocumentos ? false : (this.tiposdocumentosrequeridos.length > 0 ? this.validaDocumentosAnexos() : true);

            if (newValue === true && this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA && documentosValidos) {

                this.envioCompleto = true;
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão já pode ser enviada',
                    body: 'Todos os dados obrigatórios já foram informados.',
                    timeout: this.toastTimeout
                });
            }
        }.bind(this), true);

        this.configDesativaAdmPreliminar = this.$rootScope.configuracoes.DESABILITAR_ADMISSAO_PRELIMINAR;

        this.$scope.$watch('mrh_admsscmplt_frm_edit_cntrllr.toastController.podeEnviarPreliminar', function (newValue: any, oldValue: any) {
            // verifica se o formulário está completo para evitar dois toasters sendo exibidos simultaneamente
            if (this.entity.situacaoadmissaopreliminar !== 1 && !this.configDesativaAdmPreliminar) {
                if (
                    newValue === true &&
                    this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA &&
                    !this.entity.solicitacaoadmissaopreliminar &&
                    !this.envioCompleto
                ) {
                    this.NewToaster.pop({
                        type: 'success',
                        title: 'A admissão preliminar já pode ser enviada',
                        body: 'Todos os dados mínimos já foram informados.',
                        timeout: this.toastTimeout
                    });
                }
            }
        }.bind(this), true);

        this.$scope.$watch('mrh_admsscmplt_frm_edit_cntrllr.toastController.podeEncaminhar', function (newValue: any, oldValue: any) {
            if (newValue === true && this.entity.statusprocesso === this.statusProcessoEnum.NAO_ENCAMINHADA) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão já pode ser encaminhada.',
                    body: 'Já é possível encaminhar a admissão para o futuro colaborador informar os dados pelo app.',
                    timeout: this.toastTimeout
                });
            }
        }.bind(this), true);

        this.clienteAna = nsjGlobals.get('clienteana');

        this.$scope.$on('meurh_solicitacoeshistoricos_list_finished', (event: angular.IAngularEvent, args: any): void => {
            if (!!args) {
                args.forEach((historico: any) => {
                    if (historico.tipohistorico === this.situacaoAdmissaoEnum.EFETUADA) {
                        this.dataEfetuada = historico.created_at;
                    }
                    if (historico.tipohistorico === 6) {
                        this.foiEditado = true;
                    }
                });
            }
        });

        this.dataLimiteRetificacao = this.proximoDiaQuinze(this.entity.dataadmissao).format('DD/MM/YYYY');

        this.entity.gestaoBeneficioAvancadaAtiva = this.$rootScope.nsjGlobals.a.configuracoes.GESTAO_BENEFICIO_AVANCADA_ATIVA;
    }

    async $onInit(): Promise<void> {
        if (this.$rootScope.configuracoes.CODIGO_FUNCIONARIO_AUTOMATICO === true) {
            this.matriculaAutomatica = true;
        }

        if (this.$stateParams['camposobrigatorios']) {
            /*
                camposobrigatorios = 1 -> dados obrigatorios para envio da admissao
                camposobrigatorios = 2 -> dados obrigatorios para o encaminhamento
                camposobrigatorios = 3 -> dados obrigatorios para envio da admissao preliminar
            */
            if (this.$stateParams['camposobrigatorios'] === '1') {
                this.$q.when(this.form).then(() => {
                    this.form.$setPristine();
                    this.form.$setSubmitted();
                    this.setMostrarAlerta();
                });
            } else if (this.$stateParams['camposobrigatorios'] === '2') {
                this.$scope.$on('vaga_ativa_load_finished', (event: angular.IAngularEvent, args: any): void => {
                    this.mostraObrigatoriosEncaminhar();
                });
            } else if (this.$stateParams['camposobrigatorios'] === '3') {
                this.mostraObrigatoriosPreliminar();
            }
        }

        this.busy = true;

        this.busyController.foto = true;

        this.entityService.getFoto(this.entity.solicitacao)
        .then(() => {
            this.busyController.foto = false;
        })
        .catch(() => {
            this.busyController.foto = false;
        });

        this.alteraEntityInicio();

        if (
            this.entity.statusprocesso === this.statusProcessoEnum.ENVIADA ||
            this.entity.statusprocesso === this.statusProcessoEnum.EM_ACOMPANHAMENTO
        ) {
            this.editanexo = false;
        }

        // carregamento dos tipos de documentos requeridos
        await this.TiposDocumentosRequeridosService._load(this.TiposDocumentosRequeridosService.constructors, this.TiposDocumentosRequeridosService.after, '')
        .then((documentosCarregados: any) => {
            this.tiposdocumentosrequeridos = documentosCarregados;
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                return tipodocumentorequerido;
            });

        })
        .catch((error) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não foi possível carregar os tipos de documentos',
                body: 'Não foi possível carregar os tipos de documentos requeridos para a admissão',
                timeout: this.toastTimeout
            });
            this.busyTipoDocumentos = false;
        });

    }

    // início - ações do formulário
    public submit(rascunho: boolean = false): void {
        const customText = rascunho
            ? this.textoModal('rascunho')
            : this.textoModal('admissaocompleta');

        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);
        confirm.result
            .then((): void => {
                let documentosValidados: boolean = this.validaDocumentosAnexos();
                this.form.$submitted = true;

                if (
                    (
                        !this.entity.$$__submitting &&
                        this.validaEntity('admissaocompleta') &&
                        documentosValidados &&
                        !rascunho
                    ) ||
                    (
                        rascunho &&
                        !!this.entity.nome
                    )
                ) {
                    this.busy = true;
                    this.alteraEntityEnviar(rascunho);
                    if (
                        this.entity.situacaoadmissaopreliminar !== null &&
                        this.entity.situacaoadmissaopreliminar < 1 &&
                        !rascunho
                    ) {
                        // quando enviar a completa porem tem uma preliminar aberta
                        this.MeurhSolicitacoes.cancelar(this.entity.admissaopreliminar)
                            .then(() => this.entityService.save(this.entitySave))
                            .catch((error?: any) => {
                                if (error.data && error.data.message) {
                                    this.NewToaster.pop({
                                        type: 'error',
                                        title: error.data.message,
                                        timeout: this.toastTimeout
                                    });
                                } else {
                                    this.NewToaster.pop({
                                        type: 'error',
                                        title: 'Ocorreu um erro ao cancelar a admissão preliminar.',
                                        timeout: this.toastTimeout
                                    });
                                }
                            });
                    } else {
                        this.entityService.save(this.entitySave);
                    }
                } else {
                    if (
                        this.validaEntity('admissaocompleta') &&
                        !documentosValidados &&
                        !rascunho
                    ) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Há documento(s) obrigatório(s) não informado(s)',
                            timeout: this.toastTimeout
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Alguns campos do formulário apresentam erros',
                            body: 'Favor verificar os campos obrigatórios, assinalados com *.',
                            timeout: this.toastTimeout
                        });
                    }
                }
            })
            .catch(function () {/**/ });
    }

    public submitPreliminar(): void {
        let confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, this.textoModal('admissaopreliminar'));

        let diasAtrasoAdmissao: number = parseInt(this.configuracoes.ATRASO_ADMISSAO.valor, 10);
        let dataValida: string = moment(moment(this.dataAtual).add(diasAtrasoAdmissao, 'days')).format('YYYY-MM-DD');

        confirm.result
            .then((): void => {
                if ((!this.entity.$$__submitting && this.validaEntity('admissaopreliminar') &&
                    !moment(dataValida).isAfter(this.entity.dataadmissao))) {
                    this.busy = true;
                    this.alteraEntityEnviar(true);
                    this.entityService.enviaPreliminar(this.entitySave);
                } else {
                    if (!this.validaEntity('admissaopreliminar')) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Há campo(s) obrigatório(s) não informado(s)',
                            timeout: this.toastTimeout
                        });
                    } else if (moment(dataValida).isAfter(this.entity.dataadmissao)) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Data de admissão inválida.',
                            body: (
                                'O envio da admissão preliminar precisa acontecer no mínimo ' +
                                diasAtrasoAdmissao +
                                ' dias antes da data de admissão. Altere a data de admissão ou verifique as configurações do sistema.'
                            ),
                            timeout: this.toastTimeout
                        });
                    } else {
                        this.mostraObrigatoriosPreliminar();
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Alguns campos do formulário apresentam erros',
                            body: 'Favor verificar os campos obrigatórios, assinalados com *.',
                            timeout: this.toastTimeout
                        });
                    }
                }
            })
            .catch(function () {/**/ });
    }

    public descartar(): void {
        let customText = this.textoModal('descartar');
        let confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);
        confirm.result
            .then((): void => {
                this.busy = true;
                this.$state.go('meurh_admissaocompleta_show', { 'solicitacao': this.entity.solicitacao });
            })
            .catch(function () {/**/ });
    }

    public async encaminhar(opt?: string): Promise<void> {
        const customText = this.textoModal('encaminhar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result
            .then((): void => {
                this.busy = true;
                const reload: boolean = this.entity.statusprocesso === this.statusProcessoEnum.NAO_ENCAMINHADA;

                this.alteraEntityEnviar(true);

                this.entityService._save(this.entitySave, false)
                    .then(() => {
                        this.entityService.encaminhar(this.entity.solicitacao, opt)
                            .then((response: any) => {
                                this.constructors.solicitacao = this.entity.solicitacao;
                                const titleMessage = opt
                                    ? 'Código gerado com sucesso'
                                    : 'A admissão foi encaminhada com sucesso';

                                const bodyMessage = opt
                                    ? 'O código da admissão foi copiado, você já pode colar onde quiser para mandar para o futuro colaborador.'
                                    : null;

                                this.NewToaster.pop({
                                    type: 'success',
                                    title: titleMessage,
                                    body: bodyMessage,
                                    timeout: this.toastTimeout
                                });

                                if (opt) {
                                    navigator.clipboard.writeText(response.codigoverificacao);
                                }

                                if (reload) {
                                    this.$state.go('meurh_admissaocompleta_show', this.constructors);
                                }
                            })
                            .catch((error) => {
                                const titleMessage = opt
                                    ? 'Não foi possível copiar o código da admissão'
                                    : 'Ocorreu um erro ao encaminhar a admissão';

                                const bodyMessage = error.message
                                    ? error.message
                                    : null;

                                this.NewToaster.pop({
                                    type: 'error',
                                    title: titleMessage,
                                    body: bodyMessage,
                                    timeout: this.toastTimeout
                                });
                            })
                            .finally(() => {
                                this.busy = false;
                                this.$scope.$applyAsync();
                            });
                    })
                    .catch((error) => {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Não foi possível salvar os dados da admissão',
                            body: error.message,
                            timeout: this.toastTimeout
                        });
                    });
            })
            .catch(() => {/**/ });
    }
    // fim - ações do formulário


    // início - controle de botões e campos
    public podeSalvarRascunho(): boolean {
        return this.form.nome.$valid && this.validaDadosBancarios();
    }

    public podeEnviarCompleta(): boolean {
        this.toastController.podeEnviar = this.validaCompleta();

        return this.toastController.podeEnviar;
    }

    public podeEnviarPreliminar(): boolean {
        this.toastController.podeEnviarPreliminar = this.validaPreliminar();

        return this.toastController.podeEnviarPreliminar;
    }

    public podeEncaminhar(): boolean {
        this.toastController.podeEncaminhar = this.validaEncaminhar();
        return (
            this.toastController.podeEncaminhar &&
            this.mostrarEncaminhar()
        );
    }

    public podeEnviarEmail(): boolean {
        return (
            this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA &&
            this.entity.statusprocesso !== this.statusProcessoEnum.FINALIZADA &&
            this.entity.statusprocesso !== this.statusProcessoEnum.RETOMADA
        );
    }

    public mostrarEnviarPreliminar(): boolean {
        return !this.entity.solicitacaoadmissaopreliminar && this.entity.situacaoadmissaopreliminar !== 1;
    }

    public mostrarEncaminhar(): boolean {
        return (
            this.entity.statusprocesso !== this.statusProcessoEnum.RETOMADA &&
            this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA
        );
    }

    public setMostrarAlerta(): void {
        this.mostrarAlerta = true;
    }

    public mostrarObrigatoriosCompleta(): void {
        this.form.$setPristine();
        this.form.$setSubmitted();
        this.mostrarAlerta = true;
        this.mostraobrigatoriosencaminhar = false;
    }

    public mostraObrigatoriosEncaminhar(): void {
        setTimeout(() => {
            if (this.form) {
                this.form.$setPristine();
                this.form.matricula.$setDirty();
                this.form.nome.$setDirty();
                this.form.email.$setDirty();
                this.form.categoriatrabalhador.$setDirty();
                this.form.salariofixo.$setDirty();
                this.form.sindicato.$setDirty();
                this.form.cargo.$setDirty();
                this.form.nivelcargo.$setDirty();
                this.form.departamento.$setDirty();
                this.form.horario.$setDirty();
                this.form.lotacao.$setDirty();
                this.form.unidadesalariofixo.$setDirty();

                if (this.entity.vagaAtiva) {
                    this.form.vaga.$setDirty();
                } else {
                    this.form.estabelecimento.$setDirty();
                }
            }
            this.$scope.$applyAsync();
        }, 100);

        this.mostrarAlerta = false;
        this.mostraobrigatoriosencaminhar = true;
    }

    public mostraObrigatoriosPreliminar(): void {
        setTimeout(() => {
            if (this.form) {
                this.form.$setPristine();
                this.form.matricula.$setDirty();
                this.form.nome.$setDirty();
                this.form.cpf.$setDirty();
                this.form.datanascimento.$setDirty();
                this.form.dataadmissao.$setDirty();
                this.form.departamento.$setDirty();
                this.form.cargo.$setDirty();
                this.form.nivelcargo.$setDirty();
                this.form.salariofixo.$setDirty();
                this.form.horario.$setDirty();
            }
            this.$scope.$applyAsync();
        }, 100);
    }
    // fim - controle de botões e campos

    // início - controle de estilização dinâmica
    public getSituacaoLabel(): string {
        if (this.entity.situacao === SituacaoAdmissaoEnum.ABERTA) {
            return 'label label-aberta';
        }

        if (this.entity.situacao === SituacaoAdmissaoEnum.ENVIADA) {
            return 'label label-warning';
        }

        if (this.entity.situacao === SituacaoAdmissaoEnum.EFETUADA) {
            return 'label label-success';
        }

        if (
            this.entity.situacao === SituacaoAdmissaoEnum.CANCELADA ||
            this.entity.situacao === SituacaoAdmissaoEnum.EXCLUIDA ||
            this.entity.situacao === SituacaoAdmissaoEnum.RECUSADA
        ) {
            return 'label label-danger';
        }

        return '';
    }

    public getSituacaoBindTemplate(): string {

        if (this.entity.wkf_estado) {
            return this.entity.wkf_estado;
        }

        switch (this.entity.situacao) {
            case SituacaoAdmissaoEnum.ABERTA:
                return 'Aberta';

            case SituacaoAdmissaoEnum.ENVIADA:
                return 'Enviada';

            case SituacaoAdmissaoEnum.EFETUADA:
                return 'Efetuada';

            case SituacaoAdmissaoEnum.CANCELADA:
                return 'Cancelada';

            case SituacaoAdmissaoEnum.EXCLUIDA:
                return 'Excluída';

            case SituacaoAdmissaoEnum.RECUSADA:
                return 'Recusada';

            default:
                return ' - ';
        }
    }

    public getSituacaoEncaminhadaBindTemplate(): string {

        if (
            (this.entity.statusprocesso === StatusProcessoEnum.ENVIADA) ||
            this.entity.statusprocesso === StatusProcessoEnum.EM_ACOMPANHAMENTO && this.entity.situacao !== SituacaoAdmissaoEnum.EXCLUIDA
        ) {
            return 'encaminhada';

        } else if (this.entity.situacao === SituacaoAdmissaoEnum.ABERTA && !!this.entity.retificacao) {
            return !this.foiEditado ? 'retificação aberta' : 'retificação em rascunho';

        } else if (this.entity.situacao === SituacaoAdmissaoEnum.ENVIADA && !!this.entity.retificacao) {
            return 'retificação enviada';

        } else if (this.entity.situacao === SituacaoAdmissaoEnum.EFETUADA && !!this.entity.retificacao && !this.entity.retificacao?.solicitacaooriginal) {
            return this.entity.retificacao.situacao !== SituacaoAdmissaoEnum.EFETUADA ? 'em retificação' : 'retificada';

        } else if (this.entity.situacao === SituacaoAdmissaoEnum.EFETUADA && !!this.entity.retificacao?.solicitacaooriginal) {
            return 'retificação efetuada';

        } else {
            return '';
        }

    }

    // fim - controle de estilização dinâmica

    // início - validações
    private validaCompleta(): boolean {
        return (
            this.validaDadosGerais() &&
            this.validaDocumentosAnexos() &&
            this.validaContrato() &&
            this.validaEndereco() &&
            this.validaDadosContato() &&
            this.validaDadosPessoais() &&
            this.validaDocumentacao() &&
            this.validaOutrasInformacoes()
        );
    }

    private validaPreliminar(): boolean {
        if (this.busyTipoDocumentos) {
            return false;
        } else {
            return (
                !this.busyController.admissaoPreliminar &&
                this.validaMatricula() &&
                !!this.entity.nome &&
                !!this.entity.cargo &&
                !!this.entity.nivelcargo &&
                !!this.entity.departamento &&
                !!this.entity.salariofixo &&
                !!this.entity.dataadmissao &&
                !!this.entity.horario &&
                !!this.entity.datanascimento &&
                !!this.entity.cpf
            );
        }
    }

    private validaEncaminhar(): boolean {
        return (
            this.validaMatricula() &&
            !!this.entity.nome &&
            !!this.entity.email &&
            this.validaDadosVaga() &&
            this.validaDadosEmpresa() &&
            this.validaSalarioFixo() &&
            this.validaAdmissao() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaDadosGerais(): boolean {
        return (
            this.validaIdentificacao() &&
            this.validaDadosVaga() &&
            this.validaDadosEmpresa() &&
            this.validaSalarioFixo() &&
            this.validaAdmissao() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaIdentificacao(): boolean {
        const emailValid = this.validaEmail();

        return (
            this.validaMatricula() &&
            !!this.entity.nome &&
            !!this.entity.dataadmissao &&
            !!this.entity.cpf &&
            this.form.cpf.$valid &&
            !!this.entity.datanascimento &&
            emailValid
        );
    }

    private validaMatricula(): boolean {
        return this.matriculaAutomatica || !!this.entity.matricula;
    }

    private validaDadosVaga(): boolean {
        return (
            !!this.entity.vagaAtiva === !!this.entity.vaga &&
            !!this.entity.cargo &&
            !!this.entity.nivelcargo
        );
    }

    private validaDadosEmpresa(): boolean {
        return (
            !!this.entity.estabelecimento &&
            !!this.entity.departamento &&
            !!this.entity.lotacao
        );
    }

    private validaSalarioFixo(): boolean {
        return (
            !!this.entity.salariofixo &&
            !!this.entity.unidadesalariofixo
        );
    }

    private validaSalarioFixoComPisoSindicato(): boolean {

        if (this.entity.salariofixo && this.entity.sindicato?.pisosalarial && this.entity.sindicato.pisosalarial !== '0' && this.entity.unidadesalariofixo === '4') {
            return parseFloat(this.entity.salariofixo) >= parseFloat(this.entity.sindicato.pisosalarial);
        } else {
            return true;
        }

    }

    private validaAdmissao(): boolean {
        return (
            !!this.entity.tipoadmissao &&
            !!this.entity.motivoadmissao
        );
    }

    private validaOutrasInformacoesGerais(): boolean {
        return (
            !!this.entity.sindicato &&
            !!this.entity.categoriatrabalhador &&
            !!this.entity.horario &&
            !!this.entity.regimejornada
        );
    }

    private validaDocumentosAnexos(): boolean {
        if (this.busyTipoDocumentos) {
            return false;
        } else {
            return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
                return tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado;
            });
        }
    }

    private validaContrato(): boolean {
        return (
            this.validaPrazo() &&
            this.validaPeriodos() &&
            this.validaOutrasInformacoesContrato() &&
            this.validaContratacaoTemporaria()
        );
    }

    private validaPrazo(): boolean {
        return (
            this.validaDiasFimContrato() &&
            this.validaDiasexperienciacontrato()
        );
    }

    private validaDiasFimContrato(): boolean {
        return this.entity.tipocontrato !== '2' || !!this.entity.datafimcontrato;
    }

    private validaDiasexperienciacontrato(): boolean {
        return this.entity.tipocontrato === '2'
            ? (
                this.entity.categoriatrabalhador.categoriatrabalhador === '105' ||
                this.entity.categoriatrabalhador.categoriatrabalhador === '106' || // trabalhador temporário
                !!this.entity.diasexperienciacontrato
            )
            : true;
    }

    private validaPeriodos(): boolean {
        return !(
            !(!!this.entity.numerodiasperiodo) ||
            this.entity.numerodiasperiodo > 31 ||
            !(!!this.entity.numerohorasmensais) ||
            !(!!this.entity.quantidademediahorassemanais)
        );
    }

    private validaOutrasInformacoesContrato(): boolean {
        return (
            !!this.entity.tiporegimetrabalhista &&
            !!this.entity.tipoatividade
        );
    }

    private validaContratacaoTemporaria(): boolean {
        return (
            this.entity.categoriatrabalhador.categoriatrabalhador !== '106' ||
            (
                !!this.entity.motivocontratacaotemporaria &&
                !!this.entity.tipoinclusaotemporaria
            ) &&
            (
                this.entity.motivocontratacaotemporaria !== 1 ||
                this.form.cpftrabalhadorsubstituido.$valid
            )
        );
    }

    private validaEndereco(): boolean {

        let infosValidasPaisEstrangeiro: boolean = false;

        if (this.entity.pais) {
            if (this.entity.pais.pais !== '1058') {
                infosValidasPaisEstrangeiro = !!this.entity.formatributacaoexterior;
            } else if (this.entity.pais.pais === '1058') {
                infosValidasPaisEstrangeiro = true;
            }
        }

        return (
            !!this.entity.tipologradouro &&
            !!this.entity.logradouro &&
            !!this.entity.numero &&
            !!this.entity.cep &&
            !!this.entity.municipio &&
            !!this.entity.pais &&
            infosValidasPaisEstrangeiro
        );
    }

    private validaDadosContato(): boolean {
        const telofonesValid = this.validaTelefones();

        return telofonesValid;
    }

    private validaTelefones(): boolean {
        return (
            this.validaTelefone() &&
            this.validaCelular()
        );
    }

    private validaTelefone(): boolean {
        return !(!!this.entity.telefone) || this.entity.telefone.length >= 8;
    }

    private validaCelular(): boolean {
        return !(!!this.entity.celular) || this.entity.celular.length >= 8;
    }

    private validaEmail(): boolean {
        return (
            !!this.entity.emailcorporativo ||
            !!this.entity.email
        );
    }

    private validaDadosPessoais(): boolean {
        return (
            this.validaNascimento() &&
            this.validaIdentificacaoDadosPessoais()
        );
    }

    private validaNascimento(): boolean {

        let infosValidasPaisEstrangeiro: boolean = false;

        if (this.entity.paisnascimento) {
            if (this.entity.paisnascimento.pais !== '1058') {
                infosValidasPaisEstrangeiro = !!this.entity.datachegadapais && !!this.entity.temporesidenciaestrangeiro && !!this.entity.classificacaoestrangeiro;
            } else if (this.entity.paisnascimento.pais === '1058') {
                infosValidasPaisEstrangeiro = true;
            }
        }

        return (
            !!this.entity.municipionascimento &&
            !!this.entity.paisnascimento &&
            !!this.entity.paisnacionalidade &&
            infosValidasPaisEstrangeiro
        );
    }

    private validaIdentificacaoDadosPessoais(): boolean {

        let validacoesClienteAna = true;

        if (this.clienteAna && !this.entity.semfiliacao) {
            validacoesClienteAna = !!this.entity.nomemae || !!this.entity.nomepai;
        }

        return (
            !!this.entity.sexo &&
            !!this.entity.estadocivil &&
            !!this.entity.raca &&
            !!this.entity.grauinstrucao &&
            validacoesClienteAna
        );
    }

    private validaDocumentacao(): boolean {
        return (
            this.validaDocumentacaoObrigatoria() &&
            this.validaCtps()
        );
    }

    private validaDocumentacaoObrigatoria(): boolean {
        return (
            this.validaNis()
        );
    }

    private validaNis(): boolean {
        if (!this.entity.primeiroemprego) {
            var ftap: string = '3298765432';
            var total: number = 0;
            var resto: number = 0;
            var strResto: string = '';
            var numPIS: string = this.entity.nis;

            if (!numPIS) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            }

            for (var i = 0; i <= 9; i++) {
                var resultado: number = parseInt(numPIS.slice(i, i + 1), 10) * parseInt(ftap.slice(i, i + 1), 10);
                total = total + resultado;
            }

            resto = (total % 11);

            if (resto !== 0) {
                resto = 11 - resto;
            }

            if (resto === 10 || resto === 11) {
                strResto = resto + '';
                resto = parseInt(strResto.slice(1, 2), 10);
            }

            if (resto !== parseInt(numPIS.slice(10, 11), 10)) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            } else {
                this.form.nis.$error = { 'nisInvalido': false };
                return true;
            }
        } else {
            this.form.nis.$error = {};
            return true;
        }
    }

    private validaCtps(): boolean {
        return !(!!this.entity.numeroctps && !(!!this.entity.ufctps && !!this.entity.seriectps));
    }

    private validaOutrasInformacoes(): boolean {
        return (
            this.validaInformacoesGerais() &&
            this.validaFerias() &&
            this.validaRecebimento()
        );
    }

    private validaPercentualAdiantamento(percentual: number): boolean {
        const PERCENTUAL_MIN = 0; // 0%
        const PERCENTUAL_MAX = 0.4; // 40%

        return percentual >= PERCENTUAL_MIN && percentual <= PERCENTUAL_MAX;
    }

    private validaInformacoesGerais(): boolean {
        return (
            !!this.entity.vinculo &&
            !!this.entity.datavencimentoatestadomedico &&
            this.validaPercentualAdiantamento(this.entity.percentualadiantamento)
        );
    }

    private validaFerias(): boolean {
        return (
            !!this.entity.inicioperiodoaquisitivoferias
        );
    }

    private validaRecebimento(): boolean {
        return (
            this.entity.tiporecebimentosalario &&
            this.validaDadosBancarios() &&
            this.validaDadosPix()
        );
    }

    private validaDadosBancarios(): boolean {
        if (
            !this.entity.semdadosbancarios &&
            (
                this.entity.tiporecebimentosalario === '1' ||
                this.entity.tiporecebimentosalario === '2' ||
                this.entity.tiporecebimentosalario === '4' ||
                this.entity.tiporecebimentosalario === '64'
            )
        ) {

            let tipoContaPixValida: boolean = true;

            if (this.entity.tiporecebimentosalario === '64' && !(!!this.entity.tipocontapix)) {
                tipoContaPixValida = false;
            }

            return (
                !!this.entity.banco &&
                (!!this.entity.agencia || !!this.entity.agencianumero) &&
                !!this.entity.numerocontasalario &&
                !!this.entity.numerocontasalariodv &&
                tipoContaPixValida
            );
        }
        return true;
    }

    private validaDadosPix(): boolean {
        if (
            this.entity.tiporecebimentosalario === '128' ||
            this.entity.tiporecebimentosalario === '256' ||
            this.entity.tiporecebimentosalario === '512' ||
            this.entity.tiporecebimentosalario === '1024'
        ) {
            return !!this.entity.chavepix;
        }
        return true;
    }

    private validaEntity(tipo: string): boolean {
        if (tipo === 'admissaocompleta' && this.validaCompleta()) {
            this.entity.matricula = this.entity.matricula.toUpperCase();
            this.entity.subtiposolicitacao = 0;
            return true;
        } else if (tipo === 'admissaopreliminar' && this.validaPreliminar()) {
            this.entity.matricula = this.entity.matricula.toUpperCase();
            this.entity.subtiposolicitacao = 1;
            return true;
        }

        return false;
    }
    // fim - validações


    // início - observers
    private onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_submitted', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.entityService.constructors.solicitacao = args.entity.solicitacao;
                this.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;
                this.entityAnexo['solicitacao'] = args.entity.solicitacao;
                this.constructors.solicitacao = args.entity.solicitacao;

                this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

                if (this.tiposdocumentosrequeridos.length <= 0 || this.alterouanexo === false) {
                    if (!this.enviaEncaminhar) {
                        let tituloToast = args.entity.situacao === this.situacaoAdmissaoEnum.ABERTA
                            ? 'A admissão foi editada com sucesso.'
                            : 'A admissão foi enviada com sucesso.';

                        this.NewToaster.pop({
                            type: 'success',
                            title: tituloToast,
                            timeout: this.toastTimeout
                        });

                        this.$state.go('meurh_admissaocompleta_show', this.constructors);
                    } else {
                        this.entityService.encaminhar(args.entity.solicitacao);
                    }
                } else {
                    this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                        this.$scope.$apply(); // para atualizar os valores dos validateResult vindos do componente anexo
                        if (this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult) && !this.documentosValidados) {
                            this.documentosValidados = true;
                            if (!this.enviaEncaminhar) {
                                let tituloToast = args.entity.situacao === this.situacaoAdmissaoEnum.ABERTA ? 'A admissão foi editada com sucesso.' : 'A admissão foi enviada com sucesso.';
                                this.NewToaster.pop({
                                    type: 'success',
                                    title: tituloToast,
                                    timeout: this.toastTimeout
                                });
                                this.$state.go('meurh_admissaocompleta_show', this.constructors);
                            } else {
                                this.entityService.encaminhar(args.entity.solicitacao);
                            }
                        }
                    });
                }
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
                this.busy = false;
                this.$state.go('meurh_admissaocompleta_show', this.constructors);
            }
        });
    }

    private onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_submit_error', (event: angular.IAngularEvent, args: any): void => {
            let mensagemErro = '';
            const tituloErro = args.entity.situacao === this.situacaoAdmissaoEnum.ABERTA
                ? 'Desculpe, ocorreu um erro ao editar a admissão'
                : 'Desculpe, ocorreu um erro ao enviar a admissão';

            try {
                if (args.response?.data?.errors?.children) {
                    for (const campo in args.response.data.errors.children) {
                        if (args.response.data.errors.children[campo]['errors']) {
                            args.response.data.errors.children[campo]['errors'].forEach((element: string) => {
                                mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                            });
                        }
                    }
                } else if (args.response?.data?.message) {
                    mensagemErro = args.response.data.message;
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: tituloErro,
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: tituloErro,
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
                this.enviaEncaminhar = false;
            }
        });
    }

    private onSubmitPreliminarSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_preliminar_submitted', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.entityService.constructors.solicitacao = args.entity.solicitacao;
                this.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;
                this.entityAnexo['solicitacao'] = args.entity.solicitacao;
                this.constructors.solicitacao = args.entity.solicitacao;

                this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

                if (this.tiposdocumentosrequeridos.length <= 0 || this.alterouanexo === false) {
                    this.NewToaster.pop({
                        type: 'success',
                        title: 'A admissão preliminar foi enviada com sucesso.',
                        timeout: this.toastTimeout
                    });
                    this.$state.go('meurh_admissaocompleta_show', this.constructors);
                } else {
                    this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                        this.$scope.$apply(); // para atualizar os valores dos validateResult vindos do componente anexo
                        if (
                            this.tiposdocumentosrequeridos.every(
                                (tipodocumentorequerido) => tipodocumentorequerido.validateResult
                            ) &&
                            !this.documentosValidados
                        ) {
                            this.documentosValidados = true;
                            this.NewToaster.pop({
                                type: 'success',
                                title: 'A admissão preliminar foi enviada com sucesso.',
                                timeout: this.toastTimeout
                            });
                            this.$state.go('meurh_admissaocompleta_show', this.constructors);
                        }
                    });
                }
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
                this.busy = false;
                this.$state.go('meurh_admissaocompleta_show', this.constructors);
            }
        });
    }

    private onSubmitPreliminarError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_preliminar_submit_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                let mensagemErro = '';

                if (!!args.response && !!args.response.data) {
                    if (!!args.response.data.errors && !!args.response.data.errors.children) {
                        args.response.data.errors.children.forEach((element: any) => {
                            mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                        });
                    } else if (!!args.response.data.message) {
                        mensagemErro = args.response.data.message;
                    }
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao enviar a admissão preliminar',
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
            }
        });
    }
    // fim - observers


    // inicio - tratamento de dados
    private alteraEntityInicio(): void {
        // dados gerais
        this.entity.unidadesalariofixo = this.entity.unidadesalariofixo ? this.entity.unidadesalariofixo.toString() : this.entity.unidadesalariofixo;
        this.entity.tipoadmissao = this.entity.tipoadmissao ? this.entity.tipoadmissao.toString() : this.entity.tipoadmissao;
        this.entity.regimejornada = this.entity.regimejornada ? this.entity.regimejornada.toString() : this.entity.regimejornada;
        // contrato
        this.entity.tipocontrato = this.entity.tipocontrato.toString();
        this.entity.motivoadmissao = this.entity.motivoadmissao ? this.entity.motivoadmissao.toString() : this.entity.motivoadmissao;
        this.entity.tipoatividade = this.entity.tipoatividade ? this.entity.tipoatividade.toString() : this.entity.tipoatividade;
        this.entity.tiporegimetrabalhista = this.entity.tiporegimetrabalhista ? this.entity.tiporegimetrabalhista.toString() : this.entity.tiporegimetrabalhista;
        this.entity.tipoinclusaotemporaria = this.entity.tipoinclusaotemporaria ? this.entity.tipoinclusaotemporaria.toString() : this.entity.tipoinclusaotemporaria;
        // dados pessoais
        this.entity.sexo = this.entity.sexo ? this.entity.sexo.toString() : this.entity.sexo;
        this.entity.estadocivil = this.entity.estadocivil ? this.entity.estadocivil.toString() : this.entity.estadocivil;
        this.entity.tiposanguineo = this.entity.tiposanguineo ? this.entity.tiposanguineo.toString() : this.entity.tiposanguineo;
        this.entity.grauinstrucao = this.entity.grauinstrucao ? this.entity.grauinstrucao.grauinstrucao : null;
        // documentacao
        this.entity.tipocertidao = this.entity.tipocertidao ? this.entity.tipocertidao.toString() : this.entity.tipocertidao;
        this.entity.categoriacnh = this.entity.categoriacnh ? this.entity.categoriacnh.toString() : this.entity.categoriacnh;
        // outras informacoes
        this.entity.tiporecebimentosalario = this.entity.tiporecebimentosalario !== undefined && this.entity.tiporecebimentosalario !== null
            ? this.entity.tiporecebimentosalario.toString()
            : this.entity.tiporecebimentosalario;

        this.entity.tipocontapix = this.entity.tiporecebimentosalario === '64' && this.entity.tipocontapix ? this.entity.tipocontapix.toString() : null;

        this.entity.percentualadiantamento /= 100;

        if (!!this.entity.dependentes && this.entity.dependentes.length > 0) {
            this.entity.dependentes.forEach((element: any) => {
                element.percentualpensaoalimenticia /= 100;
                element.percentualpensaoalimenticiafgts /= 100;
            });
        }

        this.entity.temporesidenciaestrangeiro = !!this.entity.temporesidenciaestrangeiro ? this.entity.temporesidenciaestrangeiro.toString() : this.entity.temporesidenciaestrangeiro;
        this.entity.classificacaoestrangeiro = !!this.entity.classificacaoestrangeiro ? this.entity.classificacaoestrangeiro.toString() : this.entity.classificacaoestrangeiro;
        this.entity.agencianumero = this.entity.agencia ? this.entity.agencia.agencianumero : this.entity.agencianumero;
    }

    private alteraEntityEnviar(rascunho: boolean = false): void {
        this.entitySave = angular.copy(this.entity);
        this.entitySave.situacao = rascunho ? this.situacaoAdmissaoEnum.ABERTA : this.situacaoAdmissaoEnum.ENVIADA;

        this.entitySave.gestores = [];
        if (this.entity.gestores) {
            this.entity.gestores.map((gestor: IGestoresTrabalhador) => {
                if (gestor.gestor) {
                    this.entitySave.gestores?.push({ 'gestor': gestor.gestor });
                } else {
                    this.entitySave.gestores?.push({ 'identificacaonasajongestor': gestor.email });
                }
            });
        }
        this.entitySave.gestores = JSON.stringify(this.entitySave.gestores);

        this.entitySave.vaga = this.entitySave.vaga ? this.entitySave.vaga.vaga : null;
        this.entitySave.estabelecimento = this.entitySave.estabelecimento ? this.entitySave.estabelecimento.estabelecimento : null;
        this.entitySave.cargo = this.entitySave.cargo ? this.entitySave.cargo.cargo : null;
        this.entitySave.nivelcargo = this.entitySave.nivelcargo ? this.entitySave.nivelcargo.nivelcargo : null;
        this.entitySave.departamento = this.entitySave.departamento ? this.entitySave.departamento.departamento : null;
        this.entitySave.lotacao = this.entitySave.lotacao ? this.entitySave.lotacao.lotacao : null;
        this.entitySave.ambiente = this.entitySave.ambiente ? this.entitySave.ambiente.ambiente : null;
        this.entitySave.condicaoambientetrabalho = this.entitySave.condicaoambientetrabalho ? this.entitySave.condicaoambientetrabalho.condicaoambientetrabalho : null;
        this.entitySave.grupodeponto = this.entitySave.grupodeponto ? this.entitySave.grupodeponto.grupo : null;
        this.entitySave.sindicato = this.entitySave.sindicato ? this.entitySave.sindicato.sindicato : null;
        this.entitySave.horario = this.entitySave.horario ? this.entitySave.horario.horario : null;
        this.entitySave.categoriatrabalhador = this.entitySave.categoriatrabalhador ? this.entitySave.categoriatrabalhador.categoriatrabalhador : null;
        this.entitySave.municipionascimento = this.entitySave.municipionascimento ? this.entitySave.municipionascimento.ibge : null;
        this.entitySave.paisnascimento = this.entitySave.paisnascimento ? this.entitySave.paisnascimento.pais : null;
        this.entitySave.paisnacionalidade = this.entitySave.paisnacionalidade ? this.entitySave.paisnacionalidade.pais : null;
        this.entitySave.raca = this.entitySave.raca ? this.entitySave.raca.raca : null;
        this.entitySave.tipologradouro = this.entitySave.tipologradouro ? this.entitySave.tipologradouro.tipologradouro : null;
        this.entitySave.municipio = this.entitySave.municipio ? this.entitySave.municipio.ibge : null;
        this.entitySave.pais = this.entitySave.pais ? this.entitySave.pais.pais : null;

        // outras informações
        this.entitySave.vinculo = this.entitySave.vinculo ? this.entitySave.vinculo.vinculo : null;
        this.entitySave.percentualadiantamento *= 100;
        this.entitySave.agencia = null;
        if (this.entitySave.tiporecebimentosalario === undefined || this.entitySave.tiporecebimentosalario === null) {
            delete this.entitySave.tiporecebimentosalario;
            delete this.entitySave.tipocontapix;
        } else if (this.entitySave.tiporecebimentosalario === '64') {
            this.entitySave.tipocontapix = parseInt(this.entitySave.tipocontapix, 10);
        }

        if (this.entitySave.banco) {
            this.entitySave.banco = this.entitySave.banco.banco ? this.entitySave.banco.banco : null;
        }

        if (this.entitySave.beneficios) {
            const beneficios = this.entitySave.beneficios.map((element: any) => {
                element.lotacao = angular.copy(element.lotacao.lotacao);
                element.instituicao = element.instituicao ? angular.copy(element.instituicao.instituicao) : element.instituicao;
                if (element.usobeneficio === 2) {
                    element.cpfdependente = element.dependenteSelecionado.cpf;
                }

                if (element.usobeneficio === 1 && element.cpfdependente != null) {
                    element.cpfdependente = element.dependente = null;
                }

            });

            this.entitySave.beneficios = JSON.stringify(this.entitySave.beneficios);
        }

        if (this.entitySave.dependentes) {
            this.entitySave.dependentes.map((element: any) => {
                element.datainclusao = element.datainclusao ? element.datainclusao : null;
                element.databaixaimpostorenda = element.databaixaimpostorenda ? element.databaixaimpostorenda : null;
                element.vencimentoescolar = element.vencimentoescolar ? element.vencimentoescolar : null;
                element.vencimentovacinacao = element.vencimentovacinacao ? element.vencimentovacinacao : null;
                element.agencia = element.agencia ? angular.copy(element.agencia.agencia) : element.agencia;
                element.percentualpensaoalimenticia *= 100;
                element.percentualpensaoalimenticiafgts *= 100;
            });

            this.entitySave.dependentes = JSON.stringify(this.entitySave.dependentes);
        }

        if (this.entitySave.vts) {
            this.entitySave.vts.forEach((element: any) => {
                element.tarifaconcessionariavt = angular.copy(element.tarifaconcessionariavt.tarifaconcessionariavt);
            });

            this.entitySave.vts = JSON.stringify(this.entitySave.vts);
        }

        this.entitySave.temporesidenciaestrangeiro = !!this.entitySave.temporesidenciaestrangeiro ? parseInt(this.entitySave.temporesidenciaestrangeiro, 10) : null;
        this.entitySave.classificacaoestrangeiro = !!this.entitySave.classificacaoestrangeiro ? this.entitySave.classificacaoestrangeiro : null;
    }

    private preencherDadosLeituraDocumentos(args: any): void {
        args.dados.forEach((dado: any): void => {
            dado.fields.forEach((field: any): void => {
                switch (field.name) {
                    case 'nome':
                        this.entity.nome = field.value;
                        break;

                    case 'cpf':
                        this.entity.cpf = field.value;
                        break;

                    case 'data_nascimento':
                        this.entity.datanascimento = field.value;
                        break;

                    case 'nome_social':
                        this.entity.nomesocial = field.value;
                        break;

                    case 'filiacao_2': // nomenclatura para o nome do pai
                        this.entity.nomepai = field.value;
                        break;

                    case 'filiacao_1': // nomenclatura para o nome da mãe
                        this.entity.nomemae = field.value;
                        break;

                    case 'naturalidade':
                        this.entity.cidadenascimento = field.value;
                        break;

                    case 'rg':
                        this.entity.numerorg = field.value;
                        break;

                    case 'titulo_eleitor':
                        this.entity.numerote = field.value;
                        break;

                    case 'ctps':
                        this.entity.numeroctps = field.value;
                        break;

                    case 'serie':
                        this.entity.seriectps = field.value;
                        break;

                    case 'nis_pis_pasep': // nomenclatura do NIS no documento de RG
                        this.entity.nis = field.value;
                        break;

                    case 'cnh': // nomenclatura da CNH no documento de RG
                        this.entity.numerocnh = field.value;
                        break;

                    case 'registro': // nomenclatura da CNH no documento de CNH
                        this.entity.numerocnh = field.value;
                        break;
                }
            });
        });
    }

    private textoModal(tipo: string): object {
        let customText: any = {
            'rascunho': {
                title: 'Salvar admissão',
                confirmText: 'Você tem certeza que deseja salvar a admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, salvar',
            },
            'encaminhar': {
                title: 'Encaminhar admissão',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você encaminhar a admissão, os campos que o futuro colaborador pode preencher através do aplicativo ficarão bloqueados para edição até o momento que o mesmo termine de preencher os dados, ou até que seja necessário retomar a admissão.'
                },
                confirmText: 'Você tem certeza que deseja encaminhar a admissão para ' + this.entity.nome + ' informar os dados pelo aplicativo?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, encaminhar'
            },
            'admissaopreliminar': {
                title: 'Enviar admissão preliminar',
                alertInfo: {
                    header: 'Sobre a admissão preliminar',
                    text: 'Após você enviar a admissão preliminar, não será mais possível editar os dados informados.' +
                    (!this.validaSalarioFixoComPisoSindicato() ? ` O salário fixo tem um valor inferior ao piso do sindicato, que é de R$ ${parseFloat(this.entity.sindicato.pisosalarial).toFixed(2)}` : '')
                },
                confirmText: 'Você tem certeza que deseja enviar a admissão preliminar de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar'
            },
            'admissaocompleta': {
                title: 'Enviar admissão completa',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você enviar a admissão completa, não será mais possível editá-la.' +
                    (!this.validaSalarioFixoComPisoSindicato() ? ` O salário fixo tem um valor inferior ao piso do sindicato, que é de R$ ${parseFloat(this.entity.sindicato.pisosalarial).toFixed(2)}` : '')
                },
                confirmText: 'Você tem certeza que deseja enviar a admissão completa de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar'
            },
            'descartar': {
                title: 'Descartar edição',
                subtitle: 'Todos os dados informados serão perdidos.',
                confirmText: 'Você tem certeza que deseja descartar as alterações feitas na admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, descartar',
            }
        };

        if (this.entity.situacaoadmissaopreliminar < 1 && !this.configDesativaAdmPreliminar) {
            customText.admissaocompleta.subtitle = 'Caso você envie a admissão completa, a admissão preliminar em análise será automaticamente cancelada.';
        }

        return customText[tipo];
    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': TipoSolicitacaoEnum.ADMISSOESCOMPLETAS };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

    private proximoDiaQuinze(data: string): moment.Moment {
        const dataProximoDiaQuinze = moment(data);

        if (dataProximoDiaQuinze.date() > 15) {
            dataProximoDiaQuinze.add(1, 'month');
        }

        dataProximoDiaQuinze.date(15);

        return dataProximoDiaQuinze;
    }
    // fim - tratamento de dados
}
