declare var nsjGlobals: any;
import ng from 'angular';
import angular from 'angular';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';
import { MeurhAdmissaoestagiarioService } from '../admissaoestagiario.service';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { SituacaoAdmissaoEnum } from '../enums/situacao';
import { TipoRecebimentobolsaEnum } from '../enums/tiporecebimentobolsa.enum';
import { StatusProcessoEnum } from '../enums/statusprocesso.enum';
import { IGestoresTrabalhador } from '../../Transferenciacolaboradores/models/transferenciacolaboradores.model';
import { MeurhAcompanhamentoService } from '../../Acompanhamento/acompanhamento.service';

export class MeurhAdmissaoestagiarioShowController implements ng.IController {
    static $inject = [
        '$scope',
        '$stateParams',
        '$state',
        'MeurhAdmissaoestagiarioService',
        'entity',
        'NewToaster',
        '$rootScope',
        'ModalConfirmService',
        'MeurhSolicitacoes',
        'TiposDocumentosRequeridosService',
        'WorkflowService',
        'MeurhAcompanhamentoService',
    ];

    public constructors: any = {};
    public action = 'show';
    public tipo = this.$stateParams['tipo'];
    public form: angular.IFormController;
    public busy: boolean = true;
    public entitySave: any = {};
    public matriculaAutomatica: boolean = false;

    // documentos / anexos
    public tiposdocumentosrequeridos: Array<any>;
    public busyTipoDocumentos: boolean = true;
    public entityAnexo: IAnexo = {};
    public formAnexo: object = {};
    public editanexo: boolean = false;
    public entityAnexoLista: Array<IAnexo> = [];
    public adicionarAnexo: boolean = false;
    public actionAnexo: string = 'show';
    public clienteAna: boolean;

    private situacaoAdmissaoEnum = SituacaoAdmissaoEnum;
    private tipoRecebimentobolsaEnum = TipoRecebimentobolsaEnum;
    private statusprocessoEnum = StatusProcessoEnum;

    constructor(
        private $scope: angular.IScope,
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private entityService: MeurhAdmissaoestagiarioService,
        private entity: any,
        private NewToaster: any,
        public $rootScope: angular.IRootScopeService & {
            nsjGlobals: any,
            configuracoes: any,
            codigoHook: number | string,
            liberadoTela: (param: string) => boolean
            temPermissao: (param: string) => boolean
        },
        private ModalConfirmService: any,
        private $MeurhSolicitacoes: any,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        private WorkflowService: WorkflowService,
        private MeurhAcompanhamentoService: MeurhAcompanhamentoService,
    ) {
        this.entityService.getFoto(this.entity.solicitacao)
        .then(() => {
            this.busy = false;
        })
        .catch(() => {
            this.busy = false;
        });

        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.reload();

        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();

        this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesadmissoesestagiario_');

        this.onSubmitSuccess();
        this.onSubmitError();
        this.onExcluirSuccess();
        this.onExcluirError();
        this.onTiposDocumentosListFinished();
        this.clienteAna = nsjGlobals.get('clienteana');
        this.entity.gestaoBeneficioAvancadaAtiva = this.$rootScope.nsjGlobals.a.configuracoes.GESTAO_BENEFICIO_AVANCADA_ATIVA;
    }

    $onInit(): void {
        if (this.$rootScope.configuracoes.CODIGO_FUNCIONARIO_AUTOMATICO === true) {
            this.matriculaAutomatica = true;
        }
        this.entityAnexo['solicitacao'] = this.entity.solicitacao;
        this.entityAnexo['casodeuso'] = 'ADMISSOESESTAGIARIO';
        this.alteraEntityInicio();
    }

    // início - navegação
    public goToEdit(camposobrigatorios: number | null = null): void {
        this.busy = true;
        this.$state.go('meurh_admissaoestagiario_edit',
            angular.extend({}, {
                'solicitacao': this.entity.solicitacao,
                camposobrigatorios
            })
        );
    }
    // fim - navegação


    // início - use cases
    public submit(): void {
        const customText = this.getMensagensModal('enviar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result
            .then((): void => {
                this.busy = true;
                this.form.$submitted = true;

                if (!this.entity.$$__submitting && this.validaEntityEnviar()) {
                    this.busy = true;
                    this.alteraEntityEnviar();
                    this.entityService.save(this.entitySave);
                } else {
                    if (!this.validaDocumentosAnexos()) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Há documento(s) obrigatório(s) não informado(s).',
                            body: 'Favor editar a admissão.',
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Alguns campos do formulário apresentam erros',
                            body: 'Favor editar a admissão.',
                        });
                    }

                    this.busy = false;
                }
            })
            .catch(function () {/**/ });
    }

    public excluir(): void {
        const customText = this.getMensagensModal('excluir');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);
        confirm.result
            .then((): void => {
                this.entityService.delete(this.entity.solicitacao, true);
            })
            .catch(function () {/**/ });
    }

    public encaminhar(opt?: string): void {
        const customText = this.getMensagensModal('encaminhar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result
            .then(async (): Promise<void> => {
                this.busy = true;
                this.constructors.solicitacao = this.entity.solicitacao;

                await this.entityService.encaminhar(this.entity.solicitacao, opt)
                    .then((encaminharResponse: ng.IHttpResponse<any>) => {
                        const titleMessage = opt
                            ? 'Código gerado com sucesso'
                            : 'A admissão foi encaminhada com sucesso.';

                        const bodyMessage = opt
                            ? 'O código da admissão foi copiado, você já pode colar onde quiser para mandar para o futuro colaborador.'
                            : null;

                        this.NewToaster.pop({
                            type: 'success',
                            title: titleMessage,
                            body: bodyMessage
                        });

                        if (!!encaminharResponse.data.codigoverificacao) {
                            navigator.clipboard.writeText(encaminharResponse.data.codigoverificacao);
                        }
                    })
                    .catch(() => {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Ocorreu um erro ao encaminhar a admissão para o aplicativo'
                        });
                    })
                    .finally(() => {
                        this.busy = false;
                    });
            })
            .catch(() => {/** */ });
    }

    public retomar(): void {
        const customText = this.getMensagensModal('retomar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result
            .then(async (): Promise<void> => {
                this.busy = true;
                this.constructors.solicitacao = this.entity.solicitacao;

                await this.entityService.retomar(this.entity.solicitacao)
                    .then(() => {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A admissão foi retomada com sucesso.'
                        });
                    })
                    .catch((error) => {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Ocorreu um erro ao retomar a admissão',
                            body: error.message
                        });
                    })
                    .finally(() => {
                        this.busy = false;
                    });
            })
            .catch(() => {/** */ });
    }

    public cancelar(entity: ISolicitacao): void {
        const customText = this.getMensagensModal('cancelar');
        const confirm = this.ModalConfirmService.openCancelar(entity, 'solicitação de admissão estagiário', customText);

        confirm.result
            .then((entity: ISolicitacao) => {
                this.busy = true;

                this.$MeurhSolicitacoes.cancelar(entity)
                    .then((response: any) => {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A solicitação de admissão de estagiário foi cancelada com sucesso.'
                        });
                        this.$rootScope.$broadcast('meurh_solicitacoesadmissoesestagiario_cancelada');
                        this.$state.reload();
                    }).catch((response: any) => {
                        if (typeof (response.message) !== 'undefined' && response.message) {
                            this.NewToaster.pop(
                                {
                                    type: 'error',
                                    title: response.message
                                });
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Erro ao cancelar.'
                            });
                        }
                    }).finally(() => this.busy = false);
            })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }
    // fim - use cases


    // início - controle de estilização dinâmica
    public getSituacaoLabel(): string {
        if (this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA) {
            return 'label label-aberta';
        }

        if (this.entity.situacao === this.situacaoAdmissaoEnum.ENVIADA) {
            return 'label label-warning';
        }

        if (this.entity.situacao === this.situacaoAdmissaoEnum.EFETUADA) {
            return 'label label-success';
        }

        if (
            this.entity.situacao === this.situacaoAdmissaoEnum.CANCELADA ||
            this.entity.situacao === this.situacaoAdmissaoEnum.EXCLUIDA ||
            this.entity.situacao === this.situacaoAdmissaoEnum.RECUSADA
        ) {
            return 'label label-danger';
        }

        return '';
    }

    public getSituacaoBindTemplate(): string {

        if (this.entity.wkf_estado) {
            return this.entity.wkf_estado;
        }

        switch (this.entity.situacao) {
            case this.situacaoAdmissaoEnum.ABERTA:
                return 'Aberta';

            case this.situacaoAdmissaoEnum.ENVIADA:
                return 'Enviada';

            case this.situacaoAdmissaoEnum.EFETUADA:
                return 'Efetuada';

            case this.situacaoAdmissaoEnum.CANCELADA:
                return 'Cancelada';

            case this.situacaoAdmissaoEnum.EXCLUIDA:
                return 'Excluída';

            case this.situacaoAdmissaoEnum.RECUSADA:
                return 'Recusada';

            default:
                return ' - ';
        }
    }

    public getSituacaoEncaminhadaBindTemplate(): string {
        let stringLabel: string = '';

        if (
            this.entity.situacao !== this.situacaoAdmissaoEnum.EXCLUIDA &&
            (
                this.entity.statusprocesso === this.statusprocessoEnum.ENVIADA ||
                this.entity.statusprocesso === this.statusprocessoEnum.EM_ACOMPANHAMENTO
            )
        ) {
            stringLabel = 'encaminhada';
        }

        return stringLabel;
    }
    // fim - controle de estilização dinâmica


    // início - controle de botões e campos
    public podeEnviar(): boolean {
        return this.validaEntityEnviar();
    }

    public podeEncaminhar(): boolean {
        return this.validaEncaminhar();
    }

    public podeEnviarEmail(): boolean {
        return (
            this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA &&
            this.entity.statusprocesso !== this.statusprocessoEnum.FINALIZADA &&
            this.entity.statusprocesso !== this.statusprocessoEnum.RETOMADA
        );
    }

    public podeRetomar(): boolean {
        return (
            this.$rootScope.temPermissao('criar_admissao_comp') &&
            this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA &&
            (
                !!this.entity.statusprocesso &&
                (
                    this.entity.statusprocesso === this.statusprocessoEnum.ENVIADA ||
                    this.entity.statusprocesso === this.statusprocessoEnum.EM_ACOMPANHAMENTO
                )
            )
        );
    }

    public mostrarEncaminhar(): boolean {
        return (
            this.$rootScope.temPermissao('criar_admissao_comp') &&
            this.$rootScope.liberadoTela('LIBERAR_SOL_ADMISSAO_ENCAMINHAR') &&
            this.entity.statusprocesso !== this.statusprocessoEnum.RETOMADA &&
            this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA
        );
    }

    public podeCancelar(): boolean {
        return (
            this.entity.situacao === 0 &&
            this.entity.solicitacao &&
            !(this.entity.situacao > 0) &&
            this.entity.origem === 1
        );
    }
    // fim - controle de botões e campos


    // início - validações
    private validaEntityEnviar(): boolean {
        return (
            this.validaDadosGerais() &&
            this.validaEndereco() &&
            this.validaDadosContato() &&
            this.validaDadosPessoais() &&
            this.validaDocumentacao() &&
            this.validaDocumentosAnexos() &&
            this.validaOutrasInformacoes()
        );
    }

    private validaEncaminhar(): boolean {
        return (
            this.validaMatricula() &&
            this.form.nome.$valid &&
            !!this.entity.email &&
            this.validaDadosVaga() &&
            this.validaDadosEmpresa() &&
            this.validaBolsa() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaDadosGerais(): boolean {
        return (
            this.validaIdentificacao() &&
            this.validaDadosVaga() &&
            this.validaAmbienteTrabalho() &&
            this.validaDadosEmpresa() &&
            this.validaContrato() &&
            this.validaPeriodos() &&
            this.validaBolsa() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaIdentificacao(): boolean {
        const emailValid = this.validaEmail();

        return (
            this.validaMatricula() &&
            this.form.nome.$valid &&
            this.form.cpf.$valid &&
            !!this.entity.datanascimento &&
            emailValid
        );
    }

    private validaMatricula(): boolean {
        return (
            this.matriculaAutomatica ||
            !!this.entity.matricula
        );
    }

    private validaEmail(): boolean {
        return (
            !!this.entity.emailcorporativo ||
            !!this.entity.email
        );
    }

    private validaDadosVaga(): boolean {
        return (
            !!this.entity.vagaAtiva === !!this.entity.vaga &&
            !!this.entity.cargo &&
            !!this.entity.nivelcargo
        );
    }

    private validaAmbienteTrabalho(): boolean {
        return (
            !this.entity.ambiente ||
            !!this.entity.condicaoambientetrabalho
        );
    }

    private validaDadosEmpresa(): boolean {
        return (
            !!this.entity.estabelecimento &&
            !!this.entity.departamento &&
            !!this.entity.lotacao
        );
    }

    private validaContrato(): boolean {
        return (
            !!this.entity.datainiciocontrato &&
            !!this.entity.datafimcontrato
        );
    }

    private validaPeriodos(): boolean {
        return !(
            !(!!this.entity.numerodiasperiodo) ||
            this.entity.numerodiasperiodo > 31 ||
            !(!!this.entity.numerohorasmensais) ||
            !(!!this.entity.quantidademediahorassemanais)
        );
    }

    private validaBolsa(): boolean {
        return (
            !!this.entity.valorbolsa &&
            !!this.entity.unidadebolsa
        );
    }

    private validaOutrasInformacoesGerais(): boolean {
        return (
            !!this.entity.categoriatrabalhador &&
            !!this.entity.atividade &&
            !!this.entity.horario
        );
    }

    private validaEndereco(): boolean {
        return (
            !!this.entity.cep &&
            !!this.entity.tipologradouro &&
            !!this.entity.logradouro &&
            !!this.entity.numero &&
            !!this.entity.municipio &&
            !!this.entity.pais
        );
    }

    private validaDadosContato(): boolean {
        return this.validaTelefones();
    }

    private validaTelefones(): boolean {
        return (
            this.validaTelefone() &&
            this.validaCelular()
        );
    }

    private validaTelefone(): boolean {
        return !(!!this.entity.telefone) || this.entity.telefone.length >= 8;
    }

    private validaCelular(): boolean {
        return !(!!this.entity.celular) || this.entity.celular.length >= 8;
    }

    private validaDadosPessoais(): boolean {
        return (
            this.validaNascimento() &&
            this.validaIdentificacaoDadosPessoais()
        );
    }

    private validaNascimento(): boolean {

        let infosValidasPaisEstrangeiro: boolean = false;

        if (this.entity.paisnascimento) {
            if (this.entity.paisnascimento.pais !== '1058') {
                infosValidasPaisEstrangeiro = !!this.entity.datachegadapais && !!this.entity.temporesidenciaestrangeiro && !!this.entity.classificacaoestrangeiro;
            } else if (this.entity.paisnascimento.pais === '1058') {
                infosValidasPaisEstrangeiro = true;
            }
        }

        return (
            !!this.entity.municipionascimento &&
            !!this.entity.paisnascimento &&
            !!this.entity.paisnacionalidade &&
            infosValidasPaisEstrangeiro
        );
    }

    private validaIdentificacaoDadosPessoais(): boolean {

        let validacoesClienteAna = true;

        if (this.clienteAna && !this.entity.semfiliacao) {
            validacoesClienteAna = !!this.entity.nomemae || !!this.entity.nomepai;
        }

        return (
            !!this.entity.sexo &&
            !!this.entity.estadocivil &&
            !!this.entity.raca &&
            !!this.entity.grauinstrucao &&
            validacoesClienteAna
        );
    }

    private validaDocumentacao(): boolean {
        return (
            this.validaDocumentacaoObrigatoria() &&
            this.validaCtps()
        );
    }

    private validaDocumentacaoObrigatoria(): boolean {
        return this.validaNis();
    }

    private validaNis(): boolean {
        if (!!this.entity.nis) {
            var ftap: string = '3298765432';
            var total: number = 0;
            var resto: number = 0;
            var strResto: string = '';
            var numPIS: string = this.entity.nis;

            if (!numPIS) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            }

            for (var i = 0; i <= 9; i++) {
                var resultado: number = parseInt(numPIS.slice(i, i + 1), 10) * parseInt(ftap.slice(i, i + 1), 10);
                total = total + resultado;
            }

            resto = (total % 11);

            if (resto !== 0) {
                resto = 11 - resto;
            }

            if (resto === 10 || resto === 11) {
                strResto = resto + '';
                resto = parseInt(strResto.slice(1, 2), 10);
            }

            if (resto !== parseInt(numPIS.slice(10, 11), 10)) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            } else {
                this.form.nis.$error = { 'nisInvalido': false };
                return true;
            }
        } else {
            this.form.nis.$error = {};
            return true;
        }
    }

    private validaCtps(): boolean {
        return !(!!this.entity.numeroctps && !(!!this.entity.ufctps));
    }

    private validaDocumentosAnexos(): boolean {
        return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return (
                tipodocumentorequerido.fileSelecionado === (
                    tipodocumentorequerido.obrigatorio ||
                    tipodocumentorequerido.fileSelecionado
                )
            );
        });
    }

    private validaOutrasInformacoes(): boolean {
        return (
            this.validaInformacoesGerais() &&
            this.validaFerias() &&
            this.validaRecebimento() &&
            this.validaEstagio()
        );
    }

    private validaInformacoesGerais(): boolean {
        return (
            !!this.entity.datavencimentoatestadomedico &&
            !!this.form.percentualadiantamento.$error &&
            this.validaPercentualAdiantamento(this.entity.percentualadiantamento)
        );
    }

    private validaPercentualAdiantamento(percentual: number): boolean {
        const PERCENTUAL_MIN = 0;
        const PERCENTUAL_MAX = 0.4;

        return percentual >= PERCENTUAL_MIN && percentual <= PERCENTUAL_MAX;
    }

    private validaFerias(): boolean {
        return !!this.entity.inicioperiodoaquisitivoferias;
    }

    private validaRecebimento(): boolean {
        return (
            this.entity.tiporecebimentobolsa &&
            this.validaDadosBancarios() &&
            this.validaDadosPix()
        );
    }

    private validaDadosBancarios(): boolean {
        if (
            !this.entity.semdadosbancarios &&
            (
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_CORRENTE ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_POUPANCA ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_SALARIO ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_AGENCIA_CONTA
            )
        ) {

            let tipoContaPixValida: boolean = true;

            if (this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_AGENCIA_CONTA && !(!!this.entity.tipocontapix)) {
                tipoContaPixValida = false;
            }

            return (
                !!this.entity.banco &&
                (!!this.entity.agencia || !!this.entity.agencianumero) &&
                !!this.entity.numerocontasalario &&
                !!this.entity.numerocontasalariodv &&
                tipoContaPixValida
            );
        }
        return true;
    }

    private validaDadosPix(): boolean {
        if (
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CPF ||
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_EMAIL ||
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CELULAR ||
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CHAVE_ALEATORIA
        ) {
            return !!this.entity.chavepix;
        }
        return true;
    }

    private validaEstagio(): boolean {
        return (
            !!this.entity.nivelestagio &&
            !!this.entity.instituicaoensino
        );
    }
    // fim - validações


    // início - observers
    private onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_submitted', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão foi enviada com sucesso.'
                });

                this.constructors.solicitacao = args.entity.solicitacao;
                this.entity.situacao = 0;
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message
                });
            } finally {
                this.busy = false;
                this.$state.go('meurh_admissaoestagiario_show', { 'solicitacao': args.entity.solicitacao });
            }
        });
    }

    private onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_submit_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                let mensagemErro = '';
                if (!!args.response && !!args.response.data) {
                    if (!!args.response.data.errors && !!args.response.data.errors.children) {
                        args.response.data.errors.children.forEach((element: any) => {
                            mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                        });
                    } else if (!!args.response.data.message) {
                        mensagemErro = '&bull; ' + args.response.data.message + '<br>';
                    }

                }

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao enviar a admissão',
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml'
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro',
                    body: message
                });
            } finally {
                this.busy = false;
            }
        });
    }

    private onExcluirSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_deleted', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão foi excluída com sucesso.'
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro',
                    body: message
                });
            } finally {
                this.busy = false;
                this.$state.go('meurh_admissaoestagiario', {});
            }
        });
    }

    private onExcluirError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_delete_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                if (typeof (args.response.data.message) !== 'undefined' && !!args.response.data.message) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao excluir a admissão',
                        body: args.response.data.message
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao excluir a admissão'
                    });
                }
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro',
                    body: message
                });
            } finally {
                this.busy = false;
            }
        });
    }

    private onTiposDocumentosListFinished(): void {
        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                tipodocumentorequerido.file = '';
                return tipodocumentorequerido;
            });
        });
    }

    // fim - observers


    // inicio - tratamentos de dados
    private alteraEntityInicio(): void {
        // dados gerais
        this.entity.unidadebolsa = this.entity.unidadebolsa
            ? this.entity.unidadebolsa.toString()
            : this.entity.unidadebolsa;
        this.entity.atividade = this.entity.atividade
            ? this.entity.atividade.toString()
            : this.entity.atividade;

        // contrato
        this.entity.tipoatividade = this.entity.tipoatividade
            ? this.entity.tipoatividade.toString()
            : this.entity.tipoatividade;
        this.entity.tiporegimetrabalhista = this.entity.tiporegimetrabalhista
            ? this.entity.tiporegimetrabalhista.toString()
            : this.entity.tiporegimetrabalhista;

        // dados pessoais
        this.entity.sexo = this.entity.sexo
            ? this.entity.sexo.toString()
            : this.entity.sexo;
        this.entity.estadocivil = this.entity.estadocivil
            ? this.entity.estadocivil.toString()
            : this.entity.estadocivil;
        this.entity.grauinstrucao = this.entity.grauinstrucao
            ? this.entity.grauinstrucao.grauinstrucao
            : null;

        // documentacao
        this.entity.categoriacnh = this.entity.categoriacnh
            ? this.entity.categoriacnh.toString()
            : this.entity.categoriacnh;

        // outras informacoes
        this.entity.agencianumero = this.entity.agencia ? this.entity.agencia.agencianumero : this.entity.agencianumero;
        this.entity.tiporecebimentobolsa = (
            this.entity.tiporecebimentobolsa !== undefined &&
            this.entity.tiporecebimentobolsa !== null
        )
            ? this.entity.tiporecebimentobolsa.toString()
            : this.entity.tiporecebimentobolsa;

        this.entity.tipocontapix = this.entity.tiporecebimentobolsa === '64' && this.entity.tipocontapix ? this.entity.tipocontapix.toString() : null;

        this.entity.nivelestagio = this.entity.nivelestagio
            ? this.entity.nivelestagio.toString()
            : this.entity.nivelestagio;

        this.entity.percentualadiantamento /= 100;

        this.entity.temporesidenciaestrangeiro = !!this.entity.temporesidenciaestrangeiro ? this.entity.temporesidenciaestrangeiro.toString() : this.entity.temporesidenciaestrangeiro;
        this.entity.classificacaoestrangeiro = !!this.entity.classificacaoestrangeiro ? this.entity.classificacaoestrangeiro.toString() : this.entity.classificacaoestrangeiro;

        // dependentes
        if (this.entity.dependentes) {
            this.entity.dependentes.forEach((element: any) => {
                element.percentualpensaoalimenticia /= 100;
                element.percentualpensaoalimenticiafgts /= 100;
            });

            this.entitySave.dependentes = JSON.stringify(this.entitySave.dependentes);
        }
    }

    private alteraEntityEnviar(): void {
        this.entitySave = angular.copy(this.entity);
        this.entitySave.situacao = 0;

        // dados gerais
        this.entitySave.vaga = this.entitySave.vaga
            ? this.entitySave.vaga.vaga
            : null;
        this.entitySave.estabelecimento = this.entitySave.estabelecimento
            ? this.entitySave.estabelecimento.estabelecimento
            : null;
        this.entitySave.nivelcargo = this.entitySave.nivelcargo
            ? this.entitySave.nivelcargo.nivelcargo
            : null;
        this.entitySave.departamento = this.entitySave.departamento
            ? this.entitySave.departamento.departamento
            : null;
        this.entitySave.lotacao = this.entitySave.lotacao
            ? this.entitySave.lotacao.lotacao
            : null;
        this.entitySave.ambiente = !!this.entitySave.ambiente
            ? this.entitySave.ambiente.ambiente
            : null;
        this.entitySave.condicaoambientetrabalho = this.entitySave.condicaoambientetrabalho
            ? this.entitySave.condicaoambientetrabalho.condicaoambientetrabalho
            : null;
        this.entitySave.grupodeponto = this.entitySave.grupodeponto
            ? this.entitySave.grupodeponto.grupo
            : null;
        this.entitySave.horario = this.entitySave.horario
            ? this.entitySave.horario.horario
            : null;
        this.entitySave.categoriatrabalhador = this.entitySave.categoriatrabalhador
            ? this.entitySave.categoriatrabalhador.categoriatrabalhador
            : null;

        this.entitySave.gestores = [];
        if (this.entity.gestores) {
            this.entity.gestores.map((gestor: IGestoresTrabalhador) => {
                if (gestor.gestor) {
                    this.entitySave.gestores?.push({ 'gestor': gestor.gestor });
                } else {
                    this.entitySave.gestores?.push({ 'identificacaonasajongestor': gestor.email });
                }
            });
        }
        this.entitySave.gestores = JSON.stringify(this.entitySave.gestores);

        // endereço
        this.entitySave.tipologradouro = this.entitySave.tipologradouro
            ? this.entitySave.tipologradouro.tipologradouro
            : null;
        this.entitySave.municipio = this.entitySave.municipio
            ? this.entitySave.municipio.ibge
            : null;
        this.entitySave.pais = this.entitySave.pais
            ? this.entitySave.pais.pais
            : null;

        // dados pessoais
        this.entitySave.municipionascimento = this.entitySave.municipionascimento
            ? this.entitySave.municipionascimento.ibge
            : null;
        this.entitySave.paisnascimento = this.entitySave.paisnascimento
            ? this.entitySave.paisnascimento.pais
            : null;
        this.entitySave.paisnacionalidade = this.entitySave.paisnacionalidade
            ? this.entitySave.paisnacionalidade.pais
            : null;
        this.entitySave.raca = this.entitySave.raca
            ? this.entitySave.raca.raca
            : null;
        this.entitySave.instituicaoensino = this.entitySave.instituicaoensino
            ? this.entitySave.instituicaoensino.instituicao
            : null;
        this.entitySave.instituicaointegradora = this.entitySave.instituicaointegradora
            ? this.entitySave.instituicaointegradora.instituicao
            : null;

        // outras informações
        this.entitySave.agencia = null;
        if (
            this.entitySave.tiporecebimentobolsa === undefined ||
            this.entitySave.tiporecebimentobolsa === null
        ) {
            delete this.entitySave.tiporecebimentobolsa;
            delete this.entitySave.tipocontapix;
        } else if (this.entitySave.tiporecebimentobolsa === '64') {
            this.entitySave.tipocontapix = parseInt(this.entitySave.tipocontapix, 10);
        }

        if (this.entitySave.banco) {
            this.entitySave.banco = this.entitySave.banco.banco
                ? this.entitySave.banco.banco
                : null;
        }

        this.entitySave.percentualadiantamento *= 100;

        // benefícios
        if (this.entitySave.beneficios) {
            this.entitySave.beneficios.forEach((element: any) => {
                element.lotacao = angular.copy(element.lotacao.lotacao);
                element.instituicao = element.instituicao
                    ? angular.copy(element.instituicao.instituicao)
                    : element.instituicao;

                if (element.usobeneficio === 2) {
                    element.cpfdependente = element.dependenteSelecionado.cpf;
                }

                if (element.usobeneficio === 1 && element.cpfdependente != null) {
                    element.cpfdependente = element.dependente = null;
                }

            });

            this.entitySave.beneficios = JSON.stringify(this.entitySave.beneficios);
        }

        // dependentes
        if (this.entitySave.dependentes) {
            this.entitySave.dependentes.forEach((element: any) => {
                element.datainclusao = element.datainclusao
                    ? element.datainclusao
                    : null;
                element.databaixaimpostorenda = element.databaixaimpostorenda
                    ? element.databaixaimpostorenda
                    : null;
                element.vencimentoescolar = element.vencimentoescolar
                    ? element.vencimentoescolar
                    : null;
                element.vencimentovacinacao = element.vencimentovacinacao
                    ? element.vencimentovacinacao
                    : null;
                element.agencia = element.agencia
                    ? angular.copy(element.agencia.agencia)
                    : element.agencia;

                element.percentualpensaoalimenticia *= 100;
                element.percentualpensaoalimenticiafgts *= 100;
            });

            this.entitySave.dependentes = JSON.stringify(this.entitySave.dependentes);
        }

        // vale de transporte
        if (this.entitySave.vts) {
            this.entitySave.vts.forEach((element: any) => {
                element.tarifaconcessionariavt = angular.copy(element.tarifaconcessionariavt.tarifaconcessionariavt);
            });

            this.entitySave.vts = JSON.stringify(this.entitySave.vts);
        }

        this.entitySave.temporesidenciaestrangeiro = !!this.entitySave.temporesidenciaestrangeiro ? parseInt(this.entitySave.temporesidenciaestrangeiro, 10) : null;
        this.entitySave.classificacaoestrangeiro = !!this.entitySave.classificacaoestrangeiro ? this.entitySave.classificacaoestrangeiro : null;
    }

    private getMensagensModal(tipoMensagem: 'enviar' | 'enviar' | 'encaminhar' | 'retomar' | 'descartar' | 'excluir' | 'cancelar'): object {
        const msgAlerta = this.entity.statusprocesso === 0
            ? 'Depois de enviada a admissão, não será possível editá-la.'
            : 'Depois de enviada a admissão, não será possível editá-la e nem o futuro colaborador concluir o informe dos dados.';

        const customTexts = {
            enviar: {
                title: 'Enviar admissão',
                subtitle: msgAlerta,
                confirmText: 'Você tem certeza que deseja enviar a admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar',
            },
            descartar: {
                title: 'Descartar edição',
                subtitle: 'Todos os dados informados serão perdidos.',
                confirmText: 'Você tem certeza que deseja descartar as alterações feitas na admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, descartar',
            },
            excluir: {
                title: 'Excluir admissão',
                subtitle: 'Todas as informações preenchidas serão perdidas.',
                confirmText: 'Você tem certeza que deseja excluir a admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, excluir',
            },
            cancelar: {
                title: 'Cancelar admissão',
                subtitle: 'Você está prestes a cancelar a admissão do(a) estagiário(a).',
                confirmText: 'Após o cancelamento, não será possível desfazer. Deseja continuar?',
                confirmTextNum: this.entity.nome,
                closeButton: 'Voltar',
                confirmButton: 'Cancelar admissão',
            },
            encaminhar: {
                title: 'Encaminhar admissão',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você encaminhar a admissão, os campos que o futuro colaborador pode preencher através do aplicativo ficarão bloqueados para edição até o momento que o mesmo termine de preencher os dados, ou até que seja necessário retomar a admissão.'
                },
                confirmText: 'Você tem certeza que deseja encaminhar a admissão para ' + this.entity.nome + ' informar os dados pelo aplicativo?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, encaminhar'
            },
            retomar: {
                title: 'Retomar admissão',
                alertInfo: {
                    header: 'Sobre a retomada da admissão',
                    text: 'Após você retomar a admissão, não será mais possível encaminhá-la novamente.'
                },
                confirmText: 'Caso você retome a admissão, ' + this.entity.nome + ' não poderá mais preencher seus próprios dados pelo aplicativo. Você tem certeza que deseja retomar a admissão?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, retomar',
            },
        };

        return customTexts[tipoMensagem];
    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': TipoSolicitacaoEnum.ADMISSOESESTAGIARIO };
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }
    // fim - tratamentos de dados
}
