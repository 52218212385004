import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { TipoHistoricoEnum } from '../Enums/tipohistorico.enum';
import { MeurhAcompanhamentoService } from './../acompanhamento.service';
import { ISolicitacaoAcompanhamento } from '../models/solicitacaoacompanhamento.model';
import { IItensSolicitacao } from '../models/itenssolicitacao.model';
import { IAcompanhamento, ICamposAlterados } from '../models/acompanhamento.model';
import { ITimeline } from '../models/timeline.model';
import { isNumber } from 'angular';
import { MeurhAcompanhamentoModalService } from '../components/modal/acompanhamentomodal.service';

export class MeurhAcompanhamentoListController implements ng.IController {
	static $inject = [
		'$scope',
		'$state',
		'MeurhAcompanhamentoService',
		'MeurhAcompanhamentoModalService',
		'$filter'
	];

	public entityType: string;
	public nomeTrabalhador: string;
	public form: angular.IFormController;
	public criadoautomaticamente: boolean;
	public retificacao?: {
		camposretificados: string[];
		situacao: number;
		solicitacaooriginal: string | null;
		solicitacaoretificadora: string | null
	};

	public carregouLista: boolean = false;
	public arrayTimeline: Array<ITimeline> = [];
	public entities: Array<IAcompanhamento> = [];

	private tipoHistoricoEnum = TipoHistoricoEnum;

	private solicitacaoPersonalizada: boolean = false;

	constructor(
		private $scope: angular.IScope,
		private $state: angular.ui.IStateService,
		private MeurhAcompanhamentoService: MeurhAcompanhamentoService,
		private MeurhAcompanhamentoModalService: MeurhAcompanhamentoModalService,
		private $filter: any
	) {
		this.onCtrlEntity();

		this.$scope.$on('meurh_solicitacoeshistoricos_list_finished', (event: angular.IAngularEvent, args: any): void => {
			this.entities = args;
			this.arrayTimeline = this.montaArrayTimeline(this.entities);
			this.carregouLista = this.arrayTimeline.length > 0 ? true : false;
		});
	}

	public $onInit(): void {
		this.solicitacaoPersonalizada = !!this.entityType;

		if (!this.entityType) {
			this.entityType = 'solicitação';
		}
	}

	public isBusy(): boolean {
		return this.MeurhAcompanhamentoService.loadParams.busy;
	}

	public onCtrlEntity(): void {
		this.$scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
			if ((newValue !== oldValue) && this.form) {
				this.form.$setDirty();
			}
		}, true);
	}

	public abrirModalDescricaoCorrecao(titulo: string, descricaoCorrecao: string): void {
		const customMessage = {
			titulo: titulo,
			descricao: descricaoCorrecao
		};

		const modal = this.MeurhAcompanhamentoModalService.open(customMessage);

		modal.result
			.then((): void => {/**/ })
			.catch((): void => {/**/ });
	}

	private montaArrayTimeline(entities: Array<IAcompanhamento>): Array<ITimeline> {
		const timeline: Array<ITimeline> = [];

		for (let entity of entities) {
			timeline.push({
				id: entity.solicitacaohistorico,
				tipo: '',
				data: moment(entity.created_at).format('DD/MM/YYYY'),
				hora: moment(entity.created_at).format('HH:mm'),
				tipoIcone: this.getIcone(entity),
				historicoAlteracoes: this.verificaDiferenca(entity)
			});

		}

		return timeline;
	}

	private verificaDiferenca(entity: IAcompanhamento): Array<ISolicitacaoAcompanhamento> {
		let solicitacaoAcompanhamento: Array<ISolicitacaoAcompanhamento> = [];
		let solicitacao: ISolicitacaoAcompanhamento = { listTitleHtml: '', descricaoHtml: '', listItem: [] };

		if (this.retificacao?.solicitacaooriginal) {
			solicitacao['descricaoHtml'] = this.getMensagemRefificacao(entity);
			solicitacaoAcompanhamento.push(solicitacao);

			return solicitacaoAcompanhamento;
		}

		const ACOES_ANEXO = ['Adicionou', 'Removeu'];

		if (entity.valornovo && typeof (entity.valornovo) === 'string') {
			entity.valornovo = entity.valornovo.length > 0
				? JSON.parse(entity.valornovo)
				: entity.valornovo;
		}

		if (entity.valorantigo && typeof (entity.valorantigo) === 'string') {
			entity.valorantigo = entity.valorantigo.length > 0
				? JSON.parse(entity.valorantigo)
				: entity.valorantigo;
		}

		if ((entity.tipohistorico === this.tipoHistoricoEnum.EDIÇÃO || entity.tipohistorico === this.tipoHistoricoEnum.FECHAMENTO) &&
			typeof (entity.valorantigo) !== 'string' && entity.valorantigo?.campos && !this.solicitacaoPersonalizada) {
			entity.valorantigo.campos.forEach((item: any, index: number): void => {
				let itensSolicitacao: IItensSolicitacao = { html: '' };

				if (item.nome && typeof (entity.valornovo) !== 'string' && entity.valornovo?.campos) {
					const valorNovo = entity.valornovo?.campos.find((valor: any): string => {
						return valor.nome = item.nome;
					});

					if (item.valor !== null && valorNovo && valorNovo.valor !== null && index === 0) {
						solicitacao['descricaoHtml'] = this.getMensagem(this.tipoHistoricoEnum.EDIÇÃO, entity);
					}

					if (index === 0) {
						solicitacao['listTitleHtml'] = `Em <b>Dados Gerais</b>`;
					}

					if (item.valor === null) {
						itensSolicitacao.html = `<b>Adicionou</b> ${entity.valornovo.campos[index].label} <i>"${entity.valornovo.campos[index].valor}"</i>.`;

						solicitacao.listItem?.push(itensSolicitacao);

					} else if (item.valor !== null && valorNovo && valorNovo.nome !== null && typeof (entity.valorantigo) !== 'string' && entity.valorantigo?.campos) {
						let valorAntigo = entity.valorantigo?.campos[index].valor;
						let valorNovo = entity.valornovo.campos[index].valor;

						let valores = this.trataValores(item.nome, valorAntigo, valorNovo);

						if (valores.length > 0) {
							entity.valorantigo.campos[index].valor = valores[0];
							entity.valornovo.campos[index].valor = valores[1];
						}

						itensSolicitacao.html = `<b>Alterou</b> ${entity.valornovo.campos[index].label} de <i class="valor">"${entity.valorantigo.campos[index].valor}"</i> para <i class="valor">"${entity.valornovo.campos[index].valor}"</i>.`;

						solicitacao.listItem?.push(itensSolicitacao);

					} else if (item.valor !== null && valorNovo && valorNovo.nome === null && typeof (entity.valorantigo) !== 'string' && entity.valorantigo?.campos) {
						itensSolicitacao.html = `<b>Removeu</b> ${entity.valorantigo?.campos[0].label} <i>"${entity.valorantigo.campos[0].valor}"</i>.`;

						solicitacao.listItem?.push(itensSolicitacao);
					}

					solicitacaoAcompanhamento.push(solicitacao);
				}
			});

			if (entity.anexos && Object.keys(entity.anexos).length !== 0) {
				solicitacao.listTitleHtml = `Em <b>Anexos</b>`;

				JSON.parse(entity.anexos).anexos.forEach((anexo: any): void => {
					let itensSolicitacao: IItensSolicitacao = { html: '' };
					itensSolicitacao.html = `<li><b>${ACOES_ANEXO[anexo.acao]}</b> <i>"${anexo.nome}"</i></li>`;

					solicitacao.listItem?.push(itensSolicitacao);
				});

				solicitacaoAcompanhamento.push(solicitacao);
			}
		} else if (entity.tipohistorico === this.tipoHistoricoEnum.CRIACAO) {
			solicitacao.descricaoHtml = this.getMensagem(this.tipoHistoricoEnum.CRIACAO, entity);

			solicitacaoAcompanhamento.push(solicitacao);
		} else if (entity.tipohistorico === this.tipoHistoricoEnum.ENVIO) {
			solicitacao.descricaoHtml = this.getMensagem(this.tipoHistoricoEnum.ENVIO, entity);

			solicitacaoAcompanhamento.push(solicitacao);
		} else if (entity.tipohistorico === this.tipoHistoricoEnum.APROVADO) {
			solicitacao['descricaoHtml'] = this.getMensagem(this.tipoHistoricoEnum.APROVADO, entity);
			solicitacaoAcompanhamento.push(solicitacao);
		} else if (entity.tipohistorico === this.tipoHistoricoEnum.FECHAMENTO) {
			solicitacao['descricaoHtml'] = this.getMensagem(this.tipoHistoricoEnum.FECHAMENTO, entity);
			solicitacaoAcompanhamento.push(solicitacao);
		} else if (entity.tipohistorico === this.tipoHistoricoEnum.EXCLUSÃO) {
			solicitacao['descricaoHtml'] = this.getMensagem(this.tipoHistoricoEnum.EXCLUSÃO, entity);
			solicitacaoAcompanhamento.push(solicitacao);
		} else if (entity.tipohistorico === this.tipoHistoricoEnum.REABERTURA) {
			solicitacao['descricaoHtml'] = this.getMensagem(this.tipoHistoricoEnum.REABERTURA, entity);
			solicitacaoAcompanhamento.push(solicitacao);
		} else if (entity.tipohistorico === this.tipoHistoricoEnum.EDIÇÃO) {
			solicitacao.listTitleHtml = 'Em <b>Dados gerais</b>';
			solicitacao.listItem = this.getMensagemEdicaoPersonalizada(entity);
			if (entity.anexos && Object.keys(entity.anexos).length !== 0) {
				JSON.parse(entity.anexos).anexos.forEach((anexo: any) => {
					solicitacao.listItem?.push({ html: `<li><b>${ACOES_ANEXO[anexo.acao]}</b> <i class='valor'>"${anexo.nome}"</i></li>` });
				});
			}
			solicitacaoAcompanhamento.push(solicitacao);
		} else if (entity.tipohistorico === this.tipoHistoricoEnum.OUTROS) {
			solicitacao['descricaoHtml'] = entity.descricao || '';
			solicitacaoAcompanhamento.push(solicitacao);
		} else if (entity.tipohistorico === this.tipoHistoricoEnum.WORKFLOW) {
			solicitacao['descricaoHtml'] = this.getMensagem(this.tipoHistoricoEnum.WORKFLOW, entity);
			solicitacaoAcompanhamento.push(solicitacao);
		} else if (entity.tipohistorico === this.tipoHistoricoEnum.CRIACAO_RETIFICACAO) {
			solicitacao['descricaoHtml'] = this.getMensagem(this.tipoHistoricoEnum.CRIACAO_RETIFICACAO, entity);
			solicitacaoAcompanhamento.push(solicitacao);
		}

		return solicitacaoAcompanhamento;
	}

	private trataValores(nomeCampo: string, valorAntigo: any, valorNovo: any): Array<string | number> {
		valorAntigo = !isNumber(valorAntigo) ? parseInt(valorAntigo, 10) : valorAntigo;
		valorNovo = !isNumber(valorNovo) ? parseInt(valorNovo, 10) : valorNovo;

		if (isNaN(valorAntigo) || isNaN(valorNovo)) {
			return [];
		}

		if (nomeCampo === 'situacao') {
			return [
				this.$filter('situacaoSolicitacaoEnum')(valorAntigo),
				this.$filter('situacaoSolicitacaoEnum')(valorNovo)
			];
		}

		if (nomeCampo === 'tipopromocao') {
			return [
				this.$filter('solicitacaoTipoPromocaoEnum')(valorAntigo),
				this.$filter('solicitacaoTipoPromocaoEnum')(valorNovo)
			];
		}

		if (nomeCampo === 'salario') {
			return [
				this.$filter('currency')(valorAntigo),
				this.$filter('currency')(valorNovo)
			];
		}

		if (nomeCampo === 'percentual') {
			return [
				this.$filter('number')(valorAntigo, 2) + '%',
				this.$filter('number')(valorNovo, 2) + '%'
			];
		}

		return [valorAntigo, valorNovo];
	}

	private getIcone(entity: IAcompanhamento): string {
		if (this.retificacao) {
			return this.getIconesRetificacao(entity);
		}

		if (this.solicitacaoPersonalizada) {
			return this.getIconePersonalizado(entity.tipohistorico, entity.tostatetitle!);
		}

		return this.getIconeGenerico(entity.tipohistorico);
	}

	private getIconeGenerico(tipoHistorico: number): string {
		if (tipoHistorico === this.tipoHistoricoEnum.CRIACAO) {
			return 'fas fa-check';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.ENVIO) {
			return 'fas fa-file';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.APROVADO) {
			return 'fas fa-user-check';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.FECHAMENTO) {
			return 'fas fa-lock';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.EXCLUSÃO) {
			return 'fas fa-trash-alt';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.REABERTURA) {
			return 'fas fa-times';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.EDIÇÃO) {
			return 'fas fa-user-edit';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.WORKFLOW) {
			return 'fas fa-user-edit';
		}

		return 'fas fa-edit';
	}

	private getIconePersonalizado(tipoHistorico: number, tostatetitle?: string): string {
		if (tipoHistorico === this.tipoHistoricoEnum.CRIACAO) {
			return 'fas fa-file';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.ENVIO) {
			return 'fas fa-paper-plane';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.APROVADO) {
			return 'fas fa-user-check';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.FECHAMENTO) {
			return 'fas fa-file';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.EXCLUSÃO) {
			return 'fas fa-trash-alt';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.REABERTURA) {
			return 'fas fa-times';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.EDIÇÃO) {
			return 'fas fa-edit';
		}

		if (tipoHistorico === this.tipoHistoricoEnum.WORKFLOW) {
			return this.getIconePersonalizadoWorkflow(tostatetitle!);
		}

		return 'fas fa-edit';
	}

	private getIconePersonalizadoWorkflow(tostatetitle: string): string {
		if (tostatetitle === 'Aguardando correção do cliente') {
			return 'fas fa-tools';
		}

		if (tostatetitle === 'Aguardando prévias') {
			return 'fas fa-pause';
		}

		if (tostatetitle === 'Aguardando confirmação') {
			return 'fas fa-pause';
		}

		if (tostatetitle === 'Confirmada') {
			return 'fas fa-check';
		}

		if (tostatetitle === 'Efetuada') {
			return 'fas fa-user-check';
		}

		if (tostatetitle === 'Prorrogação de contrato solicitada') {
			return 'fas fa-ban';
		}

		return 'fas fa-tools';
	}

	private getIconesRetificacao(entity: IAcompanhamento): string {
		if (entity.tipohistorico === this.tipoHistoricoEnum.ENVIO) {
			return 'fas fa-paper-plane';
		}

		if (entity.tipohistorico === this.tipoHistoricoEnum.APROVADO) {
			return 'fas fa-user-check';
		}

		return 'fas fa-edit';
	}

	private getMensagem(tipoHistorico: number, historico: IAcompanhamento): string {
		return this.solicitacaoPersonalizada
			? this.getMensagemPersonalizada(tipoHistorico, historico)
			: this.getMensagemGenerica(tipoHistorico, historico.created_by?.nome, historico.fromstatetitle || '', historico.tostatetitle || '');
	}

	private getMensagemGenerica(tipoHistorico: number, nome: string = '', fromstatetitle: string, tostatetitle: string): string {
		const textoNome: string = (
			nome === ''
				? '.'
				: ` por <b class="colorButtonTertiaryAction">${nome}</b>.`
		);

		if (tipoHistorico === this.tipoHistoricoEnum.CRIACAO) {
			return `A solicitação foi criada${textoNome}`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.ENVIO) {
			return `A solicitação foi enviada${textoNome}`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.APROVADO) {
			return `A solicitação foi efetuada${textoNome}`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.FECHAMENTO) {
			return `A solicitação foi cancelada${textoNome}`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.EXCLUSÃO) {
			return `A solicitação foi excluída${textoNome}`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.REABERTURA) {
			return `A solicitação foi recusada${textoNome}`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.EDIÇÃO) {
			return `A solicitação foi editada${textoNome}`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.WORKFLOW) {
			return `A solicitação foi de "${fromstatetitle}" para "${tostatetitle}" ${textoNome}`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.CRIACAO_RETIFICACAO) {
			return `${textoNome} fez uma solicitação de retificação. Para acessar a retificação, <div class="url-bold" onclick="angular.element(this).scope().mrh_cmpnhmnt_lst.irParaRetificadora()">clique aqui</div>`;
		}

		return '';
	}

	private getMensagemPersonalizada(tipoHistorico: number, historico: IAcompanhamento): string {
		const nomePersonalizado = `<b class='txt-color-1'>${historico.created_by.nome}</b>`;
		const nomeTrabalhadorPersonalizado = `<b class='txt-color-1'>${this.nomeTrabalhador}</b>`;

		if (tipoHistorico === this.tipoHistoricoEnum.CRIACAO) {
			return this.criadoautomaticamente ?
				`O término do contrato foi gerado automaticamente.` :
				`A ${this.entityType} de ${nomeTrabalhadorPersonalizado} foi criada por ${nomePersonalizado}. <br>É possível que outros usuários consigam visualizar e realizar ações na ${this.entityType} aberta.`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.ENVIO) {
			if (this.criadoautomaticamente) {
				return historico.descricao || '';
			}

			if (historico.solicitacaoadmissaopreliminar) {
				return `A <b>admissão preliminar</b> foi enviada por ${nomePersonalizado}.`;
			}

			if (this.entityType === 'admissão') {
				return `A admissão completa foi enviada por ${nomePersonalizado}`;
			} else if (this.entityType === 'admissão de estagiário') {
				return `A ${this.entityType} foi enviada por ${nomePersonalizado}`;
			}

			if (this.entityType === 'alteração de dados salariais') {
				return `${nomePersonalizado} enviou a solicitação de ${this.entityType}`;
			}

			return `A ${this.entityType} foi enviada por ${nomePersonalizado}.`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.APROVADO) {
			if (!!this.criadoautomaticamente) {
				return historico.descricao || '';
			}

			let descricao = `${nomePersonalizado} efetuou a solicitação`;

			if (this.entityType === 'rescisão') {
				descricao += ' Você possui um prazo máximo de 10 dias para realizar o pagamento da rescisão.';
			}

			return descricao;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.FECHAMENTO) {
			if (!!this.criadoautomaticamente) {
				return historico.descricao || '';
			}

			if (historico.solicitacaoadmissaopreliminar) {
				return `A admissão preliminar de ${nomeTrabalhadorPersonalizado} foi cancelada por ${nomePersonalizado}`;
			}

			if (this.entityType === 'admissão') {
				return `A admissão completa de ${nomeTrabalhadorPersonalizado} foi cancelada por ${nomePersonalizado}`;
			} else if (this.entityType === 'admissão de estagiário') {
				return `A ${this.entityType} de ${nomeTrabalhadorPersonalizado} foi cancelada por ${nomePersonalizado}`;
			}

			return `A ${this.entityType} foi cancelada.`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.EXCLUSÃO) {
			return `${nomePersonalizado} excluiu a ${this.entityType}.`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.REABERTURA) {
			return `${nomePersonalizado} recusou a ${this.entityType}.`;
		}

		if (tipoHistorico === this.tipoHistoricoEnum.WORKFLOW) {
			return historico.created_by.nome && historico.created_by.nome !== ''
				? this.getMensagemPersonalizadaWorkflow(nomePersonalizado, historico.tostatetitle!, historico.logcomment!)
				: this.getMensagemPersonalizadaWorkflow('', historico.tostatetitle!, historico.logcomment!);
		}

		if (tipoHistorico === this.tipoHistoricoEnum.CRIACAO_RETIFICACAO) {
			return `${nomePersonalizado} fez uma solicitação de retificação. Para acessar a retificação, <div class="url-bold" onclick="angular.element(this).scope().mrh_cmpnhmnt_lst.irParaRetificadora()">clique aqui</div>`;
		}

		return '';
	}

	private getMensagemPersonalizadaWorkflow(nome: string, tostatetitle: string, descricaoCorrecao: string): string {
		const descricaoLink = (type: string): string => {
			return !!descricaoCorrecao
				? `
					<div
						class="url-bold"
						onmouseover="this.style.textDecoration='underline'"
						onmouseout="this.style.textDecoration='none'"
						onclick="angular.element(this).scope().mrh_cmpnhmnt_lst.abrirModalDescricaoCorrecao('Descrição ${type}', '${descricaoCorrecao}')"
					>
						Ver a descrição ${type}
					</div>
				`
				: '';
		};

		if (tostatetitle === 'Aguardando correção do cliente') {
			return `${nome} solicitou a correção da ${this.entityType}. ${descricaoLink('da correção')}`;
		}

		if (tostatetitle === 'Aguardando prévias') {
			return `A ${this.entityType} está aguardando as prévias. ${descricaoLink('da conclusão das prévias')}`;
		}

		if (tostatetitle === 'Aguardando confirmação') {
			return `A ${this.entityType} está aguardando a confirmação das prévias. ${descricaoLink('do pedido de confirmação')}`;
		}

		if (tostatetitle === 'Confirmada') {

			if (nome === '') {
				return `A ${this.entityType} foi confirmada. ${descricaoLink('da confirmação')}`;
			} else {
				return `${nome} confirmou a ${this.entityType}. ${descricaoLink('da confirmação')}`;
			}

		}

		if (tostatetitle === 'Aguardando correção ANA') {
			return `A solicitação de correção foi enviada e está aguardando correção pela ANA. ${descricaoLink('da correção')}`;
		}

		if (tostatetitle === 'Cancelamento solicitado') {

			if (nome === '') {
				return `Foi solicitado o cancelamento da ${this.entityType} que já havia sido efetuada.`;
			} else {
				return `${nome} solicitou o cancelamento da ${this.entityType} que já havia sido efetuada.`;
			}

		}

		if (tostatetitle === 'Aguardando ponto') {
			return `Aguardando confirmação do ponto para continuar a rescisão. ${descricaoLink('da confirmação de ponto')}`;
		}

		if (tostatetitle === 'Aguardando processo') {
			return `Aguardando a conclusão do processo de geração de documentos de demissão. ${descricaoLink('da conclusão do processo')}`;
		}

		if (tostatetitle === 'Efetuada') {
			return descricaoCorrecao || `A solicitação foi efetuada por ${nome}.`;
		}

		if (tostatetitle === 'Prorrogação de contrato solicitada') {

			if (nome !== '') {
				descricaoCorrecao = descricaoCorrecao.replace('Foi solicitado', `${nome} solicitou`);
			}

			return descricaoCorrecao;
		}

		return '';
	}

	private getMensagemRefificacao(entity: IAcompanhamento): string {
		const nomePersonalizado = `<b>${entity.created_by?.nome}</b>`;
		const nomeTrabalhadorPersonalizado = `<b>${this.nomeTrabalhador}</b>`;

		if (entity.tipohistorico === this.tipoHistoricoEnum.RETIFICACAO) {
			return `${nomePersonalizado} criou a solicitação de retificação. Para acessar a admissão original, <span class="url-bold" onclick="angular.element(this).scope().mrh_cmpnhmnt_lst.irParaSolicitacaoOriginal()">clique aqui</span>.`;
		}

		if (entity.tipohistorico === this.tipoHistoricoEnum.ENVIO) {
			return `${nomePersonalizado} enviou a retificação de ${nomeTrabalhadorPersonalizado}`;
		}

		if (entity.tipohistorico === this.tipoHistoricoEnum.APROVADO) {
			return `${nomePersonalizado} efetuou a retificação de ${nomeTrabalhadorPersonalizado}`;
		}

		if (entity.tipohistorico === this.tipoHistoricoEnum.EDIÇÃO) {
			return `${nomePersonalizado} editou a retificação de ${nomeTrabalhadorPersonalizado}`;
		}

		return '';
	}

	private irParaRetificadora(): void {
		this.$state.go('meurh_admissaocompleta_show',
			angular.extend({}, {
				'solicitacao': this.retificacao?.solicitacaoretificadora,
			})
		);
	}

	private irParaSolicitacaoOriginal(): void {
		this.$state.go('meurh_admissaocompleta_show',
			angular.extend({}, {
				'solicitacao': this.retificacao?.solicitacaooriginal,
			})
		);
	}

	private getMensagemEdicaoPersonalizada(historico: IAcompanhamento): IItensSolicitacao[] {
		let listaAlteracoes: IItensSolicitacao[] = [];
		let valorantigo: ICamposAlterados[] = [];
		let valornovo: ICamposAlterados[] = [];

		if (historico.valorantigo && typeof (historico.valorantigo) !== 'string') {
			valorantigo = historico.valorantigo!.campos;
		} else {
			return [];
		}

		if (historico.valornovo && typeof (historico.valornovo) !== 'string') {
			valornovo = historico.valornovo!.campos;
		} else {
			return [];
		}

		valorantigo.forEach((campo: ICamposAlterados, index: number) => {
			if (!campo.valor) {
				listaAlteracoes.push({ html: `<b>Informou</b> ${campo.label} "<i class='valor'>${valornovo[index].valor}</i>"` });
			} else if (!valornovo[index].valor) {
				listaAlteracoes.push({ html: `<b>Removeu</b> ${campo.label} "<i class='valor'>${campo.valor}</i>"` });
			} else {
				listaAlteracoes.push({ html: `<b>Alterou</b> ${campo.label} de "<i class='valor'>${campo.valor}"</i> para "<i class='valor'>${valornovo[index].valor}</i>"` });
			}
		});

		return listaAlteracoes;
	}
}
