
import * as angular from 'angular';
import { MeurhSolicitacoesalteracoesdadossalariaisService } from './solicitacoesalteracoesdadossalariais.service';
import { MeurhAlteracoesDadosSalariaisModalConfirmService } from './modal/modalalteracoesdadossalariais.confirm.service';
import { MeurhSolicitacoesalteracoesdadossalariaisListRouting } from './solicitacoesalteracoesdadossalariais.routes';
import { MeurhSolicitacoesalteracoesdadossalariaisIndexController } from './index/solicitacoesalteracoesdadossalariais.index.controller';
import { MeurhSolicitacoesalteracoesdadossalariaisShowController } from './show/solicitacoesalteracoesdadossalariais.show.controller';
import { MeurhSolicitacoesalteracoesdadossalariaisFormController } from './form/solicitacoesalteracoesdadossalariais.form.controller';
import { MeurhSolicitacoesalteracoesdadossalariaisNewController } from './new/solicitacoesalteracoesdadossalariais.new.controller';
import { MeurhSolicitacoesalteracoesdadossalariaisFormComponent } from './form/solicitacoesalteracoesdadossalariais.form.component';
import { MeurhAlteracoesDadosSalariaisConfirmController } from './modal/modalalteracoesdadossalariais.confirm.controller';

export const MeurhSolicitacoesalteracoesdadossalariaisModule = angular.module('MeurhSolicitacoesalteracoesdadossalariaisModule', ['ui.router.state', ])
    .controller('MeurhSolicitacoesalteracoesdadossalariaisIndexController', MeurhSolicitacoesalteracoesdadossalariaisIndexController)
    .controller('MeurhSolicitacoesalteracoesdadossalariaisShowController', MeurhSolicitacoesalteracoesdadossalariaisShowController)
    .controller('MeurhSolicitacoesalteracoesdadossalariaisFormController', MeurhSolicitacoesalteracoesdadossalariaisFormController)
    .controller('MeurhSolicitacoesalteracoesdadossalariaisNewController', MeurhSolicitacoesalteracoesdadossalariaisNewController)
    .controller('MeurhAlteracoesDadosSalariaisConfirmController', MeurhAlteracoesDadosSalariaisConfirmController)
    .component('meurhSolicitacoesalteracoesdadossalariaisFormComponent', MeurhSolicitacoesalteracoesdadossalariaisFormComponent)
    .service('MeurhSolicitacoesalteracoesdadossalariaisService', MeurhSolicitacoesalteracoesdadossalariaisService)
    .service('MeurhAlteracoesDadosSalariaisModalConfirmService', MeurhAlteracoesDadosSalariaisModalConfirmService)
    .config(MeurhSolicitacoesalteracoesdadossalariaisListRouting)
    .name;
