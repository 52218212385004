declare var nsjGlobals: any;
import angular from 'angular';
import moment from 'moment';
import { ICreditoDesconto } from '../models/creditoDesconto.model';

export class MeurhCreditosdescontosPassoquatroController {

    static $inject = ['$scope', '$state', 'NewToaster', 'MeurhCreditosdescontosService', 'ModalConfirmService', '$q', '$transitions', '$rootScope', ];

    public busy: boolean = false;

    public entitiesCreditosDescontos: Array<Partial<ICreditoDesconto>>;
    public modalEntityCreditosDescontos: Partial<ICreditoDesconto> = {};
    public entity: any; // tipagem obj ITrabalhador

    public passo: number = 3;
    public passoUmAtivo: string;

    public dataFolhaAdiantamentoSalarial?: string;
    public dataFolhaPagamento?: string;
    public dataSelecionada: string;

    public selecionadosCreditosDescontos: Array<Partial<ICreditoDesconto>>;

    public allowRefresh: boolean = false;

    public clienteAna: boolean = nsjGlobals.get('clienteana');
    public competencia = this.$rootScope.nsjGlobals.a.configuracoes.COMPETENCIA_MES_ANO;

    public fields = [
        {
            value: 'evento.nome',
            label: 'Descrição',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'conteudo',
            label: 'Conteúdo',
            type: 'string',
            style: 'default',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <div ng-if="tableRowEntity.evento.evento_unidade === 0 ||
                tableRowEntity.evento.evento_unidade === 8 ||
                tableRowEntity.evento.evento_unidade === 10"
                ng-class="{'table-color-green':tableRowEntity.tipo===0, 'table-color-red':tableRowEntity.tipo === 1}">
                    <span ng-if="tableRowEntity.tipo === 1">- </span>
                    <span ng-bind-template="{{tableRowEntity.conteudo}}"></span>
                </div>

                <div ng-if="tableRowEntity.evento.evento_unidade === 2 ||
                tableRowEntity.evento.evento_unidade === 12"
                ng-class="{'table-color-green':tableRowEntity.tipo===0, 'table-color-red':tableRowEntity.tipo === 1}">
                    <span ng-if="tableRowEntity.tipo === 1">- </span>
                    <span ng-bind-template="{{tableRowEntity.conteudo | currency:'R$ ':2}}"></span>
                </div>

                <div ng-if="tableRowEntity.evento.evento_unidade !== 0 &&
                tableRowEntity.evento.evento_unidade !== 2 &&
                tableRowEntity.evento.evento_unidade !== 8 &&
                tableRowEntity.evento.evento_unidade !== 10 &&
                tableRowEntity.evento.evento_unidade !== 12"
                ng-class="{'table-color-green':tableRowEntity.tipo===0, 'table-color-red':tableRowEntity.tipo === 1}">
                    <span ng-if="tableRowEntity.tipo === 1">- </span>
                    <span ng-bind-template="{{tableRowEntity.conteudo}}"></span>
                </div>
              `;
                return someHtmlVar;
            },
        },
        {
            value: 'tipoperiodo',
            label: 'Período',
            type: 'string',
            style: 'default',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <option ng-if="tableRowEntity.tipoperiodo === '0'">Somente uma vez em ${this.competencia}</option>
                <option ng-if="tableRowEntity.tipoperiodo === '1'">De ${this.competencia} em diante</option>
                <option ng-if="tableRowEntity.tipoperiodo === '2'">De ${this.competencia} a {{tableRowEntity.competenciafinalperiodoCompletaMesAno}}</option>
              `;
                return someHtmlVar;
            },
        },
        {
            value: 'referencia',
            label: 'Referência',
            type: 'string',
            style: 'default',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                    <span ng-if="tableRowEntity.referencia">{{tableRowEntity.referencia}}</span>
                    <span ng-if="!tableRowEntity.referencia">Não informada</span>
                `;
                return someHtmlVar;
            },
        },
        {
            value: 'lotacao.nome',
            label: 'Lotação',
            type: 'string',
            style: 'default',
            copy: '',
        },
    ];

    public arrayEntity: any = []; // tipagem array de obj ITrabalhador
    public fieldsEntity = [
        {
            value: 'nome',
            label: 'Colaborador',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'cargo.nome',
            label: 'Cargo',
            type: 'string',
            style: 'default',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <span>{{tableRowEntity.cargo.nome}} {{tableRowEntity.nivelcargo.codigo}}</span>
              `;
                return someHtmlVar;
            },
        },
    ];

    constructor(
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public NewToaster: any,
        public MeurhCreditosdescontosService: any,
        public ModalConfirmService: any,
        public $q: any,
        public $transitions: any,
        public $rootScope: angular.IRootScopeService & { nsjGlobals: any },
    ) {
        if (this.clienteAna) {
            this.fields.push(
                {
                    value: 'fim',
                    label: 'Fim',
                    type: 'string',
                    style: 'default',
                    copy: '',
                    customCollumnHtml: () => {
                        let someHtmlVar = `<span ng-bind-template="{{tableRowEntity.fim ? 'Sim' : 'Não'}}"></span>`;
                        return someHtmlVar;
                    },
                }
            );
        }
    }

    $onInit() {

        this.arrayEntity[0] = angular.copy(this.entity);

        this.dataFolhaAdiantamentoSalarial = this.selecionadosCreditosDescontos[0].dataFolhaAdiantamentoSalarial;
        this.dataFolhaPagamento = this.selecionadosCreditosDescontos[0].dataFolhaPagamento;
        this.selecionaData();

        this.onSave();

        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    onSave() {
        this.$scope.$on('meurh_solicitacoescreditodesconto_submitted', (event: any, args: any) => {

            var indexSalvo = this.selecionadosCreditosDescontos.indexOf(args.entity);

            this.selecionadosCreditosDescontos.splice(indexSalvo, 1);

            this.entitiesCreditosDescontos.splice(indexSalvo, 1);

            if (this.selecionadosCreditosDescontos.length === 0) {
                this.$state.go('creditosdescontos_index', {});
            }
            if (args.entity.situacao !== -1) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'Solicitação de movimento enviada com sucesso!'
                });
            } else {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'Rascunho de solicitação de movimento criado com sucesso!'
                });
            }
        });

        this.$scope.$on('meurh_solicitacoescreditodesconto_submit_error', (event: any, args: any) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao salvar a solicitação.'
            });
            args.entity.situacao = '';
            this.busy = false;
        });
    }

    isBusy() {
        return this.busy;
    }

    selecionaData() {
        if (this.selecionadosCreditosDescontos[0].tipocalculo === 0) {
            this.dataSelecionada = moment(this.dataFolhaAdiantamentoSalarial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        } else {
            this.dataSelecionada = moment(this.dataFolhaPagamento, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
    }

    salvar(rascunho: boolean = false) {
        this.allowRefresh = false;
        this.busy = true;
        this.selecionadosCreditosDescontos.forEach((element: any) => {
            let eventounidade = element.evento.unidade === undefined ? element.evento.evento_unidade : element.evento.unidade;

            if ((eventounidade === 0 || eventounidade === 8 || eventounidade === 10) && typeof element.conteudo === 'string') {
                element.conteudo = parseFloat(element.conteudo.replace(':', '.'));
            }

            if (element.tipoperiodo === '1') {
                element.competenciafinalperiodoCompleta = '0';
            }

            // to do: ajustar a forma com que essa propriedade é alimentada ao longo do fluxo de informações. Código feito apenas para resolver provisioriamente erro crítico
            if (element.tipoperiodo === '0') {
                element.competenciafinalperiodoCompleta = `01/${this.competencia}`;
            }
            element['situacao'] = rascunho ? -1 : 0;
        });
        this.selecionadosCreditosDescontos.forEach((element: any) => {
            this.MeurhCreditosdescontosService.save(element);
        });
    }

    formInvalido() {
        if (this.selecionadosCreditosDescontos.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    calculaPasso(continua: boolean) {
        if (continua) {
            this.passo = this.passo + 1;
        } else {
            this.passo = this.passo - 1;
            this.allowRefresh = false;
        }
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'creditosdescontos' && this.allowRefresh) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
