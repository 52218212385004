import angular from 'angular';
import { MeurhGestoresTrabalhadoresController } from './gestoresTrabalhadores.controller';

export class MeurhGestoresTrabalhadoresComponent implements angular.IComponentOptions {
	static selector = 'meurhGestoresTrabalhadoresComponent';
	static controller = MeurhGestoresTrabalhadoresController;
	static template = require('!!html-loader!./gestoresTrabalhadores.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		action: '<',
		exibeAdicionar: '<',
		permissoes: '<'
	};
}
