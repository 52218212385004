declare var nsjGlobals: any;
import angular from 'angular';
import moment from 'moment';
import { ICreditoDesconto } from '../models/creditoDesconto.model';
import { IDadosInit } from '../models/dadosInit.model';
import { IEvento } from '../models/evento.model';
import { IMensagensPassos } from '../models/mensagensPassos.model';

export class MeurhCreditosdescontosPassotresController {

    static $inject = ['$rootScope', '$scope', '$stateParams', 'MeurhCreditosdescontosService', 'MeurhCreditosdescontosAdicionarmodalService', 'RubricasPontoOverLoadService', 'MeurhCreditosdescontosIndisponivelmodalService', 'ModalConfirmService', '$q', '$transitions', 'TrabalhadoresService', ];

    public busy: boolean = false;

    public entity: any; // tipagem: obj trabalhador
    public entities: Array<Partial<ICreditoDesconto>>;
    public entitiesTemp: Array<Partial<ICreditoDesconto>> = [];
    public entitiesCreditosDescontos: Array<Partial<ICreditoDesconto>>;
    public modalEntityCreditosDescontos: Partial<ICreditoDesconto> = {};
    public entityDadosCriacao: IDadosInit;

    public passo: number = 3;
    public passoUmAtivo: string;
    public mensagensPassos: IMensagensPassos;

    public listaRubricas: Array<IEvento>;

    public contadorIdentificadorTemporario: number = 0;

    public selecionadosCreditosDescontos: Array<Partial<ICreditoDesconto>>;

    public constructors: any = this.MeurhCreditosdescontosService.constructors = [];
    public entitiesCreditosDescontosALL: Array<Partial<ICreditoDesconto>> = [];

    public allowRefresh: boolean = false;

    public clienteAna: boolean = nsjGlobals.get('clienteana');
    public competencia = this.$rootScope.nsjGlobals.a.configuracoes.COMPETENCIA_MES_ANO;

    public fields = [
        {
            value: 'tipocalculo',
            label: 'Folha',
            type: 'string',
            style: 'default',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <span ng-bind-template="{{tableRowEntity.tipocalculo === 0 ? 'Adiantamento salarial da competência ${this.competencia}' : 'Folha de pagamento da competência ${this.competencia}'}}"></span>
              `;
                return someHtmlVar;
            },
        },
        {
            value: 'evento.nome',
            label: 'Descrição',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'conteudo',
            label: 'Conteúdo',
            type: 'string',
            style: 'default',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <div ng-if="tableRowEntity.evento.evento_unidade === 0 ||
                    tableRowEntity.evento.evento_unidade === 8 ||
                    tableRowEntity.evento.evento_unidade === 10"
                    ng-class="{'table-color-green': tableRowEntity.tipo === 0, 'table-color-red': tableRowEntity.tipo === 1}">
                    <strong>
                        <span ng-if="tableRowEntity.tipo === 1">- </span>
                        <span ng-if="tableRowEntity.tipo !== 1">+ </span>
                        <span ng-bind-template="{{tableRowEntity.conteudo}}"></span>
                    </strong>
                </div>

                <div ng-if="tableRowEntity.evento.evento_unidade === 2 ||
                    tableRowEntity.evento.evento_unidade === 12"
                    ng-class="{'table-color-green': tableRowEntity.tipo === 0, 'table-color-red': tableRowEntity.tipo === 1}">
                    <strong>
                        <span ng-if="tableRowEntity.tipo === 1">- </span>
                        <span ng-if="tableRowEntity.tipo !== 1">+ </span>
                        <span ng-bind-template="{{tableRowEntity.conteudo | currency:'R$ ':2}}"></span>
                    </strong>
                </div>

                <div ng-if="tableRowEntity.evento.evento_unidade !== 0 &&
                    tableRowEntity.evento.evento_unidade !== 2 &&
                    tableRowEntity.evento.evento_unidade !== 8 &&
                    tableRowEntity.evento.evento_unidade !== 10 &&
                    tableRowEntity.evento.evento_unidade !== 12"
                    ng-class="{'table-color-green': tableRowEntity.tipo === 0, 'table-color-red': tableRowEntity.tipo === 1}">
                    <strong>
                        <span ng-if="tableRowEntity.tipo === 1">- </span>
                        <span ng-if="tableRowEntity.tipo !== 1">+ </span>
                        <span ng-bind-template="{{tableRowEntity.conteudo}}"></span>
                    </strong>
                </div>
              `;
                return someHtmlVar;
            },
        },
        {
            value: 'tipoperiodo',
            label: 'Período',
            type: 'string',
            style: 'default',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                    <span ng-if="tableRowEntity.tipoperiodo === '0'">Somente uma vez em ${this.competencia}</span>
                    <span ng-if="tableRowEntity.tipoperiodo === '1'">De ${this.competencia} em diante</span>
                    <span ng-if="tableRowEntity.tipoperiodo === '2'">De ${this.competencia} a {{tableRowEntity.competenciafinalperiodoCompletaMesAno}}</span>
                `;
                return someHtmlVar;
            },
        },
        {
            value: 'referencia',
            label: 'Referência',
            type: 'string',
            style: 'default',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                    <span ng-if="tableRowEntity.referencia">{{tableRowEntity.referencia}}</span>
                    <span ng-if="!tableRowEntity.referencia">Não informada</span>
                `;
                return someHtmlVar;
            },
        },
        {
            value: 'lotacao.nome',
            label: 'Lotação',
            type: 'string',
            style: 'default',
            copy: '',
        },
    ];

    public colaboradorSelecionado: any;

    constructor(
        public $rootScope: angular.IRootScopeService & { nsjGlobals: any },
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public MeurhCreditosdescontosService: any,
        public MeurhCreditosdescontosAdicionarmodalService: any,
        public RubricasPontoOverLoadService: any,
        public MeurhCreditosdescontosIndisponivelmodalService: any,
        public ModalConfirmService: any,
        public $q: any,
        public $transitions: any,
        public TrabalhadoresService: any,
    ) {

        $scope.$on('meurh_solicitacoescreditodesconto_loaded', (event: any, args: any) => {
            this.alteraEntitiesLoaded();
            this.entitiesCreditosDescontosALL = this.MeurhCreditosdescontosService.reload(true);
        });

        $scope.$on('meurh_solicitacoescreditodesconto_loaded_full_count', (event: any, args: any) => {
            this.busy = false;
        });

        $scope.$on('meurh_solicitacoescreditodesconto_load_error', (error: any) => {
            this.busy = false;
        });

        if (this.clienteAna) {
            this.fields.push(
                {
                    value: 'fim',
                    label: 'Fim',
                    type: 'string',
                    style: 'default',
                    copy: '',
                    customCollumnHtml: () => {
                        let someHtmlVar = `<span ng-bind-template="{{tableRowEntity.fim ? 'Sim' : 'Não'}}"></span>`;
                        return someHtmlVar;
                    },
                }
            );
        }
    }

    $onInit() {
        this.busy = true;
        this.constructors.trabalhador = this.entity.trabalhador;
        if (this.constructors.trabalhador) {
           this.entitiesTemp = this.MeurhCreditosdescontosService.reload();
        }

        this.listaRubricas = this.RubricasPontoOverLoadService.reload();

        if (this.entitiesCreditosDescontos.length === 0) {
            this.selecionadosCreditosDescontos = [];
        }

        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    getRowCustomClass(entity: any) {
        return entity.rowcustomclass;
    }

    alteraEntitiesLoaded() {
        this.entitiesTemp.forEach((element: any) => {
            element.dataFolhaAdiantamentoSalarial = this.entityDadosCriacao.dataFolhaAdiantamentoSalarial;
            element.dataFolhaPagamento = this.entityDadosCriacao.dataFolhaPagamento;

            if (element.mesfinalperiodo && element.anofinalperiodo) {
                let competenciapagamentoCompleta = element.tipocalculo === 0 ? element.dataFolhaAdiantamentoSalarial : element.dataFolhaPagamento;

                let mesCompetenciapagamento = moment(competenciapagamentoCompleta, 'DD/MM/YYYY').format('MM');
                let anoCompetenciapagamento = moment(competenciapagamentoCompleta, 'DD/MM/YYYY').format('YYYY');

                let diferenca = moment([element.anofinalperiodo, (element.mesfinalperiodo - 1)]).diff(moment([anoCompetenciapagamento, (Number(mesCompetenciapagamento) - 1)]), 'months', true);

                element.competenciafinalperiodoCompleta = moment(competenciapagamentoCompleta, 'DD/MM/YYYY').add(diferenca, 'months').format('DD/MM/YYYY');
                element.competenciafinalperiodoCompletaMesAno = moment(competenciapagamentoCompleta, 'DD/MM/YYYY').add(diferenca, 'months').format('MM/YYYY');
            }

            element.rowcustomclass = '';
            element.teste = '';
        });
        this.entities = angular.copy(this.entitiesTemp);
    }

    abrirModal(tipo: any, entityCriada: any = {}, isEdit: boolean = false, isListaExistentes: boolean = false) {
        if (this.listaRubricas.filter((item: any) => item.evento_tipovalor === tipo).length === 0) {
            this.MeurhCreditosdescontosIndisponivelmodalService.open(tipo);
        } else {
            if (entityCriada.identificadorTemporario === undefined) {
                // verifica se solicitacao já existe no banco
                this.modalEntityCreditosDescontos = entityCriada.solicitacao === undefined ? {} : angular.copy(entityCriada);
                this.modalEntityCreditosDescontos.isShow = entityCriada.solicitacao === undefined ? false : true;
                this.modalEntityCreditosDescontos.isEdit = isEdit;
                this.modalEntityCreditosDescontos.isListaExistentes = isListaExistentes;

                // infos para exibir na tela
                this.modalEntityCreditosDescontos.identificadorTemporario = undefined;
                this.modalEntityCreditosDescontos.tipo = tipo;
                this.modalEntityCreditosDescontos.tipocalculo = this.entityDadosCriacao.tipocalculo;
                this.modalEntityCreditosDescontos.dataFolhaAdiantamentoSalarial = this.entityDadosCriacao.dataFolhaAdiantamentoSalarial;
                this.modalEntityCreditosDescontos.dataFolhaPagamento = this.entityDadosCriacao.dataFolhaPagamento;

                this.modalEntityCreditosDescontos.constructors = {
                    grupoempresarial: this.$rootScope.nsjGlobals.a.grupoempresarial,
                    tenant: this.$rootScope.nsjGlobals.a.tenant,
                };
                this.modalEntityCreditosDescontos.trabalhador = {};
                this.modalEntityCreditosDescontos.trabalhador.trabalhador = this.entity.trabalhador;
                this.modalEntityCreditosDescontos.lotacao = this.entity.lotacao;
                this.modalEntityCreditosDescontos.estabelecimento = this.$rootScope.nsjGlobals.a.estabelecimento;
            } else {
                this.modalEntityCreditosDescontos = angular.copy(entityCriada);
                this.modalEntityCreditosDescontos.isShow = true;
                this.modalEntityCreditosDescontos.isEdit = isEdit;
            }

            let competenciaAtualMoment = moment(this.competencia, 'MM/YYYY');

            // filtrando apenas os movimentos do colaborador para a competência atual
            let movimentosCompetenciaAtual = this.entitiesCreditosDescontosALL.filter((movimento) => {
                return moment(competenciaAtualMoment).isSame( moment(movimento.mesreferencia + '/' + movimento.anoreferencia, 'MM/YYYY') );
            });

            var modal = this.MeurhCreditosdescontosAdicionarmodalService.open(this.modalEntityCreditosDescontos, movimentosCompetenciaAtual, tipo);

            modal.result.then((response: any) => {
                if (response.remover !== undefined && response.remover === true) {
                    this.removerCreditoDesconto(response);
                } else {
                    // setar identificador temporário para crédito/desconto criado
                    if (response.identificadorTemporario === undefined) {
                        response['identificadorTemporario'] = this.contadorIdentificadorTemporario;
                        this.contadorIdentificadorTemporario = this.contadorIdentificadorTemporario + 1;

                        // setar enviado para exibição na tabela
                        response.enviado = 'Ainda não enviado';
                    }

                    // setar competenciafinalperiodoCompleta para exibição na tabela
                    let competenciapagamentoCompleta = response.tipocalculo === 0 ? response.dataFolhaAdiantamentoSalarial : response.dataFolhaPagamento;
                    let mesCompetenciapagamento = moment(competenciapagamentoCompleta, 'DD/MM/YYYY').format('MM');
                    let anoCompetenciapagamento = moment(competenciapagamentoCompleta, 'DD/MM/YYYY').format('YYYY');
                    let diferenca = moment([response.ano, (response.mes - 1)]).diff(moment([anoCompetenciapagamento, (Number(mesCompetenciapagamento) - 1)]), 'months', true);
                    response['competenciafinalperiodoCompleta'] = moment(competenciapagamentoCompleta, 'DD/MM/YYYY').add(diferenca, 'months').format('DD/MM/YYYY');
                    response['competenciafinalperiodoCompletaMesAno'] = moment(competenciapagamentoCompleta, 'DD/MM/YYYY').add(diferenca, 'months').format('MM/YYYY');

                    if (this.modalEntityCreditosDescontos.isShow) {
                        for (let i = 0; i < this.entitiesCreditosDescontos.length; i++) {
                            if (this.entitiesCreditosDescontos[i].identificadorTemporario === response.identificadorTemporario) {
                                this.entitiesCreditosDescontos[i] = {};
                                this.entitiesCreditosDescontos[i] = angular.copy(response);
                            }
                        }
                        this.entitiesCreditosDescontos = [...this.entitiesCreditosDescontos];
                    } else {
                        this.entitiesCreditosDescontos.push(angular.copy(response));
                    }
                    this.selecionadosCreditosDescontos = this.entitiesCreditosDescontos;
                }
                response = {};
            }).catch((e: any) => { /**/ });
        }
    }

    removerCreditoDesconto(entityCreditoDesconto: any) {

        for (var i = 0; i < this.entitiesCreditosDescontos.length; i++) {

            if (this.entitiesCreditosDescontos[i].identificadorTemporario === entityCreditoDesconto.identificadorTemporario) {

                this.entitiesCreditosDescontos.splice(i, 1);
            }

        }

        if (this.selecionadosCreditosDescontos.length > 0) {
            for (var j = 0; j < this.selecionadosCreditosDescontos.length; j++) {

                if (this.selecionadosCreditosDescontos[j].identificadorTemporario === entityCreditoDesconto.identificadorTemporario) {

                    this.selecionadosCreditosDescontos.splice(j, 1);
                }

            }
        }

        if (this.entitiesCreditosDescontos.length === 0 && this.entities.length === 0) {
            this.busy = true;
            this.entitiesTemp = this.MeurhCreditosdescontosService.reload();
        }
    }

    loadMore() {
        this.MeurhCreditosdescontosService.loadMore();
    }

    finished(): boolean {
		return this.MeurhCreditosdescontosService.loadParams.finished;
	}

    formInvalido() {
        if (this.selecionadosCreditosDescontos.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    montaMensagem() {
        let creditos = 0;
        let descontos = 0;
        this.entitiesCreditosDescontos.forEach((element: any) => {
            if (element.evento.tipovalor === 0 || element.evento.evento_tipovalor === 0) {
                creditos++;
            } else {
                descontos++;
            }
        });
        this.mensagensPassos.mensagemPasso03 = creditos + (creditos > 1 ? ' créditos' : ' crédito') + ' e ' + descontos + (descontos > 1 ? ' descontos.' : ' desconto.');
    }

    calculaPasso(continua: boolean) {
        this.allowRefresh = false;
        if (continua) {
            this.montaMensagem();
            this.passo = this.passo + 1;
        } else {
            this.passo = this.passo - 1;
        }
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'creditosdescontos' && this.allowRefresh) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
