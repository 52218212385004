import angular from 'angular';
import { ITransferenciaColaboradores } from '../models/transferenciacolaboradores.model';
import { MeurhTransferenciaColaboradoresService } from '../transferenciacolaboradores.service';
import { MeurhSolicitacoes } from '../../../Meurh/Solicitacoes/services/solicitacoes.service';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';
import { WorkflowService } from '../../../Workflow/workflow.service';

export class MeurhTransferenciaColaboradoresShowController implements angular.IController {
	static $inject = [
        '$scope',
        '$stateParams',
        '$state',
        'MeurhTransferenciaColaboradoresService',
        'entity',
        'NewToaster',
        'ModalConfirmService',
        'MeurhSolicitacoes',
        'WorkflowService'
    ];

    public busy: boolean = false;
    public constructors: any = {};
    public entitySave: Partial<ITransferenciaColaboradores> = {};
    public path: number = this.$stateParams.path;

    constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
        public entityService: MeurhTransferenciaColaboradoresService,
        public entity: ITransferenciaColaboradores,
        public NewToaster: any,
        public ModalConfirmService: any,
        public MeurhSolicitacoes: MeurhSolicitacoes,
        public WorkflowService: WorkflowService) {

        this.onDeleteSuccess();
        this.onDeleteError();
    }

    $onInit(): void {
		this.afterReloadWorkflow();
		this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoestransferenciasvagas_');
	}


    afterReloadWorkflow(): void {
		this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any): void => {
			this.$state.reload();
		});
	}
    cancelar(entity: ISolicitacao): void {
		let confirm = this.ModalConfirmService.openCancelar(this.entity, 'Transferência');

        confirm.result.then((entity: ISolicitacao): void => {
			this.busy = true;
			this.MeurhSolicitacoes.cancelar(entity)
				.then((response: any): void => {
					this.NewToaster.pop({
						type: 'success',
						title: 'A Solicitação de Transferência foi cancelada com sucesso!'
					});

					this.entityService.reload();
					this.$state.go('meurh_solicitacoestransferenciasvagas', angular.extend(this.entityService.constructors));
				})
				.catch((response: any): void => {
					if (typeof (response.data.message) !== 'undefined' && response.data.message) {
						this.NewToaster.pop({
							type: 'error',
							title: response.data.message
						});
					} else {
						this.NewToaster.pop({
							type: 'error',
							title: 'Ocorreu um erro ao cancelar a Solicitação de Transferência!'
						});
					}
				})
				.finally((): void => {
					this.busy = false;
				});
		})
		.catch((error: any): void => {
			if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
				this.NewToaster.pop({
					type: 'error',
					title: error
				});
			}
		});
	}

    delete(force: boolean): void {
        let confirm = this.ModalConfirmService.open(this.entity, 'Transferência', true);
        confirm.result
        .then((entity: ITransferenciaColaboradores): void => {
            this.busy = true;
            this.entityService.delete(this.$stateParams['solicitacao'], force);
        })
        .catch(function () {/**/});
    }


    onDeleteSuccess(): void {
        this.$scope.$on('meurh_solicitacoestransferenciasvagas_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Transferência foi cancelada com sucesso!'
            });
            this.$state.go('meurh_solicitacoestransferenciasvagas', angular.extend(this.entityService.constructors));
        });
    }

    onDeleteError(): void {
        this.$scope.$on('meurh_solicitacoestransferenciasvagas_delete_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: args.response.data.message
                    });
            } else {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: 'Ocorreu um erro ao tentar cancelar.'
                    });
            }
        });
    }

}
