import angular = require('angular');
import { MeurhSolicitacoes } from '../../Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { IAlteracaoDadosSalariaisVisualizacao } from '../models/alteracaodadossalariais.model';
import { MeurhSolicitacoesalteracoesdadossalariaisService } from '../solicitacoesalteracoesdadossalariais.service';
import { MeurhAcompanhamentoService } from '../../Acompanhamento/acompanhamento.service';

export class MeurhSolicitacoesalteracoesdadossalariaisShowController implements ng.IController {
	static $inject = [
        '$scope',
        '$stateParams',
        '$state',
        'MeurhSolicitacoesalteracoesdadossalariaisService',
        'entity',
        'NewToaster',
        'ModalConfirmService',
        'WorkflowService',
        'MeurhSolicitacoes',
        'MeurhAcompanhamentoService',
    ];

    public action: string = 'show';
    public busy: boolean = false;
    public path: number = this.$stateParams.path;

    constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesalteracoesdadossalariaisService,
        public entity: IAlteracaoDadosSalariaisVisualizacao,
        public NewToaster: any,
        public ModalConfirmService: any,
        public WorkflowService: WorkflowService,
        public MeurhSolicitacoes: MeurhSolicitacoes,
        public MeurhAcompanhamentoService: MeurhAcompanhamentoService,
    ) {

        $scope.$on('meurh_solicitacoes_canceled', (event: angular.IAngularEvent, args: any): void => {
            this.$state.reload();
        });

    }

    $onInit(): void {
        this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any): void => {
            this.$state.reload();
        });

        this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesalteracoestiporecebimento_');

        this.carregaAcompanhamento();
        this.entity.tiporecebimento = this.entity.tiporecebimento.toString();
        this.entity.tipocontapix = this.entity.tiporecebimento === '64' ? this.entity.tipocontapix.toString() : null;

    }

    carregaAcompanhamento(): void {
        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.loadParams.finished = false;
        this.MeurhAcompanhamentoService.loadParams.to_load = 3;
        this.MeurhAcompanhamentoService.load();
    }

}
