import angular = require('angular');
import { MeurhSolicitacoes } from '../../../Meurh/Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';
import { IVtadicional } from '../models/vtadicional.model';
import { MeurhSolicitacoesvtsadicionaisService } from '../solicitacoesvtsadicionais.service';

export class MeurhSolicitacoesvtsadicionaisShowController implements ng.IController {
	static $inject = [ '$scope', '$stateParams', '$state', 'MeurhSolicitacoesvtsadicionaisService', 'entity', 'NewToaster', 'ModalConfirmService', 'WorkflowService', 'MeurhSolicitacoes'];

    public action: string = 'retrieve';
    public busy: boolean = false;
    public path: number = this.$stateParams.path;

    constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesvtsadicionaisService,
        public entity: IVtadicional,
        public NewToaster: any,
        public ModalConfirmService: any,
        public WorkflowService: WorkflowService,
        public MeurhSolicitacoes: MeurhSolicitacoes) {

        $scope.$on('meurh_solicitacoes_canceled', (event: angular.IAngularEvent, args: any): void => {
            this.$state.reload();
        });

        this.onDeleteSuccess();
        this.onDeleteError();
    }

    $onInit(): void {
        this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any): void => {
            this.$state.reload();
        });

        this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesvtsadicionais_');
    }

    delete(force: boolean): void {
        this.entityService.delete(this.$stateParams['solicitacao'], force);
    }

    onDeleteSuccess(): void {
        this.$scope.$on('meurh_solicitacoesvtsadicionais_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Solicitação de VT Adicional foi excluída com sucesso!'
            });
            this.$state.go('meurh_solicitacoesvtsadicionais', angular.extend(this.entityService.constructors));
        });
    }

    onDeleteError(): void {
        this.$scope.$on('meurh_solicitacoesvtsadicionais_delete_error', (event: angular.IAngularEvent, args: any): void => {
            if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: args.response.data.message
                    });
            } else {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: 'Ocorreu um erro ao tentar excluir.'
                    });
            }
        });
    }

    cancelar(entity: IVtadicional & ISolicitacao): void {
        let confirm = this.ModalConfirmService.openCancelar(entity, 'solicitação de VT adicional');
        confirm.result.then((entity: IVtadicional & ISolicitacao) => {
            this.busy = true;
            this.MeurhSolicitacoes.cancelar(entity).then((response: any) => {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A Solicitação de VT Adicional foi cancelada com sucesso.'
                });
                this.entityService.reload();
                this.$state.go('meurh_solicitacoesvtsadicionais', angular.extend(this.entityService.constructors));
            }).catch((response: any) => {
                if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: response.data.message
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro ao cancelar.'
                    });
                }
            }).finally(() => {
                this.busy = false;
            });
        }).catch((error: any) => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });

    }

}
