import angular from 'angular';

export class MeurhPromocoesShowFormController implements angular.IController {
    static $inject = ['$rootScope'];

    public entity: any;
    public unidadesSalario = {
        1: 'Hora',
        2: 'Dia',
        3: 'Semana',
        4: 'Mês',
        5: 'Tarefa',
        6: 'Variável'
    };
    constructor(
        public $rootScope: angular.IRootScopeService & {
            modoGestorPermissao: (arg: string) => boolean,
            temPermissao: (arg: string) => boolean,
            liberadoTela: (arg: string) => boolean
        },
    ) {
    }
}
