import angular from 'angular';
import { MeurhMudancaHorarioService } from '../mudancahorario.service';
import { MeurhSolicitacoes } from '../../../Meurh/Solicitacoes/services/solicitacoes.service';
import { IMudancaHorario } from '../models/mudancahorario.model';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';

export class MeurhMudancaHorarioShowController {
    static $inject = ['$state', 'MeurhMudancaHorarioService', 'MeurhSolicitacoes', 'ModalConfirmService', 'NewToaster', 'entity', '$stateParams'];

    public action: string = 'show';
    public busy: boolean = false;
    public path: number = this.$stateParams.path;

    constructor(
        public $state: angular.ui.IStateService,
        public entityService: MeurhMudancaHorarioService,
        public MeurhSolicitacoes: MeurhSolicitacoes,
        public ModalConfirmService: any,
        public NewToaster: any,
        public entity: IMudancaHorario,
        public $stateParams: angular.ui.IStateParamsService,
    ) {}

    cancelar(): void {
		let confirm = this.ModalConfirmService.openCancelar(this.entity, 'mudança de horário');
        confirm.result.then((entity: ISolicitacao): void => {
			this.busy = true;
			this.MeurhSolicitacoes.cancelar(entity)
            .then((): void => {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A mudança de horário foi cancelada com sucesso!'
                });

                this.entityService.reload();
                this.$state.go('meurh_mudancahorario', angular.extend(this.entityService.constructors));
            })
            .catch((response: any): void => {
                if (response.data && typeof (response.data.message) !== 'undefined' && response.data.message) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: response.data.message
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao cancelar a mudança de horário!'
                    });
                }
            })
            .finally((): void => {
                this.busy = false;
            });
		})
		.catch((error: any): void => {
			if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
				this.NewToaster.pop({
					type: 'error',
					title: error
				});
			}
		});
	}
}
