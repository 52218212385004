declare var nsjGlobals: any;
import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
// import { IAdmissaoCompleta } from '../models/admissaocompleta.model';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { SituacaoAdmissaoEnum } from '../enums/situacao';
import { MeurhAdmissaocompletaService } from '../admissaocompleta.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { IGestoresTrabalhador } from '../../Transferenciacolaboradores/models/transferenciacolaboradores.model';

export class MeurhAdmissaocompletaNewController implements ng.IController {
    static $inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        '$state',
        'MeurhAdmissaocompletaService',
        'entity',
        'NewToaster',
        'TiposDocumentosRequeridosService',
        'ModalConfirmService',
        'ConfiguracoesService'
    ];

    public constructors: any = {};
    public action: string = 'insert';
    public tipo = this.$stateParams['tipo'];
    public form: angular.IFormController;
    public busy: boolean = false;
    public entitySave: any = {};
    public dataAtual = new Date();
    public matriculaAutomatica: boolean = false;
    public enviaEncaminhar: boolean = false;
    public cardAtencao: boolean = false;
    public toastTimeout: number = 8000;
    public configuracoes: any;
    public envioCompleto: boolean = false;
    public mostraobrigatoriosencaminhar: boolean = false;

    public toastController = {
        podeEncaminhar: false,
        podeEnviar: false,
        podeEnviarPreliminar: false
    };

    // documentos / anexos
    public mostrarAlerta: boolean = false;
    public tiposdocumentosrequeridos: Array<any>;
    public busyTipoDocumentos: boolean = true;
    public busyAnexo: boolean = false;
    public adicionarAnexo: boolean = false;
    public desativaportrabalhadortemporario: boolean = false;
    public documentosValidados: boolean = false;
    public editanexo: boolean = true;
    public entityAnexo: IAnexo = {};
    public alterouanexo: boolean = false;

    public clienteAna: boolean;
    public idsCards: Array<string> = [
        'card-dadosgerais',
        'card-docsanexos',
        'card-contrato',
        'card-endereco',
        'card-dadoscontato',
        'card-dadospessoais',
        'card-documentacao',
        'card-outrasinfos',
        'card-observacoes',
        'card-beneficios',
        'card-dependentes',
        'card-valestransporte',
    ];
    public configDesativaAdmPreliminar: boolean;

    private situacaoAdmissaoEnum = SituacaoAdmissaoEnum;

    private link: string;

    constructor(
        private $scope: angular.IScope,
        private $rootScope: angular.IRootScopeService & { configuracoes: any, nsjGlobals: any, liberadoTela(permissao: string): any, },
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private entityService: MeurhAdmissaocompletaService,
        private entity: any,
        private NewToaster: any,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        private ModalConfirmService: any,
        private Configuracoes: any
    ) {
        this.onSubmitSuccess();
        this.onSubmitError();
        this.onSubmitPreliminarSuccess();
        this.onSubmitPreliminarError();
        this.onEncaminharSuccess();
        this.onEncaminharError();
        this.onEncaminharLinkSuccess();
        this.onEncaminharLinkError();
        this.entity = {};
        this.configuracoes = this.Configuracoes.reload();

        this.entityAnexo['casodeuso'] = 'ADMISSOESCOMPLETAS';
        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();

        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                return tipodocumentorequerido;
            });
        });

        this.$scope.$on('meurh_leituradocumentos_finalizada', (event: angular.IAngularEvent, args: any): void => {
            this.preencherDadosLeituraDocumentos(args);
            this.busyAnexo = false;
        });

        this.$scope.$watch('mrh_admsscmplt_frm_nw_cntrllr.toastController.podeEnviar', function (newValue: any, oldValue: any) {
            if (newValue === true) {
                this.envioCompleto = true;
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão já pode ser enviada',
                    body: 'Todos os dados obrigatórios já foram informados.',
                    timeout: this.toastTimeout
                });
            }
        }.bind(this), true);

        this.configDesativaAdmPreliminar = this.$rootScope.configuracoes.DESABILITAR_ADMISSAO_PRELIMINAR;

        this.$scope.$watch('mrh_admsscmplt_frm_nw_cntrllr.toastController.podeEnviarPreliminar', function (newValue: any, oldValue: any) {
            if (newValue === true && !this.envioCompleto && !this.configDesativaAdmPreliminar) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão preliminar já pode ser enviada',
                    body: 'Todos os dados mínimos já foram informados.',
                    timeout: this.toastTimeout
                });
            }
        }.bind(this), true);

        this.$scope.$watch('mrh_admsscmplt_frm_nw_cntrllr.toastController.podeEncaminhar', function (newValue: any, oldValue: any) {
            if (newValue === true) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão já pode ser encaminhada.',
                    body: 'Já é possível encaminhar a admissão para o futuro colaborador informar os dados pelo app.',
                    timeout: this.toastTimeout
                });
            }
        }.bind(this), true);

        this.clienteAna = nsjGlobals.get('clienteana');
        this.entity.gestaoBeneficioAvancadaAtiva = this.$rootScope.nsjGlobals.a.configuracoes.GESTAO_BENEFICIO_AVANCADA_ATIVA;

    }

    $onInit(): void {
        if (this.$rootScope.configuracoes.CODIGO_FUNCIONARIO_AUTOMATICO === true) {
            this.matriculaAutomatica = true;
        }
        let percAdiant = this.$rootScope.configuracoes.PERCENTUAL_AD_PADRAO_FUNCIONARIO !== null ? this.$rootScope.configuracoes.PERCENTUAL_AD_PADRAO_FUNCIONARIO / 100 : 0.4;

        this.entity.primeiroemprego = false;
        this.entity.dataadmissao = this.dataAtual;
        this.entity.dataopcaofgts = this.dataAtual;
        this.entity.inicioperiodoaquisitivoferias = moment(this.dataAtual);
        this.entity.datavencimentoatestadomedico = moment(this.dataAtual).add(1, 'years');
        this.entity.quantidademediahorassemanais = 44;
        this.entity.numerodiasperiodo = 30;
        this.entity.numerohorasmensais = 220;
        this.entity.percentualadiantamento = percAdiant;
        this.entity.tipoadmissao = '1'; // contratação Normal
        this.entity.regimejornada = '1'; // submetidos a Horário de Trabalho (Cap. II da CLT)
        this.entity.tipocontrato = '2'; // determinado
        this.entity.tiporegimetrabalhista = '1'; // cLT
        this.entity.unidadesalariofixo = '4'; // mês
        this.entity.tipoatividade = '2'; // urbana
        this.entity.motivoadmissao = '1'; // normal
        this.entity.pais = {
            nome: 'BRASIL',
            pais: '1058'
        };
        this.entity.paisnascimento = angular.copy(this.entity.pais);
        this.entity.paisnacionalidade = angular.copy(this.entity.pais);
        this.entity.vinculo = {
            vinculo: '10',
            descricao: 'Trabalhador urbano vinculado a empregador pessoa jurídica por contrato de trabalho regido pela CLT, por prazo indeterminado'
        };
    }

    // início - ações do formulário
    public submit(rascunho: boolean = false, encaminhar: boolean = false, geralink: boolean = false): void {
        let customText = {};

        if (encaminhar) {
            if (geralink) {
                this.link = 'link';
            }
            customText = this.textoModal('encaminhar');
            rascunho = true;
            this.enviaEncaminhar = true;
        } else if (rascunho) {
            customText = this.textoModal('rascunho');
        } else {
            customText = this.textoModal('admissaocompleta');
        }

        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result
            .then((): void => {
                const documentosValidados: boolean = this.validaDocumentosAnexos();
                this.form.$submitted = true;

                if (
                    (
                        !this.entity.$$__submitting &&
                        this.validaEntity('admissaocompleta') &&
                        documentosValidados &&
                        !rascunho
                    ) ||
                    (
                        rascunho &&
                        !!this.entity.nome
                    )
                ) {
                    this.busy = true;
                    this.alteraEntityEnviar(rascunho);
                    this.entityService.save(this.entitySave);
                } else {
                    if (this.validaEntity('admissaocompleta') && !documentosValidados && !rascunho) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Há documento(s) obrigatório(s) não informado(s)',
                            timeout: this.toastTimeout
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Alguns campos do formulário apresentam erros',
                            body: 'Favor verificar os campos obrigatórios, assinalados com *.',
                            timeout: this.toastTimeout
                        });
                    }
                }
            })
            .catch(function () {/**/ });
    }

    public submitPreliminar(): void {
        let confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, this.textoModal('admissaopreliminar'));

        let diasAtrasoAdmissao: number = parseInt(this.configuracoes.ATRASO_ADMISSAO.valor, 10);
        let dataValida: string = moment(moment(this.dataAtual).add(diasAtrasoAdmissao, 'days')).format('YYYY-MM-DD');

        confirm.result
            .then((): void => {
                if ((!this.entity.$$__submitting && this.validaEntity('admissaopreliminar') &&
                    !moment(dataValida).isAfter(this.entity.dataadmissao))) {
                    this.busy = true;
                    this.alteraEntityEnviar(true);
                    this.entityService.enviaPreliminar(this.entitySave, true);
                } else {
                    if (!this.validaEntity('admissaopreliminar')) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Há campo(s) obrigatório(s) não informado(s)',
                            timeout: this.toastTimeout
                        });
                    } else if (moment(dataValida).isAfter(this.entity.dataadmissao)) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Data de admissão inválida.',
                            body: 'O envio da admissão preliminar precisa acontecer no mínimo ' + diasAtrasoAdmissao + ' dias antes da data de admissão. Altere a data de admissão ou verifique as configurações do sistema.',
                            timeout: this.toastTimeout
                        });
                    } else {
                        this.mostraObrigatoriosPreliminar();
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Alguns campos do formulário apresentam erros',
                            body: 'Favor verificar os campos obrigatórios, assinalados com *.',
                            timeout: this.toastTimeout
                        });
                    }
                }
            })
            .catch(function () {/**/ });
    }

    public descartar(): void {
        let textos = {
            title: 'Descartar criação',
            subtitle: 'Após o descarte não será mais possível reverter a ação.',
            confirmText: 'Você tem certeza que deseja descartar a criação da admissão?',
            closeButton: 'Cancelar',
            confirmButton: 'Sim, descartar',
        };
        let confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, textos);

        confirm.result.then((): void => {
            this.busy = true;
            this.$state.go('meurh_admissaocompleta', {});
        })
            .catch((error: any): void => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error,
                        timeout: this.toastTimeout
                    });
                }
            });
    }
    // fim - ações do formulário


    // início - controle de botões e campos
    public podeSalvarRascunho(): boolean {
        return this.form.nome.$valid && this.validaDadosBancarios();
    }

    public podeEnviarCompleta(): boolean {
        this.toastController.podeEnviar = this.validaCompleta();

        return this.toastController.podeEnviar;
    }

    public podeEnviarPreliminar(): boolean {
        this.toastController.podeEnviarPreliminar = this.validaPreliminar();

        return this.toastController.podeEnviarPreliminar;
    }

    public podeEncaminhar(): boolean {
        this.toastController.podeEncaminhar = this.validaEncaminhar();

        return this.toastController.podeEncaminhar;
    }

    public mostrarObrigatoriosCompleta(): void {

        this.idsCards.forEach((idCard) => {
            this.verificaExpansaoCard(idCard);
        });

        this.form.$setPristine();
        this.form.$setSubmitted();
        this.mostrarAlerta = true;
        this.mostraobrigatoriosencaminhar = false;
    }

    public mostraObrigatoriosEncaminhar(): void {
        setTimeout(() => {
            if (this.form) {
                this.form.$setPristine();
                this.form.matricula.$setDirty();
                this.form.nome.$setDirty();
                this.form.email.$setDirty();
                this.form.categoriatrabalhador.$setDirty();
                this.form.salariofixo.$setDirty();
                this.form.sindicato.$setDirty();
                this.form.cargo.$setDirty();
                this.form.nivelcargo.$setDirty();
                this.form.departamento.$setDirty();
                this.form.horario.$setDirty();
                this.form.lotacao.$setDirty();
                this.form.unidadesalariofixo.$setDirty();

                if (this.entity.vagaAtiva) {
                    this.form.vaga.$setDirty();
                } else {
                    this.form.estabelecimento.$setDirty();
                }
            }
            this.mostrarAlerta = false;
            this.mostraobrigatoriosencaminhar = true;

            this.$scope.$applyAsync();
        }, 100);
    }

    public mostraObrigatoriosPreliminar(): void {
        this.form.$setPristine();
        this.form.matricula.$setDirty();
        this.form.nome.$setDirty();
        this.form.cpf.$setDirty();
        this.form.datanascimento.$setDirty();
        this.form.dataadmissao.$setDirty();
        this.form.departamento.$setDirty();
        this.form.cargo.$setDirty();
        this.form.nivelcargo.$setDirty();
        this.form.salariofixo.$setDirty();
        this.form.horario.$setDirty();
    }
    // fim - controle de botões e campos


    // início - validações
    private validaCompleta(): boolean {
        return (
            this.validaDadosGerais() &&
            this.validaDocumentosAnexos() &&
            this.validaContrato() &&
            this.validaEndereco() &&
            this.validaDadosContato() &&
            this.validaDadosPessoais() &&
            this.validaDocumentacao() &&
            this.validaOutrasInformacoes()
        );
    }

    private validaPreliminar(): boolean {
        return (
            this.validaMatricula() &&
            !!this.entity.nome &&
            !!this.entity.cargo &&
            !!this.entity.nivelcargo &&
            !!this.entity.departamento &&
            !!this.entity.salariofixo &&
            !!this.entity.dataadmissao &&
            !!this.entity.horario &&
            !!this.entity.datanascimento &&
            !!this.entity.cpf
        );
    }

    private validaEncaminhar(): boolean {
        return (
            this.validaMatricula() &&
            !!this.entity.nome &&
            !!this.entity.email &&
            this.validaDadosVaga() &&
            this.validaDadosEmpresa() &&
            this.validaSalarioFixo() &&
            this.validaAdmissao() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaDadosGerais(): boolean {
        return (
            this.validaIdentificacao() &&
            this.validaDadosVaga() &&
            this.validaDadosEmpresa() &&
            this.validaSalarioFixo() &&
            this.validaAdmissao() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaIdentificacao(): boolean {
        const emailValid = this.validaEmail();

        return (
            this.validaMatricula() &&
            !!this.entity.nome &&
            !!this.entity.dataadmissao &&
            !!this.entity.cpf &&
            this.form.cpf.$valid &&
            !!this.entity.datanascimento &&
            emailValid
        );
    }

    private validaMatricula(): boolean {
        return this.matriculaAutomatica || !!this.entity.matricula;
    }

    private validaDadosVaga(): boolean {
        return (
            !!this.entity.vagaAtiva === !!this.entity.vaga &&
            !!this.entity.cargo &&
            !!this.entity.nivelcargo
        );
    }

    private validaDadosEmpresa(): boolean {
        return (
            !!this.entity.estabelecimento &&
            !!this.entity.departamento &&
            !!this.entity.lotacao
        );
    }

    private validaSalarioFixo(): boolean {
        return (
            !!this.entity.salariofixo &&
            !!this.entity.unidadesalariofixo
        );
    }

    private validaAdmissao(): boolean {
        return (
            !!this.entity.tipoadmissao &&
            !!this.entity.motivoadmissao
        );
    }

    private validaOutrasInformacoesGerais(): boolean {
        return (
            !!this.entity.sindicato &&
            !!this.entity.categoriatrabalhador &&
            !!this.entity.horario &&
            !!this.entity.regimejornada
        );
    }

    private validaDocumentosAnexos(): boolean {
        return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado;
        });
    }

    private validaContrato(): boolean {
        return (
            this.validaPrazo() &&
            this.validaPeriodos() &&
            this.validaOutrasInformacoesContrato() &&
            this.validaContratacaoTemporaria()
        );
    }

    private validaPrazo(): boolean {
        return (
            this.validaDiasFimContrato() &&
            this.validaDiasexperienciacontrato()
        );
    }

    private validaDiasFimContrato(): boolean {
        return this.entity.tipocontrato !== '2' || !!this.entity.datafimcontrato;
    }

    private validaDiasexperienciacontrato(): boolean {
        return this.entity.tipocontrato === '2'
            ? (
                this.entity.categoriatrabalhador.categoriatrabalhador === '105' ||
                this.entity.categoriatrabalhador.categoriatrabalhador === '106' || // contrato temporário
                !!this.entity.diasexperienciacontrato
            )
            : true;
    }

    private validaPeriodos(): boolean {
        return !(
            !(!!this.entity.numerodiasperiodo) ||
            this.entity.numerodiasperiodo > 31 ||
            !(!!this.entity.numerohorasmensais) ||
            !(!!this.entity.quantidademediahorassemanais)
        );
    }

    private validaOutrasInformacoesContrato(): boolean {
        return (
            !!this.entity.tiporegimetrabalhista &&
            !!this.entity.tipoatividade
        );
    }

    private validaContratacaoTemporaria(): boolean {
        return (
            this.entity.categoriatrabalhador.categoriatrabalhador !== '106' ||
            (
                !!this.entity.motivocontratacaotemporaria &&
                !!this.entity.tipoinclusaotemporaria
            ) &&
            (
                this.entity.motivocontratacaotemporaria !== 1 ||
                this.form.cpftrabalhadorsubstituido.$valid
            )
        );
    }

    private validaEndereco(): boolean {

        let infosValidasPaisEstrangeiro: boolean = false;

        if (this.entity.pais) {
            if (this.entity.pais.pais !== '1058') {
                infosValidasPaisEstrangeiro = !!this.entity.formatributacaoexterior;
            } else if (this.entity.pais.pais === '1058') {
                infosValidasPaisEstrangeiro = true;
            }
        }

        return (
            !!this.entity.tipologradouro &&
            !!this.entity.logradouro &&
            !!this.entity.numero &&
            !!this.entity.cep &&
            !!this.entity.municipio &&
            !!this.entity.pais &&
            infosValidasPaisEstrangeiro
        );
    }

    private validaDadosContato(): boolean {
        const telofonesValid = this.validaTelefones();

        return telofonesValid;
    }

    private validaTelefones(): boolean {
        return (
            this.validaTelefone() &&
            this.validaCelular()
        );
    }

    private validaTelefone(): boolean {
        return !(!!this.entity.telefone) || this.entity.telefone.length >= 8;
    }

    private validaCelular(): boolean {
        return !(!!this.entity.celular) || this.entity.celular.length >= 8;
    }

    private validaEmail(): boolean {
        return (
            !!this.entity.emailcorporativo ||
            !!this.entity.email
        );
    }

    private validaDadosPessoais(): boolean {
        return (
            this.validaNascimento() &&
            this.validaIdentificacaoDadosPessoais()
        );
    }

    private validaNascimento(): boolean {

        let infosValidasPaisEstrangeiro: boolean = false;

        if (this.entity.paisnascimento) {
            if (this.entity.paisnascimento.pais !== '1058') {
                infosValidasPaisEstrangeiro = !!this.entity.datachegadapais && !!this.entity.temporesidenciaestrangeiro && !!this.entity.classificacaoestrangeiro;
            } else if (this.entity.paisnascimento.pais === '1058') {
                infosValidasPaisEstrangeiro = true;
            }
        }

        return (
            !!this.entity.municipionascimento &&
            !!this.entity.paisnascimento &&
            !!this.entity.paisnacionalidade &&
            infosValidasPaisEstrangeiro
        );
    }

    private validaIdentificacaoDadosPessoais(): boolean {

        let validacoesClienteAna = true;

        if (this.clienteAna && !this.entity.semfiliacao) {
            validacoesClienteAna = !!this.entity.nomemae || !!this.entity.nomepai;
        }

        return (
            !!this.entity.sexo &&
            !!this.entity.estadocivil &&
            !!this.entity.raca &&
            !!this.entity.grauinstrucao &&
            validacoesClienteAna
        );
    }

    private validaDocumentacao(): boolean {
        return (
            this.validaDocumentacaoObrigatoria() &&
            this.validaCtps()
        );
    }

    private validaDocumentacaoObrigatoria(): boolean {
        return (
            this.validaNis()
        );
    }

    private validaNis(): boolean {
        if (!this.entity.primeiroemprego) {
            var ftap: string = '3298765432';
            var total: number = 0;
            var resto: number = 0;
            var strResto: string = '';
            var numPIS: string = this.entity.nis;

            if (!numPIS) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            }

            for (var i = 0; i <= 9; i++) {
                var resultado: number = parseInt(numPIS.slice(i, i + 1), 10) * parseInt(ftap.slice(i, i + 1), 10);
                total = total + resultado;
            }

            resto = (total % 11);

            if (resto !== 0) {
                resto = 11 - resto;
            }

            if (resto === 10 || resto === 11) {
                strResto = resto + '';
                resto = parseInt(strResto.slice(1, 2), 10);
            }

            if (resto !== parseInt(numPIS.slice(10, 11), 10)) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            } else {
                this.form.nis.$error = { 'nisInvalido': false };
                return true;
            }
        } else {
            this.form.nis.$error = {};
            return true;
        }
    }

    private validaCtps(): boolean {
        return !(!!this.entity.numeroctps && !(!!this.entity.ufctps && !!this.entity.seriectps));
    }

    private validaOutrasInformacoes(): boolean {
        return (
            this.validaInformacoesGerais() &&
            this.validaFerias() &&
            this.validaRecebimento()
        );
    }

    private validaPercentualAdiantamento(percentual: number): boolean {
        const PERCENTUAL_MIN = 0; // 0%
        const PERCENTUAL_MAX = 0.4; // 40%

        return percentual >= PERCENTUAL_MIN && percentual <= PERCENTUAL_MAX;
    }

    private validaInformacoesGerais(): boolean {
        return (
            !!this.entity.vinculo &&
            !!this.entity.datavencimentoatestadomedico &&
            this.validaPercentualAdiantamento(this.entity.percentualadiantamento)
        );
    }

    private validaFerias(): boolean {
        return (
            !!this.entity.inicioperiodoaquisitivoferias
        );
    }

    private validaRecebimento(): boolean {
        return (
            this.entity.tiporecebimentosalario &&
            this.validaDadosBancarios() &&
            this.validaDadosPix()
        );
    }

    private validaDadosBancarios(): boolean {
        if (
            !this.entity.semdadosbancarios &&
            (
                this.entity.tiporecebimentosalario === '1' ||
                this.entity.tiporecebimentosalario === '2' ||
                this.entity.tiporecebimentosalario === '4' ||
                this.entity.tiporecebimentosalario === '64'
            )
        ) {

            let tipoContaPixValida: boolean = true;

            if (this.entity.tiporecebimentosalario === '64' && !(!!this.entity.tipocontapix)) {
                tipoContaPixValida = false;
            }

            return (
                !!this.entity.banco &&
                (!!this.entity.agencia || !!this.entity.agencianumero) &&
                !!this.entity.numerocontasalario &&
                !!this.entity.numerocontasalariodv &&
                tipoContaPixValida
            );
        }
        return true;
    }

    private validaDadosPix(): boolean {
        if (
            this.entity.tiporecebimentosalario === '128' ||
            this.entity.tiporecebimentosalario === '256' ||
            this.entity.tiporecebimentosalario === '512' ||
            this.entity.tiporecebimentosalario === '1024'
        ) {
            return !!this.entity.chavepix;
        }
        return true;
    }


    private validaEntity(tipo: string): boolean {
        if (tipo === 'admissaocompleta' && this.validaCompleta()) {
            this.entity.matricula = this.entity.matricula.toUpperCase();
            this.entity.subtiposolicitacao = 0;
            return true;
        } else if (tipo === 'admissaopreliminar' && this.validaPreliminar()) {
            this.entity.matricula = this.entity.matricula.toUpperCase();
            this.entity.subtiposolicitacao = 1;
            return true;
        }

        return false;
    }

    /**
     * Recebe o identificador do card e verifica se o mesmo deverá ser expandido para mostrar campos obrigatórios.
     * Os métodos open e close chamados são referentes aos métodos do componente accordion
     * @param idCard Identificador do card, definido no html da página
     */
    private verificaExpansaoCard(idCard: string) {

        let card: any = document.getElementById(idCard);

        switch (idCard) {
            case 'card-dadosgerais':

                !this.validaDadosGerais() ? card.open() : card.close();
                break;

            case 'card-docsanexos':

                !this.validaDocumentosAnexos() ? card.open() : card.close();
                break;

            case 'card-contrato':

                !this.validaContrato() ? card.open() : card.close();
                break;

            case 'card-endereco':

                !this.validaEndereco() ? card.open() : card.close();
                break;

            case 'card-dadoscontato':

                !this.validaDadosContato() ? card.open() : card.close();
                break;

            case 'card-dadospessoais':

                !this.validaDadosPessoais() ? card.open() : card.close();
                break;

            case 'card-documentacao':

                !this.validaDocumentacao() ? card.open() : card.close();
                break;

            case 'card-outrasinfos':

                !this.validaOutrasInformacoes() ? card.open() : card.close();
                break;

            default:
                card.close();
                break;
        }

        this.$scope.$applyAsync();

    }
    // fim - validações


    // início - observers
    private onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_submitted', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.entityService.constructors.solicitacao = args.entity.solicitacao;
                this.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;
                this.entityAnexo['solicitacao'] = args.entity.solicitacao;
                this.constructors.solicitacao = args.entity.solicitacao;

                this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

                if (this.tiposdocumentosrequeridos.length <= 0) {
                    if (!this.enviaEncaminhar) {
                        if (args.entity.situacao === this.situacaoAdmissaoEnum.ENVIADA) {
                            this.NewToaster.pop({
                                type: 'success',
                                title: 'A admissão foi enviada com sucesso.',
                                timeout: this.toastTimeout
                            });
                        } else {
                            this.NewToaster.pop({
                                type: 'success',
                                title: 'A admissão foi salva com sucesso',
                                body: 'Já é possível que outros usuários consigam visualizar e realizar ações na admissão aberta.',
                                timeout: this.toastTimeout
                            });
                        }
                        this.$state.go('meurh_admissaocompleta_show', this.constructors);
                    } else {
                        try {
                            this.entityService.encaminhar(args.entity.solicitacao, this.link);
                        } catch (error) {
                            throw new Error('Ocorreu um erro ao encaminhar a admissão');
                        }
                    }
                } else {
                    this.onTodasSolicitacoesDocumentosComplete('completa', args);
                }
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });

                this.busy = false;
                this.$state.go('meurh_admissaocompleta_show', this.constructors);
            }
        });
    }

    private onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_submit_error', (event: angular.IAngularEvent, args: any): void => {
            let mensagemErro = '';
            const tituloErro = args.entity.situacao === this.situacaoAdmissaoEnum.ABERTA
                ? 'Ocorreu um erro ao salvar a admissão'
                : 'Ocorreu um erro ao enviar a admissão';

            try {
                if (!!args.response && !!args.response.data) {
                    if (!!args.response.data.errors && !!args.response.data.errors.children) {
                        args.response.data.errors.children.forEach((element: any) => {
                            mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                        });
                    } else if (!!args.response.data.message) {
                        mensagemErro = args.response.data.message;
                    }
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: tituloErro,
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: tituloErro,
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
                this.enviaEncaminhar = false;
            }
        });
    }

    private onSubmitPreliminarSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_preliminar_submitted', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.entityService.constructors.solicitacao = args.entity.solicitacao;
                this.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;
                this.entityAnexo['solicitacao'] = args.entity.solicitacao;

                this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});
                this.constructors.solicitacao = args.entity.solicitacao;

                if (this.tiposdocumentosrequeridos.length <= 0) {
                    this.NewToaster.pop({
                        type: 'success',
                        title: 'A admissão preliminar foi enviada com sucesso.',
                        timeout: this.toastTimeout
                    });
                    this.$state.go('meurh_admissaocompleta_show', this.constructors);
                } else {
                    this.onTodasSolicitacoesDocumentosComplete('preliminar', args);
                }
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });

                this.busy = false;
                this.$state.go('meurh_admissaocompleta_show', this.constructors);
            }
        });
    }

    private onSubmitPreliminarError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_preliminar_submit_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                let mensagemErro = '';

                if (!!args.response && !!args.response.data) {
                    if (!!args.response.data.errors && !!args.response.data.errors.children) {
                        args.response.data.errors.children.forEach((element: any) => {
                            mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                        });
                    } else if (!!args.response.data.message) {
                        mensagemErro = args.response.data.message;
                    }
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao enviar a admissão preliminar',
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
            }
        });
    }

    private onEncaminharSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_encaminhar_success', async (event: angular.IAngularEvent, args: any): Promise<void> => {
            try {
                this.enviaEncaminhar = false;
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão foi encaminhada com sucesso.',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
                this.$state.go('meurh_admissaocompleta_show', this.constructors);
            }
        });
    }

    private onEncaminharError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_encaminhar_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                let mensagemErro = '';

                if (!!args.response && !!args.response.data) {
                    if (!!args.response.data.errors && !!args.response.data.errors.children) {
                        args.response.data.errors.children.forEach((element: any) => {
                            mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                        });
                    } else if (!!args.response.data.message) {
                        mensagemErro = args.response.data.message;
                    }
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao encaminhar a admissão',
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
                this.enviaEncaminhar = false;
                this.$state.go('meurh_admissaocompleta_show', this.constructors);
            }
        });
    }

    private onEncaminharLinkSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_encaminhar_link_success', async (event: angular.IAngularEvent, args: any): Promise<void> => {
            try {
                navigator.clipboard.writeText(args.codigoverificacao);
                this.NewToaster.pop({
                    type: 'success',
                    title: 'Código gerado com sucesso',
                    body: `O código da admissão foi copiado, você já pode colar
                onde quiser para mandar para o futuro colaborador.`,
                    timeout: this.toastTimeout
                });

            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
                this.$state.go('meurh_admissaocompleta_show', this.constructors);
            }
        });
    }

    private onEncaminharLinkError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_encaminhar_link_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não foi possível copiar o código da admissão',
                    body: args.message || '',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, mas ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
                this.enviaEncaminhar = false;
                this.$state.go('meurh_admissaocompleta_show', this.constructors);
            }
        });
    }

    private onTodasSolicitacoesDocumentosComplete(type: 'completa' | 'preliminar', args: any): void {
        this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
            try {
                this.$scope.$apply();
                if (this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult) && !this.documentosValidados) {
                    this.documentosValidados = true;
                    if (type === 'completa' && !this.enviaEncaminhar) {
                        if (args.entity.situacao === this.situacaoAdmissaoEnum.ENVIADA) {
                            this.NewToaster.pop({
                                type: 'success',
                                title: 'A admissão foi enviada com sucesso.',
                                timeout: this.toastTimeout
                            });
                        } else {
                            this.NewToaster.pop({
                                type: 'success',
                                title: 'A admissão foi salva com sucesso',
                                body: 'Já é possível que outros usuários consigam visualizar e realizar ações na admissão aberta.',
                                timeout: this.toastTimeout
                            });
                        }
                        this.$state.go('meurh_admissaocompleta_show', this.constructors);
                    } else if (type === 'completa') {
                        try {
                            this.entityService.encaminhar(args.entity.solicitacao, this.link);
                        } catch (error) {
                            throw new Error('Ocorreu um erro ao encaminhar a admissão');
                        }
                    } else {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A admissão preliminar foi enviada com sucesso.',
                            timeout: this.toastTimeout
                        });

                        this.$state.go('meurh_admissaocompleta_show', this.constructors);
                    }
                }
            } catch (error) {
                const message = error ? error : 'Desculpe, mas ocorreu um erro';

                this.NewToaster.pop({
                    type: 'error',
                    title: message,
                    timeout: this.toastTimeout
                });

                this.busy = false;
                this.$state.go('meurh_admissaocompleta_show', this.constructors);
            }
        });
    }
    // fim - observers


    // início - tratamentos de dados
    private alteraEntityEnviar(rascunho: boolean = false): void {
        this.entitySave = angular.copy(this.entity);
        this.entitySave.situacao = rascunho ? this.situacaoAdmissaoEnum.ABERTA : this.situacaoAdmissaoEnum.ENVIADA;

        this.entitySave.gestores = [];
        if (this.entity.gestores) {
            this.entity.gestores.map((gestor: IGestoresTrabalhador) => {
                if (gestor.gestor) {
                    this.entitySave.gestores?.push({ 'gestor': gestor.gestor });
                } else {
                    this.entitySave.gestores?.push({ 'identificacaonasajongestor': gestor.email });
                }
            });
        }
        this.entitySave.gestores = JSON.stringify(this.entitySave.gestores);

        this.entitySave.vaga = this.entitySave.vaga ? this.entitySave.vaga.vaga : null;
        this.entitySave.estabelecimento = this.entitySave.estabelecimento ? this.entitySave.estabelecimento.estabelecimento : null;
        this.entitySave.cargo = this.entitySave.cargo ? this.entitySave.cargo.cargo : null;
        this.entitySave.nivelcargo = this.entitySave.nivelcargo ? this.entitySave.nivelcargo.nivelcargo : null;
        this.entitySave.departamento = this.entitySave.departamento ? this.entitySave.departamento.departamento : null;
        this.entitySave.lotacao = this.entitySave.lotacao ? this.entitySave.lotacao.lotacao : null;
        this.entitySave.ambiente = this.entitySave.ambiente ? this.entitySave.ambiente.ambiente : null;
        this.entitySave.condicaoambientetrabalho = this.entitySave.condicaoambientetrabalho ? this.entitySave.condicaoambientetrabalho.condicaoambientetrabalho : null;
        this.entitySave.grupodeponto = this.entitySave.grupodeponto ? this.entitySave.grupodeponto.grupo : null;
        this.entitySave.sindicato = this.entitySave.sindicato ? this.entitySave.sindicato.sindicato : null;
        this.entitySave.horario = this.entitySave.horario ? this.entitySave.horario.horario : null;
        this.entitySave.categoriatrabalhador = this.entitySave.categoriatrabalhador ? this.entitySave.categoriatrabalhador.categoriatrabalhador : null;
        this.entitySave.municipionascimento = this.entitySave.municipionascimento ? this.entitySave.municipionascimento.ibge : null;
        this.entitySave.paisnascimento = this.entitySave.paisnascimento ? this.entitySave.paisnascimento.pais : null;
        this.entitySave.paisnacionalidade = this.entitySave.paisnacionalidade ? this.entitySave.paisnacionalidade.pais : null;
        this.entitySave.raca = this.entitySave.raca ? this.entitySave.raca.raca : null;
        this.entitySave.tipologradouro = this.entitySave.tipologradouro ? this.entitySave.tipologradouro.tipologradouro : null;
        this.entitySave.municipio = this.entitySave.municipio ? this.entitySave.municipio.ibge : null;
        this.entitySave.pais = this.entitySave.pais ? this.entitySave.pais.pais : null;
        this.entitySave.vinculo = this.entitySave.vinculo ? this.entitySave.vinculo.vinculo : null;
        this.entitySave.percentualadiantamento *= 100;
        this.entitySave.agencianumero = this.entitySave.agencia ? this.entitySave.agencia.agencianumero : this.entitySave.agencianumero;
        this.entitySave.agencia = null;
        if (this.entitySave.tiporecebimentosalario === undefined || this.entitySave.tiporecebimentosalario === null) {
            delete this.entitySave.tiporecebimentosalario;
            delete this.entitySave.tipocontapix;
        } else if (this.entitySave.tiporecebimentosalario === '64') {
            this.entitySave.tipocontapix = parseInt(this.entitySave.tipocontapix, 10);
        }
        if (this.entitySave.banco) {
            this.entitySave.banco = this.entitySave.banco.banco ? this.entitySave.banco.banco : null;
        }

        if (this.entitySave.beneficios) {
            this.entitySave.beneficios.forEach((element: any) => {
                element.lotacao = angular.copy(element.lotacao.lotacao);
                element.instituicao = element.instituicao ? angular.copy(element.instituicao.instituicao) : element.instituicao;
                if (element.usobeneficio === 2) {
                    element.cpfdependente = element.dependenteSelecionado.cpf;
                }
            });

            this.entitySave.beneficios = JSON.stringify(this.entitySave.beneficios);
        }

        if (this.entitySave.dependentes) {
            this.entitySave.dependentes.forEach((element: any) => {
                element.datainclusao = element.datainclusao ? element.datainclusao : null;
                element.databaixaimpostorenda = element.databaixaimpostorenda ? element.databaixaimpostorenda : null;
                element.vencimentoescolar = element.vencimentoescolar ? element.vencimentoescolar : null;
                element.vencimentovacinacao = element.vencimentovacinacao ? element.vencimentovacinacao : null;
                element.agencia = element.agencia ? angular.copy(element.agencia.agencia) : element.agencia;
                element.percentualpensaoalimenticia *= 100;
                element.percentualpensaoalimenticiafgts *= 100;
            });

            this.entitySave.dependentes = JSON.stringify(this.entitySave.dependentes);
        }

        if (this.entitySave.vts) {
            this.entitySave.vts.forEach((element: any) => {
                element.tarifaconcessionariavt = angular.copy(element.tarifaconcessionariavt.tarifaconcessionariavt);
            });

            this.entitySave.vts = JSON.stringify(this.entitySave.vts);
        }

        this.entitySave.temporesidenciaestrangeiro = !!this.entitySave.temporesidenciaestrangeiro ? parseInt(this.entitySave.temporesidenciaestrangeiro, 10) : null;
        this.entitySave.classificacaoestrangeiro = !!this.entitySave.classificacaoestrangeiro ? this.entitySave.classificacaoestrangeiro : null;
    }

    private preencherDadosLeituraDocumentos(args: any): void {
        args.dados.forEach((dado: any): void => {
            dado.fields.forEach((field: any): void => {
                switch (field.name) {
                    case 'nome':
                        this.entity.nome = field.value;
                        break;

                    case 'cpf':
                        this.entity.cpf = field.value;
                        break;

                    case 'data_nascimento':
                        this.entity.datanascimento = field.value;
                        break;

                    case 'nome_social':
                        this.entity.nomesocial = field.value;
                        break;

                    case 'filiacao_2': // nomenclatura para o nome do pai
                        this.entity.nomepai = field.value;
                        break;

                    case 'filiacao_1': // nomenclatura para o nome da mãe
                        this.entity.nomemae = field.value;
                        break;

                    case 'naturalidade':
                        this.entity.cidadenascimento = field.value;
                        break;

                    case 'rg':
                        this.entity.numerorg = field.value;
                        break;

                    case 'titulo_eleitor':
                        this.entity.numerote = field.value;
                        break;

                    case 'ctps':
                        this.entity.numeroctps = field.value;
                        break;

                    case 'serie':
                        this.entity.seriectps = field.value;
                        break;

                    case 'nis_pis_pasep': // nomenclatura do NIS no documento de RG
                        this.entity.nis = field.value;
                        break;

                    case 'cnh': // nomenclatura da CNH no documento de RG
                        this.entity.numerocnh = field.value;
                        break;

                    case 'registro': // nomenclatura da CNH no documento de CNH
                        this.entity.numerocnh = field.value;
                        break;
                }
            });
        });
    }

    private textoModal(tipo: string): object {
        let customText = {
            'rascunho': {
                title: 'Salvar admissão',
                confirmText: 'Você tem certeza que deseja salvar a admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, salvar',
            },
            'encaminhar': {
                title: 'Encaminhar admissão',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você encaminhar a admissão, os campos que o futuro colaborador pode preencher através do aplicativo ficarão bloqueados para edição até o momento que o mesmo termine de preencher os dados, ou até que seja necessário retomar a admissão.'
                },
                confirmText: 'Você tem certeza que deseja encaminhar a admissão para ' + this.entity.nome + ' informar os dados pelo aplicativo?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, encaminhar'
            },
            'admissaopreliminar': {
                title: 'Enviar admissão preliminar',
                alertInfo: {
                    header: 'Sobre a admissão preliminar',
                    text: 'Após você enviar a admissão preliminar, não será mais possível editar os dados informados.'
                },
                confirmText: 'Você tem certeza que deseja enviar a admissão preliminar de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar'
            },
            'admissaocompleta': {
                title: 'Enviar admissão completa',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você enviar a admissão completa, não será mais possível editá-la.'
                },
                confirmText: 'Você tem certeza que deseja enviar a admissão completa de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar'
            }
        };

        return customText[tipo];
    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': TipoSolicitacaoEnum.ADMISSOESCOMPLETAS };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }
    // início - tratamentos de dados
}
