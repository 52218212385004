import angular = require('angular');
import { ICreditoDesconto } from './models/creditoDesconto.model';

export class MeurhCreditosdescontosService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public loading_deferred_filter: any = null;
    public entity: Partial<ICreditoDesconto> = {};
    public entities: Array<Partial<ICreditoDesconto>> = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = '';
    public filters: any = {};
    public loadParams: any = {
        to_load: 3,
        busy: false,
        finished: false,
    };
    public loading_deferred: any = null;
    public filter: string = '';

    constructor(public $http: angular.IHttpService, public nsjRouting: any, public $rootScope: angular.IScope, public $q: angular.IQService) {
    }

    reload(all: boolean = false) {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        if (all === true) {
            return this.loadAll();
        } else {
            return this.load();
        }
    }

    search(filter: any) {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach(function (k: any) {
                delete this.filters[k];
            });
            for (let fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    let filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load(constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        if (!constructors.trabalhador) {
            return this.$q((resolve, reject) => {
                this.$http({
                    method: 'GET',
                    url: this.nsjRouting.generate('meurh_solicitacoescreditodesconto_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                    timeout: this.loading_deferred.promise
                })
                    .then(function (response: any) {
                        resolve(response.data);
                    })
                    .catch(function (response: any) {
                        reject(response);
                    });
            });
        }
        return this.$q((resolve, reject) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('meurh_solicitacoescreditodesconto_movimentos', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then(function (response: any) {
                    resolve(response.data);
                })
                .catch(function (response: any) {
                    reject(response);
                });

        });

    }

    load() {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            let eventounidade = data[i].evento.unidade === undefined ? data[i].evento.evento_unidade : data[i].evento.unidade;

                            if ((eventounidade === 0 || eventounidade === 8 || eventounidade === 10) && data[i].conteudo) {
                                data[i].conteudo = this.transformaConteudoEmHora(data[i].conteudo);
                            }

                            this.entities.push(data[i]);
                        }

                        this.after['created_at'] = this.entities[this.entities.length - 1]['created_at'];
                        this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
                    }

                    if (data.length < 20) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('meurh_solicitacoescreditodesconto_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }

                    this.loaded = true;
                    this.loadParams.to_load--;

                    if (this.loadParams.to_load === 0 || data.length <= 20) {
                        this.$rootScope.$broadcast('meurh_solicitacoescreditodesconto_loaded', this.entities);
                    }
                })
                .catch((error) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }

                    this.$rootScope.$broadcast('meurh_solicitacoescreditodesconto_load_error', error);
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }

                    this.load();
                });
        }

        return this.entities;
    }

    loadAll() {
        if (!this.loadParams.busy && !this.loadParams.finished &&
            (this.entities.length === 0 || this.entities.length < this.entities[0].full_count)
            ) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            let eventounidade = data[i].evento.unidade === undefined ? data[i].evento.evento_unidade : data[i].evento.unidade;

                            if (eventounidade === 0 || eventounidade === 8 || eventounidade === 10) {
                                data[i].conteudo = this.transformaConteudoEmHora(data[i].conteudo);
                            }

                            this.entities.push(data[i]);
                        }

                        this.after['created_at'] = this.entities[this.entities.length - 1]['created_at'];
                        this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
                    }

                    if (data.length < 20) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('meurh_solicitacoescreditodesconto_loaded_all_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }

                    this.loaded = true;

                    if ((this.entities.length > 0 && this.entities.length === this.entities[0].full_count)
                    || (data.length <= 20)) {
                        this.$rootScope.$broadcast('meurh_solicitacoescreditodesconto_loaded_full_count', this.entities);
                    }
                })
                .catch((error) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                    this.$rootScope.$broadcast('meurh_solicitacoescreditodesconto_load_error', error);
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                    this.loadAll();
                });
        }

        return this.entities;
    }

    transformaConteudoEmHora(content: any): string {
        let horaValor = content.replace(':', '.');
        content = new Intl.DateTimeFormat('pt-BR', { hour: '2-digit', minute: '2-digit' }).format(new Date(0, 0, 0, Math.floor(Math.round(horaValor * 60) / 60), Math.round(horaValor * 60) % 60));
        return content;
    }

    loadMore() {
        this.loadParams.to_load = 3;
        this.load();
    }

    _save(entity: any, autosave: boolean) {
        let method, url;
        if (entity['solicitacao']) {
            method = 'PUT';
            url = this.nsjRouting.generate('meurh_solicitacoescreditodesconto_put',
                { 'id': entity['solicitacao'],
                'trabalhador' : entity.trabalhador,
                'grupoempresarial' : entity.constructors.grupoempresarial,
                'tenant' : entity.constructors.tenant },
                true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('meurh_solicitacoescreditodesconto_create',
                { 'trabalhador' : entity.trabalhador,
                'grupoempresarial' : entity.constructors.grupoempresarial,
                'tenant' : entity.constructors.tenant },
                true);
        }
        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }
        let data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        this.loading_deferred = this.$q.defer();
        return this.$http({
            method: method,
            url: url,
            data: data,
            timeout: this.loading_deferred.promise
        })
            .finally(() => {
                if (!autosave) {
                    entity['$$__submitting'] = false;
                }
            });
    }

    save(entity: any, autosave: boolean) {

        this._save(entity, autosave)
            .then((response: any) => {
                if (response.data.solicitacao) {
                    entity['solicitacao'] = response.data.solicitacao;
                }
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('meurh_solicitacoescreditodesconto_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response) => {
                this.$rootScope.$broadcast('meurh_solicitacoescreditodesconto_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    get(identifier: any) {
        this.loading_deferred = this.$q.defer();
        this.loadParams.busy = true;
        return this.$q((resolve: any, reject: any) => {
            this.$http
                .get(this.nsjRouting.generate('meurh_solicitacoescreditodesconto_get', { 'id': identifier }, true),
                    { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoescreditodesconto_getloaded', response.data);
                    this.loadParams.busy = false;
                    resolve(response.data);
                })
                .catch((response: any) => {
                    this.loadParams.busy = false;
                    reject(response);
                });
        });
    }

    delete($identifier: any, force: boolean) {
        if (typeof ($identifier) === 'object') {
            $identifier = $identifier['solicitacao'];
        }
        if ($identifier) {
            if (force) {
                this.loading_deferred = this.$q.defer();
                this.$http
                    .delete(this.nsjRouting.generate('meurh_solicitacoescreditodesconto_delete', angular.extend(this.constructors, { 'id': $identifier }), true),
                        { timeout: this.loading_deferred.promise })
                    .then((response) => {
                        this.$rootScope.$broadcast('meurh_solicitacoescreditodesconto_deleted', {
                            entity: this.entity,
                            response: response
                        });
                    })
                    .catch((response) => {
                        this.$rootScope.$broadcast('meurh_solicitacoescreditodesconto_delete_error', {
                            entity: this.entity,
                            response: response
                        });
                    });
            }
        }
    }
}
